import {
  EntityListOfUserBankAccountListDto,
  UserBankAccountDetailDto,
} from "Api/Api";
import { createBankAddressAsync } from "State/BankAddresses/Create/BankAddressCreateState";
import { getBankAddressDetailAsync } from "State/BankAddresses/Detail/BankAddressDetailState";
import { getBankAccountListAsync } from "State/BankAddresses/List/BankAddressListState";
import { createReducer, ActionType } from "typesafe-actions";

type BankAccountState = {
  bankAccounts: EntityListOfUserBankAccountListDto | null;
  isListLoading: boolean;
  bankAccountDetail: UserBankAccountDetailDto | null;
};

type BankAddressAction =
  | ActionType<typeof getBankAddressDetailAsync>
  | ActionType<typeof getBankAccountListAsync>
  | ActionType<typeof createBankAddressAsync>;

export const bankAccountReducer = createReducer<
  BankAccountState,
  BankAddressAction
>({
  bankAccounts: null,
  isListLoading: false,
  bankAccountDetail: null,
})
  .handleAction(getBankAccountListAsync.request, state => {
    return { ...state, isListLoading: true };
  })
  .handleAction(getBankAccountListAsync.success, (state, action) => {
    return { ...state, bankAccounts: action.payload, isListLoading: false };
  })
  .handleAction(getBankAccountListAsync.failure, state => {
    return { ...state, isListLoading: false };
  })
  .handleAction(getBankAddressDetailAsync.success, (state, action) => {
    return {
      ...state,
      bankAccountDetail: action.payload,
      isListLoading: false,
    };
  });
