import { RadioGroup, FormControlLabel, Radio, Typography } from "@mui/material";
import {
  Control,
  Controller,
  FieldError,
  FieldErrors,
  FieldPath,
  FieldValues,
} from "react-hook-form";
import { get } from "lodash-es";
import { FormInfo } from "Components/Shared/Inputs/FormInfo";
import {
  StyledFormControl,
  StyledFormInput,
  StyledFormLabelWrapper,
  StyledValidationText,
} from "Components/Shared/FormStyles";
import styled from "styled-components";
import { useFieldError } from "Hooks/useFieldError";
import { CodeListItem } from "Models/ICodeListDto";

type Props<FormType extends FieldValues> = {
  codeList: CodeListItem[];
  name: FieldPath<FormType>;
  control: Control<FormType, object>;
  label?: string;
  formInfo?: { [key: string]: string };
  useCodesAsLabels?: boolean;
  disabled?: boolean;
  errors: FieldErrors<FormType>;
  row?: boolean;
  onChange?: (value: string) => void;
};

const StyledFormControlLabel = styled(FormControlLabel)<{
  $isSelected: boolean;
}>`
  display: flex;
  flex-flow: row;
  align-items: flex-start;
  margin-left: ${props => props.theme.spacing(1)};
  margin-right: ${props => props.theme.spacing(5)};

  .MuiFormControlLabel-label {
    padding-top: 5px;
    p {
      font-weight: 500;
      font-size: 18px;
    }
  }

  color: ${props => props.$isSelected && props.theme.palette.primary.main};
`;

const StyledIcon = styled.div`
  width: 16px;
  min-width: 16px;
  height: 16px;
  position: relative;
  border-radius: 50%;
  border: 1px solid ${props => props.theme.palette.text.secondary};
`;

const StyledIconChecked = styled(StyledIcon)`
  &::before {
    content: "";
    position: absolute;
    left: 2px;
    top: 2px;
    width: 10px;
    height: 10px;
    background-color: ${props => props.theme.palette.primary.light};
    border-radius: 50%;
  }
`;

const Description = styled(Typography)`
  color: ${props => props.theme.palette.text.secondary};
  font-size: 12px !important;
`;

export const RadioButtons = <T extends object>(props: Props<T>) => {
  const {
    codeList,
    name,
    control,
    useCodesAsLabels,
    formInfo,
    label,
    disabled,
    errors,
    row,
  } = props;
  const defaultValue = codeList[0] && codeList[0].code;
  const { hasError, errorMessage } = useFieldError({
    fieldError: get(errors, name) as FieldError,
  });

  return (
    <StyledFormControl>
      <StyledFormLabelWrapper>
        <label htmlFor={"name"}>{label}</label>
        <FormInfo name={name} formInfo={formInfo} />
      </StyledFormLabelWrapper>
      <StyledFormInput>
        <Controller
          control={control}
          defaultValue={defaultValue as any}
          name={name}
          render={({ field: { value, name, onChange, onBlur } }) => (
            <RadioGroup
              value={value ?? ""}
              name={name}
              onChange={e => {
                if (!!props.onChange) {
                  props.onChange(e.target.value);
                }
                onChange(e.target.value);
              }}
              onBlur={onBlur}
              row={row}
              className={"radio-group"}
            >
              {codeList.map(item => (
                <StyledFormControlLabel
                  $isSelected={item.code === value}
                  disabled={disabled}
                  key={item.code.toString()}
                  value={item.code.toString()}
                  control={
                    <Radio
                      icon={<StyledIcon />}
                      checkedIcon={<StyledIconChecked />}
                    />
                  }
                  label={
                    <div>
                      <Typography>
                        {useCodesAsLabels === true ? item.code : item.name}
                      </Typography>

                      {!!item.description && (
                        <Description>{item.description}</Description>
                      )}
                    </div>
                  }
                />
              ))}
            </RadioGroup>
          )}
        />
      </StyledFormInput>
      <StyledValidationText>{hasError && errorMessage}</StyledValidationText>
    </StyledFormControl>
  );
};
