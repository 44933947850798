import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  type TextFieldProps,
} from "@mui/material";
import { BankTile } from "Components/Shared/BankTile";
import type { BankAccountListItem } from "Infrastructure/Api/Api";
import {
  type Control,
  Controller,
  type FieldPath,
  type FieldValues,
} from "react-hook-form";
import { isSandbox } from "Utils/BankUtils";

type Props<FormType extends FieldValues> = TextFieldProps & {
  name: FieldPath<FormType>;
  label?: string;
  control: Control<FormType, object>;
  disabled?: boolean;
  bankAccounts: BankAccountListItem[];
};

export const BankingAccountSelector = <T extends object>({
  control,
  name,
  label,
  bankAccounts,
}: Props<T>) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, ...field }, fieldState: { error } }) => (
        <FormControl
          component="fieldset"
          sx={{ width: "100%", marginBottom: 2 }}
          error={!!error}
        >
          <FormLabel component="legend" sx={{ marginBottom: 1 }}>
            {label}
          </FormLabel>
          <RadioGroup
            {...field}
            onChange={e => onChange(Number.parseInt(e.target.value))}
          >
            {bankAccounts.map(
              (
                { environment, bankAccountID, bank, productName },
                index,
                items,
              ) => (
                <FormControlLabel
                  key={bankAccountID}
                  value={bankAccountID}
                  control={
                    <Radio
                      sx={{ display: "none" }}
                      checked={field.value === bankAccountID}
                    />
                  }
                  sx={{
                    width: "100%",
                    marginBottom: index < items.length - 1 ? 2 : 0,
                    marginLeft: 0,
                    "& .MuiFormControlLabel-label": {
                      width: "100%",
                      margin: 0,
                    },
                  }}
                  label={
                    <BankTile
                      bank={bank}
                      customName={productName}
                      isSandbox={isSandbox(environment)}
                      border={
                        field.value === bankAccountID ? "active" : "inactive"
                      }
                      clickable
                    />
                  }
                />
              ),
            )}
          </RadioGroup>
          <FormHelperText sx={{ margin: 0 }}>{error?.message}</FormHelperText>
        </FormControl>
      )}
    />
  );
};
