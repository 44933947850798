import { useMutation } from "@tanstack/react-query";
import { BANKING_ACCOUNTS_QUERY_KEY } from "Api/Queries/useBankingAccounts";
import { queryClient } from "App";
import { postBankingAccountsSync } from "Infrastructure/Api/Api";
import { processResponse } from "Infrastructure/Api/ApiUtils";

export const useBankingAccountsSync = () =>
  useMutation({
    mutationFn: processResponse(() => postBankingAccountsSync()),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: BANKING_ACCOUNTS_QUERY_KEY });
    },
  });
