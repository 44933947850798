import * as React from "react";
import PullToRefresh from "react-simple-pull-to-refresh";
import { CssBaseline, GlobalStyles } from "@mui/material";
import { getAppUrl } from "Utils/UrlUtils";
import { getCodeListCollectionAsync } from "State/CodeLists/CodeListReducer";
import { getTheme } from "Components/Layout/Themes/Themes";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  ThemeProvider,
  Theme,
  StyledEngineProvider,
} from "@mui/material/styles";
import styled, {
  DefaultTheme,
  ThemeProvider as StyledThemeProvider,
} from "styled-components";
import { Toaster } from "sonner";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const StyledWrapper = styled.div`
  display: grid;
  grid-template-rows: 1fr auto;
  min-height: ${props => props.theme.vh(100)};
  background-color: ${props => props.theme.colors.gray};

  ${props => props.theme.breakpoints.up("md")} {
    justify-content: center;
  }
`;

const Layout: React.FunctionComponent<
  React.PropsWithChildren<unknown>
> = props => {
  const [theme, setTheme] = React.useState<DefaultTheme | null>(null);

  React.useEffect(() => {
    setTheme(getTheme());
  }, []);

  const onRefresh = () => {
    return new Promise(resolve => {
      window.location.reload();
      setTimeout(resolve, 200);
    });
  };

  function isPwa() {
    return ["fullscreen", "standalone", "minimal-ui"].some(
      displayMode =>
        window.matchMedia("(display-mode: " + displayMode + ")").matches,
    );
  }
  const appUrl = getAppUrl();
  var location = useLocation();

  const isOnWhitelistedPage = () => {
    return (
      location.pathname === appUrl("dashboard") ||
      location.pathname === appUrl("transactions")
    );
  };

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getCodeListCollectionAsync.request());
  }, [dispatch]);

  return (
    <>
      {theme && (
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <GlobalStyles
              styles={{
                "#sentry-feedback": {
                  [theme.breakpoints.down("md")]: {
                    "--bottom": "72px",
                  },
                },
              }}
            />
            <CssBaseline />
            <StyledThemeProvider theme={theme}>
              <Toaster richColors position="top-right" />
              <PullToRefresh
                isPullable={isPwa() && isOnWhitelistedPage()}
                onRefresh={onRefresh}
                resistance={4}
                pullingContent={""}
              >
                <StyledWrapper>{props.children}</StyledWrapper>
              </PullToRefresh>
            </StyledThemeProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      )}
    </>
  );
};

export { Layout };
