import { SystemSettingType } from "Api/Api";
import { RootStateType } from "State/Store";
import { useSelector } from "react-redux";

export const useMaxInvitationCount = () => {
  const { codeLists } = useSelector((e: RootStateType) => e.codeList);

  return Number(
    codeLists?.systemSettings?.find(
      e => e.type === SystemSettingType.MaxInvitationCount,
    )?.value ?? "0",
  );
};
