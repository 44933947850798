import { RouteProps, useRoutes } from "react-router-dom";
import { AuthenticatedRoute } from "Routes/AuthenticatedRoute";
import { useSelector } from "react-redux";
import { RootStateType } from "State/Store";
import { getAppUrl } from "Utils/UrlUtils";
import { AuthorizedRoute } from "Routes/AuthorizedRoute";
import { AccessRightCodes } from "Models/AccessRightCodes";
import { isAuthenticated } from "Utils/AuthUtils";
import { wrapUseRoutes } from "@sentry/react";
import GoogleCallbackPage from "Pages/Auth/GoogleCallbackPage";
import MerchantInitiatePaymentDetailPage from "Pages/Merchant/MerchantInitiatePaymentDetail";
import CustomerPaymentPayPage from "Pages/Customer/CustomerPaymentPayPage";
import SignInPage from "Pages/Auth/SignInPage";
import DashboardPage from "Pages/DashboardPage";
import BankIDRedirectPage from "Pages/Auth/BankIDRedirectPage";
import GoogleRedirectPage from "Pages/Auth/GoogleRedirectPage";
import AppleRedirectPage from "Pages/Auth/AppleRedirectPage";
import SignUpPage from "Pages/Auth/SignUpPage";
import ForgottenPasswordPage from "Pages/Auth/ForgottenPasswordPage";
import SetPasswordPage from "Pages/Auth/SetPasswordPage";
import WelcomePage from "Pages/WelcomePage";
import MerchantPaymentPage from "Pages/Merchant/MerchantPaymentPage";
import AccountSettingsPage from "Pages/Settings/AccountSettingsPage";
import TransactionsPage from "Pages/Transactions/TransactionsPage";
import VerificationPage from "Pages/VerificationPage";
import TransactionDetailPage from "Pages/Transactions/TransactionDetailPage";
import SettingsPage from "Pages/Settings/SettingsPage";
import NotificationSettingsPage from "Pages/Settings/NotificationSettingsPage";
import DeactivateUserPage from "Pages/Settings/DeactivateUserPage";
import EmailVerificationPage from "Pages/Auth/EmailVerificationPage";
import AwaitingEmailVerificationPage from "Pages/Auth/AwaitingEmailVerificationPage";
import RateConfirmationPage from "Pages/RateConfirmationPage";
import SystemReportPage from "Pages/System/SystemReportPage";
import SystemControlPage from "Pages/System/SystemControlPage";
import UserListPage from "Components/Users/UserListPage";
import UserDetailPage from "Pages/UserDetailPage";
import BankingAuthPage from "Pages/Banking/Auth/BankingAuthPage";
import Error403Page from "Pages/Error/Error403Page";
import Error404Page from "Pages/Error/Error404Page";
import AccountPage from "Pages/AccountPage";
import LeadInvitationPage from "Pages/Account/LeadInvitationPage";
import MerchantInitiatePaymentPage from "Pages/Merchant/MerchantInitiatePaymentPage";
import AffiliatePage from "Pages/Account/AffiliatePage";
import ContactPage from "Pages/ContactPage";
import BankAccountListPage from "Pages/BankAccounts/BankAccountListPage";
import BankAccountDetailPage from "Pages/BankAccounts/BankAccountDetailPage";
import { AnonymousRoute } from "Routes/AnonymousRoute";
import AnonymousCustomerPaymentPayPage from "Pages/Customer/AnonymousCustomerPaymentPayPage";
import { UserStatus } from "Infrastructure/Api/Api";
import { AnonymousTransactionDetailPage } from "Pages/Transactions/AnonymousTransactionDetailPage";
import { AnonymousDashboardPage } from "Pages/AnonymousDashboardPage";

const appUrl = getAppUrl(true);

const Routes: React.FunctionComponent<
  React.PropsWithChildren<RouteProps>
> = _ => {
  const user = useSelector((e: RootStateType) => e.auth.user);

  const isUserAuthenticated = isAuthenticated(user);
  const isAnonymousUserAuthenticated =
    isAuthenticated(user) && user?.userStatus === UserStatus.Anonymous;
  const useSentryRoutes = wrapUseRoutes(useRoutes);

  const element = useSentryRoutes([
    {
      path: "",
      element: isUserAuthenticated ? (
        <AuthenticatedRoute isUserAuthenticated={isUserAuthenticated}>
          <DashboardPage />
        </AuthenticatedRoute>
      ) : (
        <SignInPage />
      ),
    },
    {
      path: appUrl("sign-in"),
      element: <SignInPage />,
    },
    {
      path: appUrl("bankID-redirect"),
      element: <BankIDRedirectPage />,
    },
    {
      path: appUrl("google-redirect"),
      element: <GoogleRedirectPage />,
    },
    {
      path: appUrl("google-callback"),
      element: <GoogleCallbackPage />,
    },
    {
      path: appUrl("apple-redirect"),
      element: <AppleRedirectPage />,
    },
    {
      path: appUrl("sign-up"),
      element: <SignUpPage />,
    },
    {
      path: appUrl("forgottenPassword"),
      element: <ForgottenPasswordPage />,
    },
    {
      path: appUrl("setPassword"),
      element: <SetPasswordPage />,
    },
    {
      path: appUrl("welcome"),
      element: (
        <AuthenticatedRoute isUserAuthenticated={isUserAuthenticated}>
          <WelcomePage />
        </AuthenticatedRoute>
      ),
    },
    {
      path: appUrl("merchant-payment"),
      element: (
        <AuthenticatedRoute isUserAuthenticated={isUserAuthenticated}>
          <MerchantPaymentPage />
        </AuthenticatedRoute>
      ),
    },
    {
      path: appUrl("initiate-payment"),
      element: (
        <AuthenticatedRoute isUserAuthenticated={isUserAuthenticated}>
          <MerchantInitiatePaymentPage />
        </AuthenticatedRoute>
      ),
    },
    {
      path: appUrl("initiate-payment-detail"),
      element: (
        <AuthenticatedRoute isUserAuthenticated={isUserAuthenticated}>
          <MerchantInitiatePaymentDetailPage />
        </AuthenticatedRoute>
      ),
    },
    {
      path: appUrl("account"),
      element: (
        <AuthenticatedRoute isUserAuthenticated={isUserAuthenticated}>
          <AccountPage />
        </AuthenticatedRoute>
      ),
    },
    {
      path: appUrl("affiliate"),
      element: (
        <AuthenticatedRoute isUserAuthenticated={isUserAuthenticated}>
          <AffiliatePage />
        </AuthenticatedRoute>
      ),
    },
    {
      path: appUrl("invite-lead"),
      element: (
        <AuthenticatedRoute isUserAuthenticated={isUserAuthenticated}>
          <LeadInvitationPage />
        </AuthenticatedRoute>
      ),
    },
    {
      path: appUrl("account-settings"),
      element: (
        <AuthenticatedRoute isUserAuthenticated={isUserAuthenticated}>
          <AccountSettingsPage />
        </AuthenticatedRoute>
      ),
    },
    {
      path: appUrl("verification"),
      element: (
        <AuthenticatedRoute isUserAuthenticated={isUserAuthenticated}>
          <VerificationPage />
        </AuthenticatedRoute>
      ),
    },

    {
      path: appUrl("transactions"),
      element: (
        <AuthenticatedRoute isUserAuthenticated={isUserAuthenticated}>
          <TransactionsPage />
        </AuthenticatedRoute>
      ),
    },
    {
      path: appUrl("transaction-detail"),
      element: (
        <AuthenticatedRoute
          isUserAuthenticated={isUserAuthenticated}
          allowAnonymous
        >
          <AnonymousRoute
            isUserAuthenticated={isUserAuthenticated}
            isAnonymousUserAuthenticated={isAnonymousUserAuthenticated}
            authenticatedComponent={<TransactionDetailPage />}
            unauthenticatedComponent={<AnonymousTransactionDetailPage />}
          />
        </AuthenticatedRoute>
      ),
    },
    {
      path: appUrl("settings"),
      element: (
        <AuthenticatedRoute isUserAuthenticated={isUserAuthenticated}>
          <SettingsPage />
        </AuthenticatedRoute>
      ),
    },
    {
      path: appUrl("notification-settings"),
      element: (
        <AuthenticatedRoute isUserAuthenticated={isUserAuthenticated}>
          <NotificationSettingsPage />
        </AuthenticatedRoute>
      ),
    },
    {
      path: appUrl("deactivate-user"),
      element: (
        <AuthenticatedRoute isUserAuthenticated={isUserAuthenticated}>
          <DeactivateUserPage />
        </AuthenticatedRoute>
      ),
    },
    {
      path: appUrl("contact"),
      element: (
        <AuthenticatedRoute isUserAuthenticated={isUserAuthenticated}>
          <ContactPage />
        </AuthenticatedRoute>
      ),
    },
    {
      path: appUrl("dashboard"),
      element: (
        <AuthenticatedRoute
          isUserAuthenticated={isUserAuthenticated}
          allowAnonymous
        >
          <AnonymousRoute
            isUserAuthenticated={isUserAuthenticated}
            isAnonymousUserAuthenticated={isAnonymousUserAuthenticated}
            authenticatedComponent={<DashboardPage />}
            unauthenticatedComponent={<AnonymousDashboardPage />}
          />
        </AuthenticatedRoute>
      ),
    },
    {
      path: appUrl("email-verification"),
      element: <EmailVerificationPage />,
    },
    {
      path: appUrl("awaiting-email-verification"),
      element: <AwaitingEmailVerificationPage />,
    },
    {
      path: appUrl("rate-confirmation"),
      element: (
        <AuthenticatedRoute isUserAuthenticated={isUserAuthenticated}>
          <RateConfirmationPage />
        </AuthenticatedRoute>
      ),
    },
    {
      path: appUrl("system"),
      element: (
        <AuthenticatedRoute isUserAuthenticated={isUserAuthenticated}>
          <AuthorizedRoute
            code={AccessRightCodes.SystemReport}
            userAccessRightCodes={user?.accessRightCodes}
          >
            <SystemReportPage />
          </AuthorizedRoute>
        </AuthenticatedRoute>
      ),
    },
    {
      path: appUrl("system-control"),
      element: (
        <AuthenticatedRoute isUserAuthenticated={isUserAuthenticated}>
          <AuthorizedRoute
            code={AccessRightCodes.SystemControl}
            userAccessRightCodes={user?.accessRightCodes}
          >
            <SystemControlPage />
          </AuthorizedRoute>
        </AuthenticatedRoute>
      ),
    },
    {
      path: appUrl("user-list"),
      element: (
        <AuthenticatedRoute isUserAuthenticated={isUserAuthenticated}>
          <AuthorizedRoute
            code={AccessRightCodes.Users}
            userAccessRightCodes={user?.accessRightCodes}
          >
            <UserListPage />
          </AuthorizedRoute>
        </AuthenticatedRoute>
      ),
    },
    {
      path: appUrl("user-detail"),
      element: (
        <AuthenticatedRoute isUserAuthenticated={isUserAuthenticated}>
          <AuthorizedRoute
            code={AccessRightCodes.Users}
            userAccessRightCodes={user?.accessRightCodes}
          >
            <UserDetailPage />
          </AuthorizedRoute>
        </AuthenticatedRoute>
      ),
    },
    {
      path: appUrl("bank-address-list"),
      element: (
        <AuthenticatedRoute isUserAuthenticated={isUserAuthenticated}>
          <BankAccountListPage />
        </AuthenticatedRoute>
      ),
    },
    {
      path: appUrl("bank-address-detail"),
      element: (
        <AuthenticatedRoute isUserAuthenticated={isUserAuthenticated}>
          <BankAccountDetailPage />
        </AuthenticatedRoute>
      ),
    },
    {
      path: appUrl("banking-auth"),
      element: (
        <AuthenticatedRoute isUserAuthenticated={isUserAuthenticated}>
          <BankingAuthPage />
        </AuthenticatedRoute>
      ),
    },
    {
      path: appUrl("customer-payment-pay"),
      element: (
        <AnonymousRoute
          isUserAuthenticated={isUserAuthenticated}
          isAnonymousUserAuthenticated={isAnonymousUserAuthenticated}
          authenticatedComponent={<CustomerPaymentPayPage />}
          unauthenticatedComponent={<AnonymousCustomerPaymentPayPage />}
        />
      ),
    },
    { path: appUrl("403"), element: <Error403Page /> },
    { path: "*", element: <Error404Page /> },
  ]);

  return element;
};

export { Routes };
