import { AppUser } from "State/Auth/Models/AuthStateModels";

type Pages =
  | ""
  | "403"
  | "404"
  | "sign-in"
  | "bankID-redirect"
  | "google-redirect"
  | "google-callback"
  | "apple-redirect"
  | "sign-up"
  | "forgottenPassword"
  | "setPassword"
  | "verification"
  | "registration-verification"
  | "awaiting-email-verification"
  | "email-verification"
  | "rate-confirmation"
  | "invoice-create"
  | "welcome"
  | "faq"
  | "faq-wage"
  | "faq-investment"
  | "faq-quick-exchange"
  | "account"
  | "quick-exchange"
  | "account-settings"
  | "affiliate"
  | "invite-lead"
  | "transaction-detail"
  | "transactions"
  | "settings"
  | "notification-settings"
  | "contact"
  | "dashboard"
  | "dashboard-withdraw"
  | "dashboard-sell"
  | "system"
  | "cold-wallet"
  | "system-control"
  | "bank-address-list"
  | "bank-address-detail"
  | "user-list"
  | "user-detail"
  | "sell"
  | "redeem"
  | "redeem-public"
  | "deactivate-user"
  | "coinback"
  | "coinback-redirect"
  | "banking-auth"
  | "merchant-payment"
  | "initiate-payment"
  | "initiate-payment-detail"
  | "customer-payment-pay";

export const getAppUrl = (asRoutes = false) => {
  return (page: Pages) => {
    switch (page) {
      case "403":
        return `/403`;
      case "404":
        return `/404`;
      case "sign-in":
        return `/sign-in`;
      case "bankID-redirect":
        return `/auth/bankID`;
      case "google-redirect":
        return `/auth/google`;
      case "google-callback":
        return `/auth/google/callback`;
      case "apple-redirect":
        return `/auth/apple`;
      case "sign-up":
        return `/sign-up`;
      case "setPassword":
        return `/set-password${asRoutes ? "/:token" : ""}`;
      case "forgottenPassword":
        return "/forgotten-password";
      case "welcome":
        return `/welcome`;
      case "faq":
        return `/faq`;
      case "faq-wage":
        return `/faq/wage`;
      case "faq-investment":
        return `/faq/investment`;
      case "faq-quick-exchange":
        return `/faq/quick-exchange`;
      case "dashboard":
        return `/dashboard`;
      case "dashboard-withdraw":
        return `/dashboard/withdraw`;
      case "dashboard-sell":
        return `/dashboard/sell`;
      case "account":
        return `/account`;
      case "account-settings":
        return `/account-settings`;
      case "verification":
        return `/verification`;
      case "affiliate":
        return `/account/affiliate`;
      case "invite-lead":
        return `/account/invite-lead`;
      case "transaction-detail":
        return `/transaction-detail${asRoutes ? "/:id" : ""}`;
      case "transactions":
        return `/transactions`;
      case "settings":
        return `/settings`;
      case "notification-settings":
        return `/notification-settings`;
      case "deactivate-user":
        return `/smazat-ucet`;
      case "contact":
        return `/contact`;
      case "invoice-create":
        return `/invoice/create`;
      case "rate-confirmation":
        return `/rate-confirmation${asRoutes ? "/:externalID" : ""}`;
      case "registration-verification":
        return `/registration-verification${asRoutes ? "/:token" : ""}`;
      case "awaiting-email-verification":
        return `/awaiting-email-verification`;
      case "email-verification":
        return `/email-verification${asRoutes ? "/:token" : ""}`;
      case "system":
        return `/system`;
      case "cold-wallet":
        return `/cold-wallet`;
      case "system-control":
        return `/system-control`;
      case "bank-address-list":
        return "/bank-address";
      case "bank-address-detail":
        return `/bank-address${asRoutes ? "/:id" : ""}`;
      case "user-list":
        return "/users";
      case "user-detail":
        return `/users${asRoutes ? "/:id" : ""}`;
      case "quick-exchange":
        return `/quick-exchange`;
      case "sell":
        return `/sell`;
      case "redeem-public":
        return `/mam-kartu`;
      case "redeem":
        return `/karta`;
      case "coinback":
        return `/coinback`;
      case "coinback-redirect":
        return `/coinback/redirect`;
      case "banking-auth":
        return `/banking-auth`;
      case "merchant-payment":
        return `/merchant-payment`;
      case "initiate-payment":
        return `/merchant-payment/initiate`;
      case "initiate-payment-detail":
        return `/merchant-payment/initiate${asRoutes ? "/:id" : ""}`;
      case "customer-payment-pay":
        return `/pay${asRoutes ? "/:id?" : ""}`;
      default:
        return "";
    }
  };
};

export const appUrl = getAppUrl();

export function resolveStartPage(
  appUrl: (page: Pages) => string,
  user: AppUser | null,
  unauthenticatedUrl: string | null,
) {
  if (user === null) {
    return appUrl("sign-in");
  }

  if (unauthenticatedUrl) {
    return unauthenticatedUrl;
  }

  // if (user.isFirstSignIn) {
  //   return appUrl("welcome");
  // }

  return appUrl("dashboard");
}
