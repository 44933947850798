import styled from "styled-components";

type StyledTableWrapperType = {
  gridTemplateColumns: string;
  columnsCount: number;
  hasHeader: boolean;
};

const StyledTableWrapper = styled.div<StyledTableWrapperType>`
  display: grid;
  grid-template-columns: ${props => props.gridTemplateColumns};
  gap: ${props => props.theme.spacing(1, 0)};
  margin-top: ${props => props.theme.spacing(8)};

  & > div {
    border-bottom: 1px solid;
  }

  &
    > div:nth-of-type(${props => props.columnsCount}n
      + ${props => (props.hasHeader ? props.columnsCount + 1 : 1)}) {
    border-left: 0;
  }

  &
    > div:nth-of-type(${props => props.columnsCount}n
      + ${props => props.columnsCount * (props.hasHeader ? 2 : 1)}) {
    border-right: 0;
  }
`;

const StyledHeaderCell = styled.div<{ justifySelf?: string }>`
  font-size: 12px;
  font-weight: 300;
  padding: ${props => props.theme.spacing(0, 3)};
  padding-bottom: ${props => props.theme.spacing(5)};
  color: ${props => props.theme.palette.text.secondary};
  border-color: ${props => props.theme.colors.gray} !important;
  display: flex;
  align-items: center;
  justify-content: ${props => props.justifySelf ?? ""};
`;

const StyledCell = styled.div<{ justifySelf?: string; borderColor: string }>`
  display: flex;
  align-items: center;
  justify-content: ${props => props.justifySelf ?? ""};
  padding: ${props => props.theme.spacing(2, 3)};
  border-color: ${props => props.borderColor} !important;
`;

const StyledNoData = styled.div`
  display: flex;
  justify-content: center;
  border-bottom: 1px solid ${props => props.theme.colors.gray};
  padding: ${props => props.theme.spacing(3)};
`;

export { StyledCell, StyledHeaderCell, StyledTableWrapper, StyledNoData };
