import { CurrencyRateDto, GetRateQuery, GetRateQueryResult } from "Api/Api";
import { produce } from "immer";
import { getRatesHistoryAsync } from "State/Rates/GerRatesHistoryState";
import {
  createReducer,
  ActionType,
  createAsyncAction,
  createAction,
} from "typesafe-actions";

type RateState = {
  watchedRates: GetRateQuery[];
  rates: GetRateQueryResult[];

  isRateHistoryLoading: boolean;
  ratesHistory: CurrencyRateDto[];
};

export const getRateAsync = createAsyncAction(
  "@rate/GET_REQUEST",
  "@rate/GET_SUCCESS",
  "@rate/GET_FAILURE",
)<void, GetRateQueryResult, Error>();

export const addWatchedRate = createAction("@rate/ADD_RATE")<GetRateQuery>();
export const removeWatchedRates = createAction("@rate/REMOVE_RATES")<void>();

type RateAction =
  | ActionType<typeof getRatesHistoryAsync>
  | ActionType<typeof getRateAsync>
  | ActionType<typeof addWatchedRate>
  | ActionType<typeof removeWatchedRates>;

export const rateReducer = createReducer<RateState, RateAction>({
  rates: [],
  watchedRates: [],
  isRateHistoryLoading: false,
  ratesHistory: [],
})
  .handleAction(addWatchedRate, (state, action) => {
    return produce(state, draft => {
      const indexOf = draft.watchedRates.findIndex(
        e =>
          e.fiatCurrencyCode === action.payload.fiatCurrencyCode &&
          e.cryptoCurrencyCode === action.payload.cryptoCurrencyCode,
      );

      if (indexOf > -1) {
        draft.watchedRates.splice(indexOf, 1);
      }

      draft.watchedRates.push(action.payload);

      return draft;
    });
  })
  .handleAction(removeWatchedRates, (state, action) => {
    return produce(state, draft => {
      draft.watchedRates = [];
      return draft;
    });
  })
  .handleAction(getRateAsync.success, (state, action) => {
    return produce(state, draft => {
      draft.rates = draft.rates.filter(
        e =>
          !(
            e.fiatCurrencyCode === action.payload.fiatCurrencyCode &&
            e.cryptoCurrencyCode === action.payload.cryptoCurrencyCode
          ),
      );

      draft.rates.push(action.payload);
      return draft;
    });
  })
  .handleAction(getRatesHistoryAsync.request, (state, action) => {
    return produce(state, draft => {
      draft.isRateHistoryLoading = true;
      return draft;
    });
  })
  .handleAction(getRatesHistoryAsync.success, (state, action) => {
    return produce(state, draft => {
      draft.isRateHistoryLoading = false;
      draft.ratesHistory = action.payload.items.items;
      return draft;
    });
  })
  .handleAction(getRatesHistoryAsync.failure, (state, action) => {
    return produce(state, draft => {
      draft.isRateHistoryLoading = false;
      return draft;
    });
  });
