import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { PageTitle } from "Components/Shared/PageTitles/PageTitle";
import * as React from "react";
import styled from "styled-components";
import { Resources, useResource } from "Translations/Resources";
import { Menu } from "./Menu/Menu";
import { useIsMobile } from "Hooks/useIsMobile";
import {
  BOTTOM_MENU_HEIGHT,
  BottomMenu,
} from "Components/Layout/Menu/BottomMenu";
import mobileBg from "Assets/Images/Images/mobile-bg.webp";
import { useHasBackgroundImage } from "Hooks/Layout/useHasBackgroundImage";

const StyledWrapper = styled(Box)`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: ${props => props.theme.spacing(6, 0, 0, 12)};
  z-index: 2;

  ${props => props.theme.breakpoints.down("lg")} {
    padding: ${props => props.theme.spacing(6, 3)};
  }
`;

const StyledChildrenWrapper = styled.div`
  margin-top: ${props => props.theme.spacing(6)};
  height: 100%;
`;

type Props = {
  title: string;
  hideTitleOnMobile?: boolean;
  goBackUrl?: string;
  onChevronClick?: () => void;
};

export const AuthenticatedLayout: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = props => {
  const { title, goBackUrl, onChevronClick, hideTitleOnMobile } = props;

  const { t } = useResource();
  const theme = useTheme();
  const { isMobile } = useIsMobile();
  const { hasBackgroundImage } = useHasBackgroundImage();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

  React.useEffect(() => {
    document.title = `${title} | ${t(Resources.Common.AppName)}`;
  }, [title, t]);

  return (
    <>
      <Box
        overflow="hidden"
        display="flex"
        paddingBottom={isMobile ? BOTTOM_MENU_HEIGHT : undefined}
      >
        {hasBackgroundImage && (
          <Box
            sx={{
              zIndex: "1",
              position: "absolute",
              top: 0,
              width: "100%",
              minWidth: "350px",
            }}
          >
            <img
              src={mobileBg}
              height="auto"
              width="100%"
              alt="Top background"
            />
            <Typography
              color="white"
              position="absolute"
              variant="h3"
              top={theme.spacing(25)}
              left={theme.spacing(4)}
            >
              {t(Resources.Common.NiceDay)}
            </Typography>
          </Box>
        )}
        {!isMobile && (
          <Menu
            title={title}
            isVisible={true}
            goBackUrl={goBackUrl}
            onChevronClick={onChevronClick}
          />
        )}
        <StyledWrapper marginTop={hasBackgroundImage ? 24 : undefined}>
          {(!isMobile || !hideTitleOnMobile || !isSmall) && (
            <PageTitle
              title={title}
              goBackUrl={goBackUrl}
              onChevronClick={onChevronClick}
            />
          )}
          <StyledChildrenWrapper>{props.children}</StyledChildrenWrapper>
        </StyledWrapper>
      </Box>
      {isMobile && <BottomMenu />}
    </>
  );
};
