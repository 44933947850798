import { TextFieldProps } from "@mui/material";
import { StyledFormControl } from "Components/Shared/FormStyles";
import { TextInput } from "Components/Shared/Inputs/TextInput";
import { get } from "lodash-es";
import {
  Control,
  Controller,
  FieldErrors,
  FieldPath,
  FieldValues,
} from "react-hook-form";

type Props<FormType extends FieldValues> = TextFieldProps & {
  name: FieldPath<FormType>;
  label?: string;
  control: Control<FormType, object>;
  errors?: FieldErrors<FormType>;
  formInfo?: { [key: string]: string };
  maxLength?: number;
  disabled?: boolean;
  mask?: string | NumberConstructor | RegExp;
  isMaskLazy?: boolean;
  maskPlaceholder?: string;
  type?: string;
  max?: number;
  min?: number;
  scale?: number;
  isNumber?: boolean;
  formControlClass?: "long" | "medium" | "tiny";
  unit?: string;
  inputEndAdornment?: JSX.Element;
  valueTransformer?: (value: string) => unknown;
  isGreyIfEmpty?: boolean;
};

export const FormInput = <T extends object>(props: Props<T>) => {
  const {
    name,
    control,
    errors,
    formInfo,
    maxLength,
    label,
    disabled,
    mask,
    isMaskLazy,
    maskPlaceholder,
    type,
    max,
    min,
    scale,
    isNumber,
    formControlClass,
    unit,
    isGreyIfEmpty,
    valueTransformer,
    ...rest
  } = props;

  const transform =
    isNumber || type === "number"
      ? {
          input: (value: number) =>
            !value || isNaN(value) || value === 0 ? "" : value.toString(),
          output: (e: { target: { value: string } }) => {
            const output = Number(e.target.value);
            return isNaN(output) ? 0 : output;
          },
        }
      : {
          input: (value: any) =>
            value === null || value === undefined ? "" : value,
          output: (e: { target: { value: string } }) => {
            return valueTransformer
              ? valueTransformer(e.target.value)
              : e.target.value;
          },
        };

  return (
    <>
      <Controller
        control={control}
        name={name}
        render={({
          field: { onChange, value, onBlur },
          fieldState: { error },
        }) => {
          return (
            <StyledFormControl
              className={`${formControlClass} ${!label ? "no-label" : ""}`}
            >
              <TextInput
                value={transform.input(value as any)}
                name={name.toString()}
                label={label}
                // TODO: Can we fully replace error with errors props?
                fieldError={errors ? get(errors, name as string) : error}
                maxLength={maxLength}
                disabled={disabled}
                onBlur={onBlur}
                mask={mask}
                isMaskLazy={isMaskLazy}
                maskPlaceholder={maskPlaceholder}
                type={type ?? "text"}
                max={max}
                min={min}
                scale={scale}
                unit={unit}
                onInputChange={event => {
                  const value = transform.output(event);

                  onChange(value);
                }}
                onMaskInputChange={v => {
                  const value = isNumber
                    ? Number(v)
                    : transform.output({ target: { value: v } });

                  onChange(value);
                }}
                isGreyIfEmpty={isGreyIfEmpty}
                {...rest}
              />
            </StyledFormControl>
          );
        }}
      />
    </>
  );
};
