import * as React from "react";
export const MoreIcon = (props: React.SVGProps<SVGSVGElement>) => {
  props = {
    ...props,
    color: props.color,
  };
  return (
    <svg
      width={17}
      height={16}
      viewBox="0 0 17 16"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_1612_1096)">
        <path d="M2.96598 5.83301C1.7751 5.83301 0.799316 6.80915 0.799316 7.99967C0.799316 9.19032 1.77534 10.1663 2.96598 10.1663C4.15663 10.1663 5.13265 9.19032 5.13265 7.99967C5.13265 6.80903 4.15663 5.83301 2.96598 5.83301ZM8.29932 5.83301C7.10844 5.83301 6.13265 6.80915 6.13265 7.99967C6.13265 9.19032 7.10867 10.1663 8.29932 10.1663C9.48996 10.1663 10.466 9.19032 10.466 7.99967C10.466 6.80903 9.48996 5.83301 8.29932 5.83301ZM13.6327 5.83301C12.4418 5.83301 11.466 6.80915 11.466 7.99967C11.466 9.19032 12.442 10.1663 13.6327 10.1663C14.8235 10.1663 15.7993 9.1902 15.7993 7.99967C15.7993 6.80903 14.8233 5.83301 13.6327 5.83301ZM2.96598 6.83301C3.616 6.83301 4.13265 7.34965 4.13265 7.99967C4.13265 8.6497 3.616 9.16634 2.96598 9.16634C2.31596 9.16634 1.79932 8.6497 1.79932 7.99967C1.79932 7.34953 2.31553 6.83301 2.96598 6.83301ZM8.29932 6.83301C8.94934 6.83301 9.46598 7.34965 9.46598 7.99967C9.46598 8.6497 8.94934 9.16634 8.29932 9.16634C7.6493 9.16634 7.13265 8.6497 7.13265 7.99967C7.13265 7.34953 7.64886 6.83301 8.29932 6.83301ZM13.6327 6.83301C14.2827 6.83301 14.7993 7.34965 14.7993 7.99967C14.7993 8.64982 14.2831 9.16634 13.6327 9.16634C12.9826 9.16634 12.466 8.6497 12.466 7.99967C12.466 7.34953 12.9822 6.83301 13.6327 6.83301Z" />
      </g>
      <defs>
        <clipPath id="clip0_1612_1096">
          <rect width={16} height={16} transform="translate(0.300049)" />
        </clipPath>
      </defs>
    </svg>
  );
};
