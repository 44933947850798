import { Typography } from "@mui/material";
import { UnstyledLink } from "Components/Routes/UnstyledLink";
import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";
import { getAppUrl } from "Utils/UrlUtils";

export const InProgressStep: React.FunctionComponent<
  React.PropsWithChildren<unknown>
> = _ => {
  const appUrl = getAppUrl();

  return (
    <>
      <br />
      <Typography>Všechny informace a doklady byly nahrány.</Typography>
      <br />

      <Typography>
        Po jejich kontrole bude Váš účet ověřen a limity zvýšeny.
      </Typography>
      <br />

      <Typography>
        Ověření může trvat až 2 pracovní dny, ale budeme se snažit stihnout to
        dříve!
      </Typography>
      <br />
      <br />

      <UnstyledLink to={appUrl("dashboard")}>
        <PrimaryButton fullWidth>Na přehled</PrimaryButton>
      </UnstyledLink>
    </>
  );
};
