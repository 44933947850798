import { yupResolver } from "@hookform/resolvers/yup";
import { Typography } from "@mui/material";
import { AuthPageLayout } from "Components/Auth/Shared/AuthPageLayout";
import { StyledContainer } from "Components/Auth/Styles";
import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";
import { FormInput } from "Components/Shared/Inputs/Form/FormInput";
import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { ResetPasswordStep } from "State/Auth/Models/AuthStateModels";
import {
  resetPasswordAsync,
  setPasswordResetStep,
} from "State/Auth/Passwords/ResetPasswordState";
import type { RootStateType } from "State/Store";
import styled from "styled-components";
import { Resources, useResource } from "Translations/Resources";
import { object, type ObjectSchema, string } from "yup";

type FormModel = {
  login: string;
};

const StyledForm = styled.form`
  margin-top: ${props => props.theme.spacing(4)};
`;

const StyledSubmitButton = styled(PrimaryButton)`
  margin-top: ${props => props.theme.spacing(3)};
`;

const SuccesssMessageWrapper = styled.div`
  margin-top: ${props => props.theme.spacing(4)};
  margin-bottom: ${props => props.theme.spacing(4)};
`;

export const ForgottenPasswordPage: React.FunctionComponent<
  React.PropsWithChildren<unknown>
> = _ => {
  const { t } = useResource();
  const dispatch = useDispatch();

  const validationSchema: ObjectSchema<FormModel> = object({
    login: string().required(t(Resources.Validation.Required)),
  }).defined();

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<FormModel>({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
    defaultValues: { login: "" },
  });

  const onSubmit = (data: FormModel) => {
    dispatch(resetPasswordAsync.request({ login: data.login }));
  };

  const { isLoading, resetPasswordStep } = useSelector(
    (e: RootStateType) => e.auth,
  );

  React.useEffect(() => {
    return () => {
      dispatch(setPasswordResetStep(ResetPasswordStep.Email));
    };
  }, [dispatch]);

  return (
    <>
      <AuthPageLayout
        title={"Zapomenuté heslo"}
        isLoading={isLoading}
        pageType={"other"}
      >
        {resetPasswordStep === ResetPasswordStep.Email && (
          <StyledForm onSubmit={handleSubmit(onSubmit)}>
            <StyledContainer>
              <div>
                <FormInput
                  autoFocus
                  control={control}
                  name="login"
                  errors={errors}
                  label="E-mail"
                  valueTransformer={v => v.toLowerCase()}
                />

                <StyledSubmitButton
                  type="submit"
                  variant="contained"
                  color="info"
                  isLoading={isLoading}
                  fullWidth
                >
                  {"Resetovat heslo"}
                </StyledSubmitButton>
              </div>
            </StyledContainer>
          </StyledForm>
        )}

        {resetPasswordStep === ResetPasswordStep.Success && (
          <StyledContainer>
            <div>
              <SuccesssMessageWrapper>
                <Typography>
                  {
                    "Pokud uživatel s daným e-mailem existuje, odeslali jsme na něj pokyny k obnovení hesla."
                  }
                </Typography>
              </SuccesssMessageWrapper>
            </div>
          </StyledContainer>
        )}
      </AuthPageLayout>
    </>
  );
};

export default ForgottenPasswordPage;
