import { useMutation } from "@tanstack/react-query";
import { BANKING_ACCOUNTS_QUERY_KEY } from "Api/Queries/useBankingAccounts";
import { queryClient } from "App";
import {
  BankAccountListItem,
  deleteBankingAccountsAccountID,
  EntityListOfBankAccountListItem,
} from "Infrastructure/Api/Api";
import { processResponse } from "Infrastructure/Api/ApiUtils";

export const useBankingAccountDelete = () => {
  return useMutation({
    mutationFn: async (bankAccountID: BankAccountListItem["bankAccountID"]) => {
      await queryClient.cancelQueries({ queryKey: BANKING_ACCOUNTS_QUERY_KEY });
      const accounts =
        queryClient.getQueryData<EntityListOfBankAccountListItem>(
          BANKING_ACCOUNTS_QUERY_KEY,
        );
      queryClient.setQueryData(BANKING_ACCOUNTS_QUERY_KEY, {
        ...accounts,
        items: accounts?.items?.filter(i => i.bankAccountID !== bankAccountID),
      });

      await processResponse(() =>
        deleteBankingAccountsAccountID(bankAccountID),
      )();
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: BANKING_ACCOUNTS_QUERY_KEY });
    },
  });
};
