import { put, takeLatest } from "redux-saga/effects";
import { createAsyncAction, getType } from "typesafe-actions";
import { call } from "typed-redux-saga";
import {
  EntityListOfTransactionListItemDto,
  postTransactionTransactionList,
} from "Api/Api";

export const getTransactionListAsync = createAsyncAction(
  "@TRANSACTION/LIST_REQUEST",
  "@TRANSACTION/LIST_SUCCESS",
  "@TRANSACTION/LIST_FAILURE",
)<
  { limit: number; offset: number; isShowMoreShown: boolean },
  EntityListOfTransactionListItemDto,
  Error
>();

function* getTransactionList(
  action: ReturnType<typeof getTransactionListAsync.request>,
): Generator {
  try {
    const response = yield* call(postTransactionTransactionList, {
      limit: action.payload.limit,
      offset: action.payload.offset,
    });

    if (response.status === 200) {
      yield put(getTransactionListAsync.success(response.json));
    } else {
      yield put(
        getTransactionListAsync.failure(
          new Error(`Error in action ${action.type}`, {
            cause: response.status,
          }),
        ),
      );
    }
  } catch (err) {
    yield put(getTransactionListAsync.failure(err as Error));
  }
}
export function* getTransactionListSaga() {
  yield takeLatest(
    getType(getTransactionListAsync.request),
    getTransactionList,
  );
}
