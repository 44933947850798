import { useMutation } from "@tanstack/react-query";
import { PostAuthSignUpPasswordlessFetchResponse } from "Infrastructure/Api/Api";
import { processResponse } from "Infrastructure/Api/ApiUtils";
import { useDispatch } from "react-redux";
import { signUpPasswordlessAsync } from "State/Auth/SignUp/SignUpPasswordlessState";
import { v4 as uuid } from "uuid";

export const useSignUpPasswordless = () => {
  const dispatch = useDispatch();

  return useMutation({
    mutationFn: processResponse(
      () =>
        new Promise<PostAuthSignUpPasswordlessFetchResponse>(
          (resolve, reject) => {
            dispatch(
              signUpPasswordlessAsync.request({
                model: { isAnonymous: true, login: uuid() },
                successCallback: resolve,
                failedCallback: reject,
              }),
            );
          },
        ),
    ),
  });
};
