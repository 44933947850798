import * as React from "react";
import { PrimarySkeleton } from "Components/Shared/PrimarySkeleton";
import styled from "styled-components";

type Props = {
  rowsCount: number;
};

const StyledSkeleton = styled(PrimarySkeleton)`
  margin-bottom: ${props => props.theme.spacing(2)};
`;

export const TableSkeleton: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = props => {
  const rows = () => {
    let result: number[] = [];
    for (let index = 0; index < props.rowsCount; index++) {
      result.push(index);
    }
    return result;
  };

  return (
    <>
      {rows().map((x, i) => (
        <StyledSkeleton key={i} height={60} variant="rectangular" />
      ))}
    </>
  );
};
