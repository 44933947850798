import { Typography } from "@mui/material";
import { AuthPageLayout } from "Components/Auth/Shared/AuthPageLayout";
import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { resendVerificationEmailAsync } from "State/Auth/Verifications/ResendVerificationEmailState";
import { RootStateType } from "State/Store";
import styled from "styled-components";
import { getAppUrl } from "Utils/UrlUtils";

const Wrapper = styled.div`
  margin: ${props => props.theme.spacing(4)};
`;

export const AwaitingEmailVerificationPage: React.FunctionComponent<
  React.PropsWithChildren<unknown>
> = _ => {
  const { verificationEmail, user } = useSelector((e: RootStateType) => e.auth);
  const dispatch = useDispatch();

  const [wasResend, setWasResend] = React.useState(false);

  const resend = () => {
    if (!!verificationEmail && !wasResend) {
      dispatch(
        resendVerificationEmailAsync.request({
          email: verificationEmail,
        }),
      );
      setWasResend(true);
    }
  };

  const navigate = useNavigate();
  const appUrl = getAppUrl();

  React.useEffect(() => {
    if (!!user) {
      navigate(appUrl("dashboard"), { replace: true });
    }
  }, [user, navigate, appUrl]);

  return (
    <AuthPageLayout
      title={"Ověření e-mailu"}
      isLoading={false}
      pageType={"other"}
    >
      <Wrapper>
        <Typography fontSize={16}>Na Vaši e-mailovou adresu:</Typography>
        <br />
        {!!verificationEmail && (
          <Typography fontSize={16} fontWeight={600}>
            {verificationEmail}
          </Typography>
        )}
        <br />
        <Typography fontSize={16}>
          byl odeslán e-mail s aktivačním odkazem. Před pokračováním je nutné
          účet aktivovat kliknutím na odkaz v tomto e-mailu.
        </Typography>
        <br />
        <br />
        <Typography fontSize={16}>
          PS: Žádný e-mail nedorazil? Zkuste zkontrolovat složku SPAM nebo
          Hromadné. Případně si nechte poslat e-mail znovu.
        </Typography>
        <br />
        <PrimaryButton
          onClick={() => resend()}
          type="submit"
          fullWidth
          variant="contained"
          color="info"
          disabled={wasResend}
        >
          {wasResend ? "E-mail byl odeslán" : "Odeslat e-mail znovu"}
        </PrimaryButton>
      </Wrapper>
    </AuthPageLayout>
  );
};

export default AwaitingEmailVerificationPage;
