import { Typography } from "@mui/material";
import { FC, PropsWithChildren } from "react";

export const PaperTitle: FC<PropsWithChildren<{}>> = ({ children }) => {
  return (
    <Typography fontWeight={600} fontSize={16} textTransform="uppercase">
      {children}
    </Typography>
  );
};
