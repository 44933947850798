import { Typography } from "@mui/material";
import { AuthenticatedLayout } from "Components/Layout/AuthenticatedLayout";
import { InvoiceSettingsForm } from "Components/Settings/InvoiceSettings/InvoiceSettingsForm";
import { PrimaryPaper } from "Components/Shared/PrimaryPaper";
import { StyledPageWrapper } from "Components/Shared/StyledComponents";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getInvoiceSettingsAsync,
  saveInvoiceSettingsAsync,
} from "State/Invoices/InvoiceSettings/InvoiceSettingsState";
import { RootStateType } from "State/Store";
import styled from "styled-components";
import { getAppUrl } from "Utils/UrlUtils";

const Trivia = styled.div`
  font-size: 16px;
`;

export const AccountSettingsPage: React.FunctionComponent<
  React.PropsWithChildren<unknown>
> = _ => {
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(getInvoiceSettingsAsync.request());

    return () => {
      dispatch(getInvoiceSettingsAsync.success(null));
    };
  }, [dispatch]);

  const { invoiceSettings } = useSelector((e: RootStateType) => e.invoice);

  const appUrl = getAppUrl();

  return (
    <AuthenticatedLayout
      title="Uchování bitcoinů"
      goBackUrl={appUrl("settings")}
    >
      <StyledPageWrapper>
        <div className="left-content">
          <PrimaryPaper>
            <Trivia>
              <Typography>
                Bitcoinová adresa peněženky, kam chcete zasílat nakoupené
                bitcoiny.
              </Typography>
              <Typography>
                Po provedení obchodu můžeme nakoupené bitcoiny poslat do Vaší
                peněženky nebo nechat bezpečně uložené u nás.
              </Typography>
            </Trivia>

            {invoiceSettings && (
              <InvoiceSettingsForm
                model={invoiceSettings}
                onSubmit={model =>
                  dispatch(saveInvoiceSettingsAsync.request(model))
                }
              />
            )}
          </PrimaryPaper>
        </div>
      </StyledPageWrapper>
    </AuthenticatedLayout>
  );
};

export default AccountSettingsPage;
