import { Typography, Tooltip } from "@mui/material";
import {
  AdditionalInfoStatus,
  BankVerificationStatus,
  EntityListOfUserDto,
  IdentityVerificationStatus,
  RiskProfileStatus,
  UserDto,
} from "Api/Api";
import {
  PrimaryDataTable,
  TableIDType,
} from "Components/Shared/PrimaryDataTable/PrimaryDataTable";
import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { formatDate } from "Utils/DateUtils";
import { getAppUrl } from "Utils/UrlUtils";
import InfoIcon from "@mui/icons-material/Info";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

type Props = {
  list: EntityListOfUserDto | null;
  isLoading: boolean;
  rowsPerPage: number;
  onPageChange: (page: number, rows: number) => void;
};

const CellWrapper = styled.div`
  cursor: pointer;
`;

type VerificationCellProps = {
  user: UserDto;
};

const VerificationCell: React.FunctionComponent<
  React.PropsWithChildren<VerificationCellProps>
> = props => {
  const { user } = props;

  const isCompletelyVerified =
    user.verificationAdditionalInfoStatus === AdditionalInfoStatus.Verified &&
    user.verificationIdentityVerificationStatus ===
      IdentityVerificationStatus.Verified &&
    user.verificationRiskProfile === RiskProfileStatus.NoRisk &&
    user.verificationBankVerificationStatus === BankVerificationStatus.Verified;
  return (
    <CellWrapper>
      <Tooltip
        title={
          <div>
            <Typography>
              Dodatečné informace: {user.verificationAdditionalInfoStatus}
            </Typography>
            <Typography>
              ID: {user.verificationIdentityVerificationStatus}
            </Typography>
            <Typography>Risk: {user.verificationRiskProfile}</Typography>
            <Typography>
              Bank. účet: {user.verificationBankVerificationStatus}
            </Typography>
          </div>
        }
      >
        {isCompletelyVerified ? (
          <CheckCircleOutlineIcon color="secondary" />
        ) : (
          <InfoIcon color="warning" />
        )}
      </Tooltip>
    </CellWrapper>
  );
};

export const UserListTable: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = props => {
  const { isLoading, list, rowsPerPage, onPageChange } = props;

  const getCell = (id: TableIDType<UserDto>, data: UserDto) => {
    switch (id) {
      case "verificationAdditionalInfoStatus":
        return <VerificationCell user={data} />;
      case "login":
      case "firstName":
      case "lastName":
        return <CellWrapper>{data[id]}</CellWrapper>;
      case "dateCreated":
        return <CellWrapper>{formatDate(data.dateCreated, true)}</CellWrapper>;
      default:
        return <CellWrapper>{}</CellWrapper>;
    }
  };

  const navigate = useNavigate();
  const appUrl = getAppUrl();

  return (
    <PrimaryDataTable
      rows={list?.items.map(e => ({ data: e })) ?? []}
      rowEntityID={"userID"}
      columns={[
        { label: "Login", id: "login" },
        { label: "Jméno", id: "firstName" },
        { label: "Příjmění", id: "lastName" },
        {
          label: "Ověření",
          id: "verificationAdditionalInfoStatus",
        },
        { label: "Datum vytvoření", id: "dateCreated" },
      ]}
      getCell={getCell}
      getHeaderCell={column => (
        <div>
          <Typography fontWeight={600}>{column.label}</Typography>
        </div>
      )}
      totalCount={list?.totalCount}
      onPageChange={onPageChange}
      isLoading={isLoading}
      rowsPerPage={rowsPerPage}
      onRowClick={userDto =>
        navigate(`${appUrl("user-detail")}/${userDto.userID}`)
      }
    ></PrimaryDataTable>
  );
};
