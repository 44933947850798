import { SystemSettingType } from "Api/Api";
import { RootStateType } from "State/Store";
import { useSelector } from "react-redux";

export const useLeadReward = () => {
  const { codeLists } = useSelector((e: RootStateType) => e.codeList);

  const reward =
    Number(
      codeLists?.systemSettings?.find(
        e => e.type === SystemSettingType.LeadRewardForBoth,
      )?.value ?? "0",
    ) / 2;

  return reward;
};
