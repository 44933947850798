import { styled, Typography } from "@mui/material";
import { LeadInvitationCommandResultStatus } from "Api/Api";
import { LeadInvitationForm } from "Components/Account/LeadInvitationForm";
import { AuthenticatedLayout } from "Components/Layout/AuthenticatedLayout";
import { PrimaryPaper } from "Components/Shared/PrimaryPaper";
import { StyledPageWrapper } from "Components/Shared/StyledComponents";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { resetLeadInvitationStatus } from "State/Afl/AffiliateReducer";
import { inviteLeadAsync } from "State/Afl/LeadInvitation/LeadInvitation";
import { RootStateType } from "State/Store";
import { getAppUrl } from "Utils/UrlUtils";

const Trivia = styled(Typography)``;

export const LeadInvitationPage: React.FunctionComponent<
  React.PropsWithChildren<unknown>
> = _ => {
  const dispatch = useDispatch();

  const { leadInvitationStatus, isLoading } = useSelector(
    (e: RootStateType) => e.affiliate,
  );

  React.useEffect(() => {
    return () => {
      dispatch(resetLeadInvitationStatus());
    };
  }, [dispatch]);

  const isFormShown =
    !leadInvitationStatus ||
    leadInvitationStatus === LeadInvitationCommandResultStatus.UserExists ||
    leadInvitationStatus === LeadInvitationCommandResultStatus.LeadExists;

  const isSuccessShown =
    leadInvitationStatus === LeadInvitationCommandResultStatus.Success;

  const appUrl = getAppUrl();

  return (
    <AuthenticatedLayout title="Pozvat přítele" goBackUrl={appUrl("affiliate")}>
      <StyledPageWrapper>
        <div className="left-content">
          <PrimaryPaper>
            {isFormShown && (
              <>
                <Trivia>
                  Zadejte e-mail přítele, kterého chcete pozvat. Pokud bude Váš
                  přítel účet aktivně využívat, dostanete za něj odměnu!
                </Trivia>
                <br />
                <LeadInvitationForm
                  isLoading={isLoading}
                  status={leadInvitationStatus}
                  onSubmit={model =>
                    dispatch(inviteLeadAsync.request({ email: model.email }))
                  }
                />
              </>
            )}

            {isSuccessShown && (
              <Trivia>Děkujeme. Váš přítel byl úspěšně pozván.</Trivia>
            )}
          </PrimaryPaper>
        </div>
      </StyledPageWrapper>
    </AuthenticatedLayout>
  );
};

export default LeadInvitationPage;
