import * as React from "react";
import { Resources, useResource } from "Translations/Resources";
import { useForm } from "react-hook-form";
import { string, object, type ObjectSchema } from "yup";
import { Typography } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormInput } from "Components/Shared/Inputs/Form/FormInput";
import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";
import styled from "styled-components";
import { SecondaryButton } from "Components/Shared/Buttons/SecondaryButton";
import { SignInStep } from "State/Auth/Models/AuthStateModels";
import { StyledContainer } from "Components/Auth/Styles";
import { UnstyledLink } from "Components/Routes/UnstyledLink";
import { getAppUrl } from "Utils/UrlUtils";
import { nameof } from "Utils/ObjectUtils";

type Props = {
  isLoading: boolean;
  serverError: string | null;
  step: SignInStep;
  login: string;
  onSubmit: (formData: FormData) => void;
  onReset: () => void;
};

const StyledForm = styled.form`
  margin-top: ${props => props.theme.spacing(2)};
`;

const StyledSubmitButton = styled(PrimaryButton)`
  margin-top: ${props => props.theme.spacing(3)};
`;

const StyledCancelButton = styled(SecondaryButton)`
  margin-top: 15px;
`;

const StyledServerError = styled(Typography)`
  color: ${props => props.theme.colors.redDark};
`;

const ForgottenPassword = styled(UnstyledLink)`
  display: flex;
  justify-content: end;
  font-weight: 600;
  font-size: 14px;
`;

export type FormData = {
  login: string;
  password: string;
  token?: string;
};

export const SignInForm: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = props => {
  const { t } = useResource();

  const { isLoading, serverError, step, login, onReset } = props;
  const validationSchema: ObjectSchema<FormData> = object({
    login: string().required(t(Resources.Validation.Required)),
    password: string().required(t(Resources.Validation.Required)),
    token: string().when(nameof<FormData>("login"), ([_], schema) =>
      step === SignInStep.VerificationToken
        ? schema.required(t(Resources.Validation.Required))
        : schema.notRequired(),
    ),
  }).defined();

  const {
    handleSubmit,
    formState: { errors },
    setError,
    control,
  } = useForm<FormData>({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
    defaultValues: { login: login, password: "", token: "" },
  });

  React.useEffect(() => {
    if (serverError) {
      setError("login", {
        type: "required",
        message: " ",
      });
    }
  }, [serverError, setError]);

  const onSubmit = (data: FormData) => {
    props.onSubmit(data);
  };

  const appUrl = getAppUrl();

  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)}>
      <StyledContainer>
        <div>
          {step === SignInStep.Credentials && (
            <>
              <FormInput
                autoFocus={login.length === 0}
                control={control}
                name="login"
                errors={errors}
                label="E-mail"
                fullWidth={true}
                valueTransformer={v => v.toLowerCase()}
              />
              <ForgottenPassword to={appUrl("forgottenPassword")}>
                Zapomněli jste heslo?
              </ForgottenPassword>
              <FormInput
                autoFocus={login.length > 0}
                control={control}
                name="password"
                errors={errors}
                label="Heslo"
                type="password"
                autoComplete={"current-password"}
              />
            </>
          )}

          {step === SignInStep.VerificationToken && (
            <FormInput
              autoFocus
              control={control}
              name="token"
              errors={errors}
              label="Verification token"
            />
          )}

          <StyledServerError variant="subtitle1">
            {serverError}
          </StyledServerError>

          <StyledSubmitButton
            type="submit"
            fullWidth
            variant="contained"
            color="info"
            isLoading={isLoading}
          >
            Přihlásit
          </StyledSubmitButton>

          {step === SignInStep.VerificationToken && (
            <StyledCancelButton
              onClick={() => onReset()}
              type="reset"
              fullWidth
              variant="contained"
              color="primary"
              disabled={isLoading}
            >
              Cancel
            </StyledCancelButton>
          )}
        </div>
      </StyledContainer>
    </StyledForm>
  );
};
