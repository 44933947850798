import { useQuery } from "@tanstack/react-query";
import { getTransactionsSum, TransactionStatus2 } from "Infrastructure/Api/Api";
import { processResponse } from "Infrastructure/Api/ApiUtils";

type Props = {
  startDate?: Date;
  endDate?: Date;
  allowedStatuses?: TransactionStatus2[];
};

export const useTransactionsSum = ({
  startDate,
  endDate,
  allowedStatuses,
}: Props = {}) =>
  useQuery({
    queryKey: ["TRANSACTIONS_SUM", { startDate, endDate, allowedStatuses }],
    queryFn: processResponse(() =>
      getTransactionsSum(
        startDate?.toString(),
        endDate?.toString(),
        allowedStatuses,
      ),
    ),
    refetchInterval: 30_000,
  });
