import { yupResolver } from "@hookform/resolvers/yup";
import { Typography, Divider } from "@mui/material";
import { UserNotificationSettingsDto } from "Api/Api";
import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";
import { CheckboxInput } from "Components/Shared/Inputs/Form/CheckboxInput";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { bool, object, ObjectSchema } from "yup";

type Props = {
  model: UserNotificationSettingsDto;
  onSubmit: (data: UserNotificationSettingsDto) => void;
};

const StyledForm = styled.form`
  margin: ${props => props.theme.spacing(3, 0)};
`;

const StyledLabel = styled.div`
  display: flex;
  flex-flow: column;
  width: 70%;
  margin: ${props => props.theme.spacing(1, 0)};
`;

const StyledSubmitButton = styled(PrimaryButton)`
  margin: ${props => props.theme.spacing(6, 0)};
`;

export const NotificationSettingsForm: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = props => {
  const { model, onSubmit } = props;

  const validationSchema: ObjectSchema<UserNotificationSettingsDto> = object({
    isFilledOrderNotificationActive: bool().defined(),
    isCreatedOrderNotificationActive: bool().defined(),
    isWithdrawalNotificationActive: bool().defined(),
  }).defined();

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<UserNotificationSettingsDto>({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
    defaultValues: model,
  });

  return (
    <>
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <CheckboxInput
          control={control}
          errors={errors}
          name="isCreatedOrderNotificationActive"
          label={
            <StyledLabel>
              <Typography fontWeight={600}>
                Notifikace o přijaté objednávce
              </Typography>
              <Typography>
                Po přijaté objednávce Vám zašleme e-mailovou notifikaci.
              </Typography>
            </StyledLabel>
          }
          isLabelFirst={true}
        ></CheckboxInput>
        <Divider />

        <CheckboxInput
          control={control}
          errors={errors}
          name="isFilledOrderNotificationActive"
          label={
            <StyledLabel>
              <Typography fontWeight={600}>
                Notifikace o vypořádáném obchodu
              </Typography>
              <Typography>
                Po vypořádaném obchodu Vám zašleme e-mailovou notifikaci.
              </Typography>
            </StyledLabel>
          }
          isLabelFirst={true}
        ></CheckboxInput>
        <Divider />

        <CheckboxInput
          control={control}
          errors={errors}
          name="isWithdrawalNotificationActive"
          label={
            <StyledLabel>
              <Typography fontWeight={600}>Notifikace po výběru</Typography>
              <Typography>
                Po výběru bitcoinů do Vaší peněženky Vám zašleme e-mailovou
                notifikaci.
              </Typography>
            </StyledLabel>
          }
          isLabelFirst={true}
        ></CheckboxInput>
        <Divider />

        <StyledSubmitButton
          type="submit"
          fullWidth
          variant="contained"
          color="info"
        >
          <Typography>Uložit</Typography>
        </StyledSubmitButton>
      </StyledForm>
    </>
  );
};
