import { Grid, Typography } from "@mui/material";
import { ForwardIcon } from "Components/Shared/Icons";
import type { TransactionItem as TransactionItemType } from "Infrastructure/Api/Api";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Resources, useResource } from "Translations/Resources";
import { formatCurrency } from "Utils/CurrencyUtils";
import { formatDate } from "Utils/DateUtils";
import {
  getInstructionStatusName,
  getTransactionStatusName,
} from "Utils/TransactionUtils";
import { getAppUrl } from "Utils/UrlUtils";

type Props = {
  item: TransactionItemType;
  direction: "incoming" | "outgoing";
};

const StyledWrapper = styled.div`
  margin: ${props => props.theme.spacing(2.5, 0)};
  padding-bottom: ${props => props.theme.spacing(1.5)};
  border-bottom: 1px solid ${props => props.theme.colors.border};
  cursor: pointer;
`;

const ComponentResources = Resources.Transactions.Payment;

export const TransactionItem: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = ({ item, direction }) => {
  const { t } = useResource();
  const {
    amount,
    currencyCode,
    dateCreated,
    publicID,
    status,
    instructionStatus,
  } = item;

  const navigate = useNavigate();
  const appUrl = getAppUrl();

  return (
    <>
      <StyledWrapper
        onClick={() => navigate(`${appUrl("transaction-detail")}/${publicID}`)}
      >
        <Grid container justifyItems={"center"} alignContent="center">
          <Grid
            item
            xs={2}
            sm={1}
            display="flex"
            alignSelf={"center"}
            justifyContent="flex-start"
          >
            <ForwardIcon width={26} />
          </Grid>

          <Grid
            container
            item
            xs={5}
            sm={8}
            display="flex"
            alignSelf="center"
            direction="column"
          >
            <Grid item xs={12}>
              <Typography fontWeight={600}>
                {t(
                  ComponentResources[
                    direction === "incoming" ? "Incoming" : "Outgoing"
                  ],
                )}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography>
                {instructionStatus
                  ? getInstructionStatusName(t, instructionStatus)
                  : getTransactionStatusName(t, status)}
              </Typography>
            </Grid>
          </Grid>

          <Grid
            container
            item
            xs={5}
            sm={3}
            display="flex"
            justifySelf={"flex-end"}
            justifyContent="flex-end"
            justifyItems={"flex-end"}
            alignSelf="flex-end"
            alignItems={"flex-end"}
            direction="column"
          >
            <>
              <Typography fontWeight={600}>
                {formatCurrency(amount, currencyCode)}
              </Typography>
              {!!dateCreated && (
                <Typography>{formatDate(dateCreated)}</Typography>
              )}
              {
                /*item.status === TransactionStatus.Completed*/ false && (
                  <Typography>
                    +
                    {/*formatCurrency(
                      item.cleanAmount,
                      item.currencyCode,
                      undefined,
                      true,
                    )*/}
                  </Typography>
                )
              }
            </>

            {/*item.type === TransactionType.Withdrawal && (
              <>
                <Typography fontWeight={600}>
                  {formatCurrency(
                    item.cleanAmount,
                    item.currencyCode,
                    undefined,
                    true,
                  )}
                </Typography>
                <Typography>{getStatusName(t, item)}</Typography>
              </>
            )}
            {item.type === TransactionType.Coinback && (
              <>
                <Typography fontWeight={600}>
                  {formatCurrency(
                    item.payoutCommissionValue,
                    item.payoutCommissionCurrencyCode,
                    undefined,
                    true,
                  )}
                </Typography>
                {item.status === TransactionStatus.PendingPayout && (
                  <Typography>{getStatusName(t, item)}</Typography>
                )}
                {item.status === TransactionStatus.Completed && (
                  <Typography>
                    +
                    {formatCurrency(
                      item.cleanAmount,
                      item.currencyCode,
                      undefined,
                      true,
                    )}
                  </Typography>
                )}
              </>
            )*/}
          </Grid>
        </Grid>
      </StyledWrapper>
    </>
  );
};
