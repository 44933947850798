import { createAsyncAction } from "typesafe-actions";
import { put, takeLatest } from "redux-saga/effects";
import {
  ProcessInvoiceRateChangeRequest,
  ProcessInvoiceRateChangeCommandResult,
  postInvoiceProcessInvoiceRateChange,
} from "Api/Api";
import { getType } from "typesafe-actions";
import { call } from "typed-redux-saga";
import { toast } from "sonner";
import { NavigateFunction } from "react-router-dom";
import { getAppUrl } from "Utils/UrlUtils";

export const processInvoiceRateChangeAsync = createAsyncAction(
  "@invoice/PROCESS_INVOICE_RATE_CHANGE_REQUEST",
  "@invoice/PROCESS_INVOICE_RATE_CHANGE_SUCCESS",
  "@invoice/PROCESS_INVOICE_RATE_CHANGE_FAILURE",
)<
  { request: ProcessInvoiceRateChangeRequest; navigate: NavigateFunction },
  ProcessInvoiceRateChangeCommandResult,
  Error
>();

function* processInvoiceRateChange(
  action: ReturnType<typeof processInvoiceRateChangeAsync.request>,
): Generator {
  try {
    const response = yield* call(
      postInvoiceProcessInvoiceRateChange,
      action.payload.request,
    );
    if (response.status === 200) {
      yield put(processInvoiceRateChangeAsync.success(response.json));
      toast.success("Invoice rate change was accepted.");

      const url = getAppUrl();
      yield* call(() => action.payload.navigate(url("dashboard")));
    } else {
      yield put(
        processInvoiceRateChangeAsync.failure(
          new Error(`Error in action ${action.type}`, {
            cause: response.status,
          }),
        ),
      );
    }
  } catch (err) {
    yield put(processInvoiceRateChangeAsync.failure(err as Error));
  }
}
export function* processInvoiceRateChangeSaga() {
  yield takeLatest(
    getType(processInvoiceRateChangeAsync.request),
    processInvoiceRateChange,
  );
}
