import { Stack, Typography } from "@mui/material";
import { useTransactionPay } from "Api/Mutations/Transactions/useTransactionPay";
import { useBankingAccounts } from "Api/Queries/useBankingAccounts";
import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";
import { BankingAccountSelector } from "Components/Shared/Inputs/Form/BankingAccountSelector";
import { FORCE_BANK_ACCOUNT_SELECT_STEP } from "Pages/Customer/AnonymousCustomerPaymentPayPage";
import { FC } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { toast } from "sonner";
import { Resources, useResource } from "Translations/Resources";

type Props = {
  transactionPublicID: string;
};

type FormModel = {
  bankAccountID: number;
};

const ComponentResources = Resources.Customer.Pay;

export const BankAccountSelector: FC<Props> = ({ transactionPublicID }) => {
  const { t } = useResource();
  const { data } = useBankingAccounts();
  const {
    control,
    handleSubmit,
    formState: { isDirty },
  } = useForm<FormModel>();
  const navigate = useNavigate();

  const { mutateAsync: payTransaction, isPending } = useTransactionPay();

  const onSubmit = async ({ bankAccountID }: FormModel) => {
    const result = await payTransaction({
      publicID: transactionPublicID,
      senderBankAccountID: bankAccountID,
    });

    if (result.signingUrl) {
      window.location.href = result.signingUrl;
    } else {
      toast.error("Neznámá chyba během platby");
    }
  };

  const onAddBankAccount = () => {
    navigate({ search: `?step=${FORCE_BANK_ACCOUNT_SELECT_STEP}` });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Typography marginBottom={4}>
        {t(ComponentResources.ChooseBankAccount)}
      </Typography>
      <BankingAccountSelector
        bankAccounts={data?.items ?? []}
        control={control}
        name="bankAccountID"
      />
      <Stack gap={3}>
        <PrimaryButton
          fullWidth
          color="primary"
          disabled={!isDirty}
          type="submit"
          isLoading={isPending}
        >
          {t(ComponentResources.PayButton)}
        </PrimaryButton>

        <PrimaryButton onClick={onAddBankAccount}>
          {t(ComponentResources.AddNextBankAccount)}
        </PrimaryButton>
      </Stack>
    </form>
  );
};
