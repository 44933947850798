/* eslint-disable */
// THIS FILE WAS GENERATED
// ALL CHANGES WILL BE OVERWRITTEN

// INFRASTRUCTURE START
  export type StandardError = globalThis.Error;
  export type Error500s = 501 | 502 | 503 | 504 | 505 | 506 | 507 | 508 | 510 | 511;
  export type ErrorStatuses = 0 | Error500s;
  export type ErrorResponse = FetchResponse<unknown, ErrorStatuses>;

  export type FetchResponseOfError = {
    data: null;
    error: StandardError;
    status: ErrorStatuses;
    args: any;
  };

  export type FetchResponseOfSuccess<TData, TStatus extends number = 0> = 
  {
    data: TData;
    error: null;
    status: TStatus;
    args: any;
    responseHeaders: Headers;
  };

  export type FetchResponse<TData, TStatus extends number = 0> = 
    TStatus extends ErrorStatuses ? FetchResponseOfError: FetchResponseOfSuccess<TData, TStatus>;

  export type TerminateRequest = null;
  export type TerminateResponse = null;

  export type Configuration = {
    apiUrl: string | (() => string);
    jwtKey: string | undefined | (() => string | null | undefined);
    requestMiddlewares?: Array<{
      name: string;
      fn: (request: FetchArgs) => FetchArgs | TerminateRequest;
    }>;
    responseMiddlewares?: Array<{
      name: string;
      fn: (
        response: FetchResponse<unknown, any>
      ) => FetchResponse<unknown, any> | TerminateResponse;
    }>;
  };

  let CONFIG: Configuration = {
    apiUrl: () => "",
    jwtKey: undefined,
    requestMiddlewares: [],
    responseMiddlewares: [],
  };

  export function setupClient(configuration: Configuration) {
    CONFIG = {
      ...CONFIG,
      ...configuration,
      requestMiddlewares: [
        ...(CONFIG.requestMiddlewares || []),
        ...(configuration.requestMiddlewares || []),
      ],
      responseMiddlewares: [
        ...(CONFIG.responseMiddlewares || []),
        ...(configuration.responseMiddlewares || []),
      ],
    };
  }

  export function getApiUrl() {
    if (typeof CONFIG.apiUrl === "function") {
      return CONFIG.apiUrl();
    }
    return CONFIG.apiUrl;
  }

  export type Termination = {
    termination: {
      name: string;
    };
  };

  export function processRequestWithMiddlewares(
    request: FetchArgs
  ): FetchArgs | Termination {
    for (const middleware of CONFIG.requestMiddlewares || []) {
      try {
        const middlewareResponse = middleware.fn(request);
        if (middlewareResponse === null) {
          return { termination: { name: middleware.name } };
        }
        request = middlewareResponse;
      } catch (e) {
        console.error("Request middleware error", e);
      }
    }
    return request;
  }

  export function processResponseWithMiddlewares<T extends FetchResponse<unknown, any>>(
    response: T
  ): T | Termination {
    for (const middleware of CONFIG.responseMiddlewares || []) {
      try {
        const middlewareResponse = middleware.fn(response);
        if (middlewareResponse === null) {
          return {
            status: 0,
            args: response.args,
            data: null,
            error: new Error(
              `Response terminated by middleware: ${middleware.name}`
            ),
          } as FetchResponseOfError as unknown as T;
        }
        response = middlewareResponse as T;
      } catch (e) {
        console.error("Response middleware error", e);
      }
    }
    return response;
  }

  export type FetchOptions = {
    method: string;
    headers: Headers;
    body?: any;
    redirect: RequestRedirect;
  };

  export type FetchArgs = {
    url: string;
    options: FetchOptions;
  }

  export async function fetchJson<T extends FetchResponse<unknown, number>>(
    args: FetchArgs
  ): Promise<T> {
    const errorResponse = (error: StandardError, status: number, args: any) => {
      const errorResponse = {
        status: status as ErrorStatuses,
        args,
        data: null,
        error,
      } satisfies FetchResponse<T>;

      return processResponseWithMiddlewares(errorResponse) as unknown as T;
    };

    const errorStatus = (args: any) => {
      const errorResponse = {
        status: 0,
        args,
        data: null,
        error: new Error("Network error"),
      } as FetchResponse<T, Error500s>;

      return processResponseWithMiddlewares(errorResponse) as unknown as T;
    };

    try {
      const fetchRequest = processRequestWithMiddlewares(args);

      if ("termination" in fetchRequest) {
        const terminationResponse = {
          status: 0,
          args,
          data: null,
          error: new Error(
            `Request terminated by middleware: ${fetchRequest.termination.name}`
          ),
        } as FetchResponse<T, Error500s>;

        return processResponseWithMiddlewares(
          terminationResponse
        ) as unknown as T;
      }

      const fetchResponse: Response = await fetch(fetchRequest.url, fetchRequest.options);
      const status = fetchResponse.status;
      try {
        const json = await fetchResponse.json();
        const response = {
          data: json,
          status: fetchResponse.status,
          args,
          error: null,
          responseHeaders: fetchResponse.headers,
        };
        return processResponseWithMiddlewares(response) as unknown as T;
      } catch (error) {
        return errorResponse(error as StandardError, status, args);
      }
    } catch {
      return errorStatus(args);
    }
  }

  export function getJwtKey(): string | null | undefined {
    if (typeof CONFIG.jwtKey === "function") {
      return CONFIG.jwtKey();
    }

    if (typeof CONFIG.jwtKey === "string") {
      return localStorage.getItem(CONFIG.jwtKey);
    }

    return undefined;
  } 
  
  
 function getApiRequestData<Type extends any>(
    requestContract: Type | undefined,
    isFormData: boolean = false
  ): FormData | Type | {} {
  
    if (!isFormData) {
      return requestContract !== undefined ? requestContract : {};
    }
  
    //multipart/form-data
    const formData = new FormData();
  
     if (requestContract) {
      Object.keys(requestContract).forEach(key => {
        const value = requestContract[key as keyof Type];
        const isKeyArrayAndValueIterable = key.endsWith('[]') && typeof (value as any)[Symbol.iterator] === 'function';
        const values = isKeyArrayAndValueIterable ? Array.from(value as Iterable<any>) : [value];
          for (const val of values) {
              if (val === undefined) {
                  continue;
              } else if (val === null) {
                  formData.append(key, '');
              } else if (val instanceof File) {
                  formData.append(key, val);
              } else if (typeof val === 'object' && val !== null) {
                  formData.append(key, JSON.stringify(val));
              } else {
                  formData.append(key, val as any);
              }
          }
      });
    }
  
    return formData;
  }

  
  function updateHeadersAndGetBody<TResponse extends FetchResponse<unknown, number>, TRequest>(
    request: TRequest,
    headers: Headers
  ) {
    updateHeaders(headers);
    if (request instanceof FormData) {
      headers.delete("Content-Type");
      return request;
    } else {      
      return JSON.stringify(request);
    }
  }
  
  function updateHeaders(headers: Headers) {
    if (!headers.has("Content-Type")) {
      headers.append("Content-Type", "application/json");
    }
    const token = getJwtKey();
    if (!headers.has("Authorization") && !!token) {
      headers.append("Authorization", token);
    }
  }

export function getQueryParamsString(paramsObject: ParamsObject = {}) {
	const queryString = Object.entries(paramsObject)
    .map(([key, value]) => {
      if (Array.isArray(value)) {
        return value
          .map(val => `${encodeURIComponent(key)}=${encodeURIComponent(
            val,
          )}`)
          .join('&');
      }
      // Handling non-array parameters
      return value !== undefined && value !== null 
        ? `${encodeURIComponent(key)}=${encodeURIComponent(value)}` 
        : '';
    })
    .filter(part => part !== '')
    .join("&");

	return queryString.length > 0 ? `?${queryString}` : '';
}

export function apiPost<TResponse extends FetchResponse<unknown, number>, TRequest>(
  url: string,
  request: TRequest,
  headers: Headers,
  paramsObject: ParamsObject = {}
) {
  
  const raw = updateHeadersAndGetBody(request, headers); 

  const requestOptions: FetchOptions = {
    method: "POST",
    headers,
    body: raw,
    redirect: "follow",
  };

  const maybeQueryString = getQueryParamsString(paramsObject);

  return fetchJson<TResponse>({
    url: `${url}${maybeQueryString}`,
    options: requestOptions,
  });
}

export type ParamsObject = {
  [key: string]: any;
};

export function apiGet<TResponse extends FetchResponse<unknown, number>>(
  url: string,
  headers: Headers,
  paramsObject: ParamsObject = {}
) {
  updateHeaders(headers);
  
  const maybeQueryString = getQueryParamsString(paramsObject);

  const requestOptions: FetchOptions = {
    method: "GET",
    headers,
    redirect: "follow",
  };

  return fetchJson<TResponse>({
    url: `${url}${maybeQueryString}`,
    options: requestOptions,
  });
}

export function apiPut<TResponse extends FetchResponse<unknown, number>, TRequest>(
  url: string,
  request: TRequest,
  headers: Headers,
  paramsObject: ParamsObject = {}
) {
  updateHeaders(headers);

  const raw = JSON.stringify(request);

  const requestOptions: FetchOptions = {
    method: "PUT",
    headers,
    body: raw,
    redirect: "follow",
  };

  const maybeQueryString = getQueryParamsString(paramsObject);

  return fetchJson<TResponse>({
    url: `${url}${maybeQueryString}`,
    options: requestOptions,
  });
}

export function apiDelete<TResponse extends FetchResponse<unknown, number>>(
  url: string,
  headers: Headers,
  paramsObject: ParamsObject = {}
) {
  updateHeaders(headers);

  const queryString = Object.entries(paramsObject)
    .filter(([_, val]) => val !== undefined && val !== null)
    .map(([key, val]) => `${key}=${val}`)
    .join("&");
  
  const maybeQueryString = queryString.length > 0 ? `?${queryString}` : "";

  const requestOptions: FetchOptions = {
    method: "DELETE",
    headers,
    redirect: "follow",
  };

  return fetchJson<TResponse>({
    url: `${url}${maybeQueryString}`,
    options: requestOptions,
  });
}

export function apiPatch<TResponse extends FetchResponse<unknown, number>, TRequest>(
  url: string,
  request: TRequest,
  headers: Headers,
  paramsObject: ParamsObject = {}
) {
  updateHeaders(headers);

  const raw = JSON.stringify(request);

  const requestOptions: FetchOptions = {
    method: "PATCH",
    headers,
    body: raw,
    redirect: "follow",
  };
  const maybeQueryString = getQueryParamsString(paramsObject);

  return fetchJson<TResponse>({
    url: `${url}${maybeQueryString}`,
    options: requestOptions,
  });
}
// INFRASTRUCTURE END

export type SignInResult = {
	userStatus: UserStatus;
	status: SignInStatus;
	token?: string | null;
	login?: string | null;
	firstName?: string | null;
	lastName?: string | null;
	userIdentityProviders: IdentityProvider[];
	error: AuthError;
	errorMessage?: string | null;
	accessRightCodes: string[];
	isFirstSignIn: boolean;
	profilePicture?: string | null;
};

export enum UserStatus {
	Unknown = "Unknown",
	InvitedWaitingForConfirmation = "InvitedWaitingForConfirmation",
	Active = "Active",
	WaitingForEmailConfirmation = "WaitingForEmailConfirmation",
	Deactivated = "Deactivated",
	BlockedFinances = "BlockedFinances",
	Anonymous = "Anonymous",
	Onboarding = "Onboarding"
};

export enum SignInStatus {
	Success = "Success",
	CodeVerificationStep = "CodeVerificationStep",
	Failed = "Failed",
	UnsafeSuccess = "UnsafeSuccess"
};

export enum IdentityProvider {
	Application = "Application",
	Google = "Google",
	BankID = "BankID",
	Apple = "Apple"
};

export enum AuthError {
	BankIDTokenError = "BankIDTokenError",
	BankIDUserInfoError = "BankIDUserInfoError",
	ApplicationVerification = "ApplicationVerification",
	InvalidCredentials = "InvalidCredentials",
	AccountIsDeactivated = "AccountIsDeactivated",
	AccountNotFound = "AccountNotFound",
	PhoneNumberNotFound = "PhoneNumberNotFound",
	SmsSendingFailed = "SmsSendingFailed",
	InvalidToken = "InvalidToken",
	AccountWaitingForEmailConfirmation = "AccountWaitingForEmailConfirmation"
};

export type SignInCommand = {
	login: string;
	password: string;
	token?: string | null;
};

export type SignUpCommandResult = {
	status: SignUpCommandResultStatus;
};

export enum SignUpCommandResultStatus {
	Success = "Success",
	UserExists = "UserExists",
	WeakPassword = "WeakPassword"
};

export type SignUpCommand = {
	login: string;
	password: string;
	affiliateCode?: string | null;
};

export type PasswordlessSignUpCommandResult = {
	status: PasswordlessSignUpCommandResultStatus;
	signInResult?: SignInResult | null;
	subsidyFiatAmount?: number | null;
	subsidyFiatCurrencyCode?: string | null;
};

export enum PasswordlessSignUpCommandResultStatus {
	UserExists = "UserExists",
	UserCreated = "UserCreated"
};

export type PasswordlessSignUpCommand = {
	login: string;
	voucherCode?: string | null;
	isAnonymous: boolean;
};

export type EmailVerificationCommandResult = {
	signInResult?: SignInResult | null;
	status: EmailVerificationCommandResultStatus;
};

export enum EmailVerificationCommandResultStatus {
	NotFound = "NotFound",
	Success = "Success"
};

export type EmailVerificationCommand = {
	token: string;
};

export type ResendVerificationEmailCommandResult = {
	status: ResendVerificationEmailCommandResultStatus;
};

export enum ResendVerificationEmailCommandResultStatus {
	Success = "Success"
};

export type ResendVerificationEmailCommand = {
	email: string;
};

export type SsoSignInRequest = {
	token: string;
	affiliateCode?: string | null;
	identityProvider: IdentityProvider;
};

export type UserInfoQueryResult = {
	token: string;
	login: string;
	firstName: string;
	lastName: string;
	profilePicture?: string | null;
	isFirstSignIn: boolean;
	userIdentityProviders: IdentityProvider[];
	accessRightCodes: string[];
	status: UserStatus;
};

export type SendPhoneVerificationTokenCommandResult = {
	status: SendPhoneVerificationTokenCommandResultStatus;
};

export enum SendPhoneVerificationTokenCommandResultStatus {
	Success = "Success",
	TokenNotFound = "TokenNotFound",
	SmsSendingFailed = "SmsSendingFailed"
};

export type SendPhoneVerificationTokenCommand = {
	token: string;
	phone: string;
};

export type VerifyPhoneCommandResult = {
	status: VerifyPhoneCommandResultStatus;
	signInResult?: SignInResult | null;
};

export enum VerifyPhoneCommandResultStatus {
	Success = "Success",
	TokenNotFound = "TokenNotFound"
};

export type VerifyPhoneCommand = {
	token: string;
};

export type ResetPasswordCommandResult = {
	status: ResetPasswordCommandResultStatus;
};

export enum ResetPasswordCommandResultStatus {
	Success = "Success",
	Fail = "Fail"
};

export type ResetPasswordCommand = {
	login: string;
};

export type SetPasswordCommandResult = {
	status: SetPasswordCommandStatus;
	signInResult?: SignInResult | null;
};

export enum SetPasswordCommandStatus {
	Success = "Success",
	WeakPassword = "WeakPassword",
	TokenNotFound = "TokenNotFound"
};

export type SetPasswordCommand = {
	token: string;
	password: string;
};

export type GetBalanceQueryResult = {
	availableBalance: number;
	currencyCode: string;
	fiatBalance: number;
	fiatCurrencyCode: string;
	profitPercent: number;
	profitFiat: number;
};

export type AddWithdrawalToQueueCommandResult = {
	status: AddWithdrawalToQueueCommandResultStatus;
};

export enum AddWithdrawalToQueueCommandResultStatus {
	Success = "Success",
	AccountNotFound = "AccountNotFound",
	InsufficientFunds = "InsufficientFunds",
	BlockedFinances = "BlockedFinances"
};

export type WithdrawalRequest = {
	address: string;
	amount: number;
	priority: WithdrawalPriority;
};

export enum WithdrawalPriority {
	Low = "Low",
	High = "High"
};

export type SellCommandResult = {
	status: SellCommandResultStatus;
};

export enum SellCommandResultStatus {
	AccountNotFound = "AccountNotFound",
	InsufficientFunds = "InsufficientFunds",
	Success = "Success",
	RateExpired = "RateExpired",
	BankAccountDoesNotBelongToUser = "BankAccountDoesNotBelongToUser"
};

export type SellRequest = {
	amount: number;
	fiatPrice: number;
	userBankAccountID: number;
};

export type GetCodeListCollectionQueryResult = {
	countries: CodeListItemDto[];
	systemSettings: SystemSettingDto[];
};

export type CodeListItemDto = {
	id: number;
	alpha2Code: string;
	alpha3Code: string;
	name: string;
};

export type SystemSettingDto = {
	type: SystemSettingType;
	value: string;
};

export enum SystemSettingType {
	InvoicePaymentProcessorJob = "InvoicePaymentProcessorJob",
	TradeJob = "TradeJob",
	WithdrawalJob = "WithdrawalJob",
	SendinblueEmails = "SendinblueEmails",
	LeadRewardForBoth = "LeadRewardForBoth",
	WithdrawalStaticFeeSat = "WithdrawalStaticFeeSat",
	MaxInvitationCount = "MaxInvitationCount",
	MempoolFeeLevel = "MempoolFeeLevel"
};

export type EntityListOfOfferListItemDto = {
	offset: number;
	limit: number;
	totalCount: number;
	items: OfferListItemDto[];
};

export type OfferListItemDto = {
	name: string;
	webUrl: string;
	commissionType: CommissionType;
	commissionValue: number;
	commissionCurrencyCode?: string | null;
	logoUrl?: string | null;
	rank: number;
	visibility: OfferVisibility;
	bestLink: string;
};

export enum CommissionType {
	Percents = "Percents",
	Fiat = "Fiat"
};

export enum OfferVisibility {
	Visible = "Visible",
	Hidden = "Hidden",
	Promoted = "Promoted"
};

export type EntityListOfCategoryDto = {
	offset: number;
	limit: number;
	totalCount: number;
	items: CategoryDto[];
};

export type CategoryDto = {
	name: string;
	code: string;
	rank: number;
};

export type EntityListOfOfferBrowserExtensionDto = {
	offset: number;
	limit: number;
	totalCount: number;
	items: OfferBrowserExtensionDto[];
};

export type OfferBrowserExtensionDto = {
	webUrl: string;
};

export type OfferDto = {
	name: string;
	webUrl: string;
	commissionType: CommissionType;
	commissionValue: number;
	commissionCurrencyCode?: string | null;
	logoUrl?: string | null;
	rank: number;
	visibility: OfferVisibility;
	bestLink: string;
};

export type EntityListOfInvoiceListItemDto = {
	offset: number;
	limit: number;
	totalCount: number;
	items: InvoiceListItemDto[];
};

export type InvoiceListItemDto = {
	invoiceID: number;
	askAmount: number;
	askCurrencyID: number;
	askCurrencyCode: string;
	status: InvoiceStatus;
	paymentReference: number;
	type: InvoiceType;
	payerReference: string;
	accountNumber: string;
	iban: string;
};

export enum InvoiceStatus {
	New = "New",
	Paid = "Paid",
	Processed = "Processed",
	WaitingForRateConfirmation = "WaitingForRateConfirmation",
	WaitingForRateConfirmationAndPaid = "WaitingForRateConfirmationAndPaid",
	DeclinedBecauseRateConfirmation = "DeclinedBecauseRateConfirmation",
	AcceptedBecauseRateConfirmation = "AcceptedBecauseRateConfirmation"
};

export enum InvoiceType {
	Opened = "Opened",
	FixedPrice = "FixedPrice",
	MarketPrice = "MarketPrice",
	CustomPrice = "CustomPrice"
};

export type CreateInvoiceCommandResult = {
	status: CreateInvoiceCommandResultStatus;
	invoiceID: number;
};

export enum CreateInvoiceCommandResultStatus {
	Success = "Success"
};

export type CreateInvoiceRequest = {
	askCurrencyCode: string;
	askAmount?: number | null;
	bidCurrencyCode: string;
	bidAmount?: number | null;
	rate?: number | null;
	ownershipType: OwnershipType;
	cryptoAddress?: string | null;
	invoiceType: InvoiceType;
};

export enum OwnershipType {
	NonCustodial = "NonCustodial",
	Custodial = "Custodial"
};

export type GetInvoiceRateChangeDetailQueryResult = {
	invoiceRateChangeID: number;
	invoiceID: number;
	invoiceFixedRate: number;
	invoiceAskAmount: number;
	invoiceAskCurrencyCode: string;
	rate: number;
	dateCalculated: string;
	externalID: string;
};

export type GetInvoiceRateChangeDetailQuery = {
	externalID: string;
};

export type ProcessInvoiceRateChangeCommandResult = {
	status: ProcessInvoiceRateChangeCommandResultStatus;
};

export enum ProcessInvoiceRateChangeCommandResultStatus {
	Success = "Success",
	Fail = "Fail",
	NotFound = "NotFound"
};

export type ProcessInvoiceRateChangeRequest = {
	isAccepted: boolean;
	externalID: string;
};

export type InvoiceSettingsDto = {
	ownershipType: OwnershipType;
	cryptoAddress?: string | null;
};

export type SaveInvoiceSettingsCommandResult = {
	status: SaveInvoiceSettingsCommandResultStatus;
};

export enum SaveInvoiceSettingsCommandResultStatus {
	Success = "Success"
};

export type GetUserFeeRateQueryResult = {
	feeRate: number;
	remainingAmount: number;
	remainingAmountCurrencyCode: string;
	nextFeeRate: number;
};

export type EntityListOfLeadDto = {
	offset: number;
	limit: number;
	totalCount: number;
	items: LeadDto[];
};

export type LeadDto = {
	email: string;
	status: LeadStatus;
	code: string;
};

export enum LeadStatus {
	Invited = "Invited",
	Registered = "Registered",
	Completed = "Completed",
	CompletedRewardSent = "CompletedRewardSent",
	CompletedRewardApplied = "CompletedRewardApplied"
};

export type LeadInvitationCommandResult = {
	status: LeadInvitationCommandResultStatus;
};

export enum LeadInvitationCommandResultStatus {
	Success = "Success",
	UserExists = "UserExists",
	LeadExists = "LeadExists"
};

export type InviteLeadRequest = {
	email: string;
};

export type ErrorWebLogCommand = {
	dateCreated: string;
	webLogRequests: WebLogRequest[];
};

export type WebLogRequest = {
	dateCreated: string;
	error: string;
	additionalInfo?: string | null;
	reduxState?: string | null;
};

export type UserNotificationSettingsDto = {
	isFilledOrderNotificationActive: boolean;
	isCreatedOrderNotificationActive: boolean;
	isWithdrawalNotificationActive: boolean;
};

export type SaveUserNotificationSettingsCommandResult = {
	status: SaveUserNotificationSettingsCommandResultStatus;
};

export enum SaveUserNotificationSettingsCommandResultStatus {
	Success = "Success"
};

export type GetRateQueryResult = {
	fiatCurrencyCode: string;
	cryptoCurrencyCode: string;
	askRate: number;
	bidRate: number;
	dateExecuted: string;
};

export type GetRateQuery = {
	fiatCurrencyCode: string;
	cryptoCurrencyCode: string;
	useCache: boolean;
};

export type GetRateHistoryQueryResult = {
	items: EntityListOfCurrencyRateDto;
};

export type EntityListOfCurrencyRateDto = {
	offset: number;
	limit: number;
	totalCount: number;
	items: CurrencyRateDto[];
};

export type CurrencyRateDto = {
	dateCreated: string;
	askCurrencyCode: string;
	bidCurrencyCode: string;
	ask: number;
	bid: number;
};

export type GetSystemReportQueryResult = {
	exchangeWithdrawalFee: number;
	exchangeWithdrawSum: number;
	withdrawalSum: number;
	blockchainFee: number;
	mempoolFeeLevel: string;
	coinmateBalances: ExchangeBalanceDto[];
	userAccountBalance: number;
	coinmateVolume: TradedVolumeDto;
	coinmateMonthlyVolume: TradedVolumeDto;
	coinmateWeeklyVolume: TradedVolumeDto;
	coinmateDailyVolume: TradedVolumeDto;
	coinmateBoughtBtc: number;
	withdrawalTransactionSum: number;
	accountingSumBTC: number;
	accountingSumCZK: number;
	walletBalance: GetBitcoinWalletBalanceQueryResult;
	coldWalletBalance: GetBitcoinWalletBalanceQueryResult;
	sellTransactionSum: number;
};

export type ExchangeBalanceDto = {
	currencyCode: string;
	availableBalance: number;
	reservedBalance: number;
};

export type TradedVolumeDto = {
	currencyCode: string;
	askedVolume: number;
};

export type GetBitcoinWalletBalanceQueryResult = {
	balance: number;
	fiatBalance: number;
};

export type ProcessSystemWithdrawalResult = {
	status: ProcessSystemWithdrawalResultStatus;
};

export enum ProcessSystemWithdrawalResultStatus {
	Success = "Success"
};

export type SystemWithdrawalRequest = {
	amount: number;
	address: string;
};

export type GenerateSystemAddressCommandResult = {
	address: string;
	status: GenerateSystemAddressCommandResultStatus;
};

export enum GenerateSystemAddressCommandResultStatus {
	Success = "Success"
};

export type GetSystemSettingQuery = {
	systemSettingType: SystemSettingType;
};

export type SaveSystemSettingCommandResult = {
	status: SaveSystemSettingCommandResultStatus;
};

export enum SaveSystemSettingCommandResultStatus {
	Success = "Success"
};

export type SaveSystemSettingCommand = {
	type: SystemSettingType;
	value: string;
};

export type EntityListOfTransactionListItemDto = {
	offset: number;
	limit: number;
	totalCount: number;
	items: TransactionListItemDto[];
};

export type TransactionListItemDto = {
	externalID: string;
	cleanPrice: number;
	cleanAmount: number;
	currencyCode?: string | null;
	currencyName: string;
	blockchainTransactionID?: string | null;
	fee: number;
	type: TransactionType;
	dateChanged: string;
	orderFee?: number | null;
	orderPrice?: number | null;
	orderAskAmount?: number | null;
	orderAskCurrencyCode?: string | null;
	orderBidCurrencyCode?: string | null;
	status: TransactionStatus;
	voucherType?: VoucherType | null;
	payoutCommissionValue?: number | null;
	payoutCommissionCurrencyCode?: string | null;
	payoutOfferLinkOfferName?: string | null;
};

export enum TransactionType {
	Buy = "Buy",
	Sell = "Sell",
	Withdrawal = "Withdrawal",
	LeadReward = "LeadReward",
	Voucher = "Voucher",
	Coinback = "Coinback",
	LeadRewardConsumer = "LeadRewardConsumer"
};

export enum TransactionStatus {
	Completed = "Completed",
	PendingTrade = "PendingTrade",
	WithdrawRequested = "WithdrawRequested",
	Withdrawn = "Withdrawn",
	PendingPayout = "PendingPayout"
};

export enum VoucherType {
	GiftCard = "GiftCard",
	SignUpSubsidy = "SignUpSubsidy",
	GiftCardDigital = "GiftCardDigital"
};

export type GetTransactionListRequest = {
	offset: number;
	limit: number;
};

export type GetTransactionDetailQueryResult = {
	detail?: TransactionDetailDto | null;
};

export type TransactionDetailDto = {
	externalID: string;
	cleanPrice: number;
	cleanAmount: number;
	currencyCode?: string | null;
	currencyName: string;
	recipientAddress?: string | null;
	blockchainTransactionID?: string | null;
	fee: number;
	type: TransactionType;
	dateChanged: string;
	orderFee?: number | null;
	orderPrice?: number | null;
	orderAskAmount?: number | null;
	orderAskCurrencyCode?: string | null;
	orderBidCurrencyCode?: string | null;
	status: TransactionStatus;
	voucherType?: VoucherType | null;
	payoutCommissionValue?: number | null;
	payoutCommissionCurrencyCode?: string | null;
	payoutOfferLinkOfferName?: string | null;
};

export type UserBankAccountDetailDto = {
	accountNumberIBANEUR: string;
	externalID: string;
	paymentReference: string;
	accountNumber: string;
	accountNumberIBAN: string;
};

export type UserBankAccountDetailRequest = {
	externalID?: string | null;
};

export type EntityListOfUserBankAccountListDto = {
	offset: number;
	limit: number;
	totalCount: number;
	items: UserBankAccountListDto[];
};

export type UserBankAccountListDto = {
	userBankAccountID: number;
	userID: number;
	iban?: string | null;
	status: UserBankAccountStatus;
	externalID: string;
	formattedAccountNumber?: string | null;
};

export enum UserBankAccountStatus {
	New = "New",
	NewToVerify = "NewToVerify",
	Verified = "Verified",
	VerifiedIBANParsingError = "VerifiedIBANParsingError",
	Rejected = "Rejected"
};

export type CreateUserBankAccountCommandResult = {
	externalID: string;
	status: CreateUserBankAccountCommandResultStatus;
};

export enum CreateUserBankAccountCommandResultStatus {
	Success = "Success"
};

export type EntityListOfUserDto = {
	offset: number;
	limit: number;
	totalCount: number;
	items: UserDto[];
};

export type UserDto = {
	userID: number;
	login: string;
	firstName: string;
	lastName: string;
	dateCreated: string;
	verificationAdditionalInfoStatus: AdditionalInfoStatus;
	verificationIdentityVerificationStatus: IdentityVerificationStatus;
	verificationRiskProfile: RiskProfileStatus;
	verificationBankVerificationStatus: BankVerificationStatus;
};

export enum AdditionalInfoStatus {
	NotVerified = "NotVerified",
	Verified = "Verified",
	NeedsInvestigation = "NeedsInvestigation"
};

export enum IdentityVerificationStatus {
	NotVerified = "NotVerified",
	Verified = "Verified",
	Processing = "Processing"
};

export enum RiskProfileStatus {
	NotVerified = "NotVerified",
	NoRisk = "NoRisk",
	Risk = "Risk",
	Unacceptable = "Unacceptable"
};

export enum BankVerificationStatus {
	NotVerified = "NotVerified",
	Verified = "Verified",
	InProgress = "InProgress"
};

export type GetUserListQuery = {
	offset: number;
	limit: number;
};

export type UserAdminDetailDto = {
	userID: number;
	login: string;
	email: string;
	status: UserStatus;
	firstName?: string | null;
	lastName?: string | null;
	verificationAdditionalInfoStatus: AdditionalInfoStatus;
	verificationRiskProfile: RiskProfileStatus;
	verificationSourceOfIncome: SourceOfIncome;
	verificationHasMoneyFromCriminalActivityOrIsFinancingTerrorism: boolean;
	verificationHasConnectionToSanctionedCountry: boolean;
	verificationIsPoliticallyExposedPerson: boolean;
	verificationIdentityVerificationStatus: IdentityVerificationStatus;
	verificationBankVerificationStatus: BankVerificationStatus;
	verificationVerificationJson?: string | null;
	verificationExternalID?: string | null;
	verificationBirthnumberSha256?: string | null;
	verificationDailyBuyLimit: number;
	verificationMonthlyBuyLimit: number;
	hasIdentificationsUploaded: boolean;
};

export enum SourceOfIncome {
	Unknown = "Unknown",
	AlimonyOrWelfareOrPension = "AlimonyOrWelfareOrPension",
	WageOrSalary = "WageOrSalary",
	BusinessIncome = "BusinessIncome",
	CapitalGains = "CapitalGains"
};

export type SaveUserAdminDetailCommandResult = {
	status: SaveUserAdminDetailCommandResultStatus;
};

export enum SaveUserAdminDetailCommandResultStatus {
	Success = "Success"
};

export type DeactivateUserCommandResult = {
	status: DeactivateUserCommandResultStatus;
};

export enum DeactivateUserCommandResultStatus {
	Success = "Success"
};

export type UserVerificationDetailDto = {
	additionalInfoStatus: AdditionalInfoStatus;
	riskProfile: RiskProfileStatus;
	isPoliticallyExposedPerson: boolean;
	identityVerificationStatus: IdentityVerificationStatus;
	bankVerificationStatus: BankVerificationStatus;
	verificationLevel: number;
};

export type GetUserLimitsQueryResult = {
	remainingDailyLimit: number;
	remainingMonthlyLimit: number;
	currencyCode: string;
	verificationLevel: number;
	dailyBuyLimit: number;
	monthlyBuyLimit: number;
};

export type ProcessBankIDVerificationCommandResult = {
	status: ProcessBankIDVerificationCommandResultStatus;
	signInResult?: SignInResult | null;
};

export enum ProcessBankIDVerificationCommandResultStatus {
	BankIDUserInfoError = "BankIDUserInfoError",
	Success = "Success",
	Fail = "Fail",
	VerificationAlreadyExists = "VerificationAlreadyExists"
};

export type BankIDVerificationRequest = {
	token: string;
};

export type SetAdditionalInfoCommandResult = {
	verificationDetail: UserVerificationDetailDto;
};

export type SetAdditionalInfoRequest = {
	hasMoneyFromCriminalActivityOrIsFinancingTerrorism: boolean;
	hasConnectionToSanctionedCountry: boolean;
	isPoliticallyExposedPerson: boolean;
	sourceOfIncome: SourceOfIncome;
};

export type UploadIdentificationCommandResult = {
	status: UploadIdentificationCommandResultStatus;
};

export enum UploadIdentificationCommandResultStatus {
	Success = "Success",
	MissingFiles = "MissingFiles"
};

export type SetBankIdentificationInProgressCommandResult = {
	status: SetBankIdentificationInProgressCommandResultStatus;
	verificationDetail: UserVerificationDetailDto;
};

export enum SetBankIdentificationInProgressCommandResultStatus {
	Success = "Success"
};

export type ValidateVoucherCommandResult = {
	status: ValidateVoucherCommandResultStatus;
	code?: string | null;
};

export enum ValidateVoucherCommandResultStatus {
	NotFound = "NotFound",
	Valid = "Valid"
};

export type ValidateVoucherCommand = {
	code?: string | null;
};

export type ApplyVoucherCommandResult = {
	status: ApplyVoucherCommandResultStatus;
	code?: string | null;
	voucherFiatAmount?: number | null;
	login?: string | null;
	voucherFiatCurrencyID?: number | null;
};

export enum ApplyVoucherCommandResultStatus {
	NotFound = "NotFound",
	Applied = "Applied",
	UserAccountNotFound = "UserAccountNotFound",
	UserNotFound = "UserNotFound",
	UserIsWaitingForSignIn = "UserIsWaitingForSignIn",
	NotAllowed = "NotAllowed"
};

export type ApplyVoucherRequest = {
	code?: string | null;
	login?: string | null;
};

export type UserVerificationDto = {
	publicID: string;
	userID: number;
	sourceOfIncome: SourceOfIncome;
	isPoliticallyExposedPerson: boolean;
	additionalInfoStatus: AdditionalInfoStatus;
	identityVerificationStatus: IdentityVerificationStatus;
	riskProfile: RiskProfileStatus;
	bankVerificationStatus: BankVerificationStatus;
	verificationLevel: number;
};

export type UserVerificationUpdateDto = {
	isPoliticallyExposedPerson: boolean;
};

export type InitiateTransactionResponse = {
	transaction: TransactionDto;
};

export type TransactionDto = {
	transactionID: number;
	publicID: string;
	amount: number;
	currencyID: number;
	receiverIBAN?: string | null;
	receiverBankAccountID?: number | null;
};

export type InitiateTransactionRequest = {
	amount: number;
	receiverBankAccountID?: number | null;
	receiverIBAN?: string | null;
};

export type TransactionStatusResponse = {
	transactionStatus: TransactionStatus2;
	paymentInstructionStatus?: PaymentInstructionStatus | null;
};

export enum TransactionStatus2 {
	Initiated = "Initiated",
	Processing = "Processing",
	Success = "Success",
	Fail = "Fail",
	Cancelled = "Cancelled"
};

export enum PaymentInstructionStatus {
	None = "None",
	AcceptedTechnicalValidation = "AcceptedTechnicalValidation",
	AcceptedCustomerProfile = "AcceptedCustomerProfile",
	AcceptedSettlementCompleted = "AcceptedSettlementCompleted",
	Rejected = "Rejected",
	AcceptedWithChange = "AcceptedWithChange"
};

export type PayTransactionResponse = {
	signingUrl: string;
};

export type PayTransactionRequest = {
	publicID: string;
	senderBankAccountID: number;
};

export type EntityListOfTransactionItem = {
	offset: number;
	limit: number;
	totalCount: number;
	items: TransactionItem[];
};

export type TransactionItem = {
	publicID: string;
	dateCreated: string;
	amount: number;
	currencyCode: string;
	status: TransactionStatus2;
	instructionStatus?: PaymentInstructionStatus | null;
};

export type TransactionDetailDto2 = {
	publicID: string;
	amount: number;
	creditorIBAN: string;
	debtorIBAN: string;
	transactionStatus: TransactionStatus2;
	paymentInstructionStatus?: PaymentInstructionStatus | null;
	paymentReference?: string | null;
	noteForDebtor?: string | null;
};

export type TransactionSumResponse = {
	totalSum: number;
};

export type SendInvoiceRequest = {
	email: string;
	transactionPublicID: string;
};

export type PartyDto = {
	publicID?: string | null;
	type: PartyType;
	firstName?: string | null;
	lastName?: string | null;
	companyName?: string | null;
	taxNumber?: string | null;
	companyNumber?: string | null;
	personalNumber?: string | null;
	birthDate?: string | null;
	gender: Gender;
	birthPlace?: string | null;
	nationalityCountryID?: number | null;
	isForeigner: boolean;
	isVATPayer?: boolean | null;
	phone?: string | null;
	email?: string | null;
	identification?: IdentificationType | null;
	identificationNumber?: string | null;
	identificationValidFrom?: string | null;
	identificationValidTo?: string | null;
	identificationIssuer?: string | null;
	companyRepresentativeFirstName?: string | null;
	companyRepresentativeLastName?: string | null;
	companyRepresentativeBirthDate?: string | null;
	addresses: AddressDto[];
};

export enum PartyType {
	NaturalPerson = "NaturalPerson",
	LegalEntity = "LegalEntity",
	SelfEmployed = "SelfEmployed"
};

export enum Gender {
	Male = "Male",
	Female = "Female"
};

export enum IdentificationType {
	IDCard = "IDCard",
	Passport = "Passport",
	Other = "Other"
};

export type AddressDto = {
	addressID?: number | null;
	type: AddressType;
	street?: string | null;
	streetNumber?: string | null;
	orientationNumber?: string | null;
	postalCode: string;
	municipality: string;
};

export enum AddressType {
	Permanent = "Permanent",
	Mail = "Mail"
};

export type EntityListOfBankAccountListItem = {
	offset: number;
	limit: number;
	totalCount: number;
	items: BankAccountListItem[];
};

export type BankAccountListItem = {
	bankAccountID: number;
	iban: string;
	currency: string;
	balance: number;
	rank: number;
	productName: string;
	publicID: string;
	environment: BankEnvironment;
	bank: Bank;
};

export enum BankEnvironment {
	CSOB_SANDBOX = "CSOB_SANDBOX",
	CSOB_PROD = "CSOB_PROD",
	CSAS_SANDBOX = "CSAS_SANDBOX",
	CSAS_PROD = "CSAS_PROD",
	KB_SANDBOX = "KB_SANDBOX",
	KB_PROD = "KB_PROD",
	AirBank_SANDBOX = "AirBank_SANDBOX",
	AirBank_PROD = "AirBank_PROD",
	UCB_SANDBOX = "UCB_SANDBOX",
	UCB_PROD = "UCB_PROD"
};

export enum Bank {
	CSOB = "CSOB",
	CSAS = "CSAS",
	KB = "KB",
	AirBank = "AirBank",
	UCB = "UCB"
};

export type RankBankAccountsRequest = {
	bankAccountIDs: number[];
};

export type AuthenticationUrl = {
	url: string;
	externalID?: string | null;
};

export type EntityListOfBankEnvironmentDto = {
	offset: number;
	limit: number;
	totalCount: number;
	items: BankEnvironmentDto[];
};

export type BankEnvironmentDto = {
	bankEnvironment: BankEnvironment;
	name: string;
	bank: Bank;
	isEnabled: boolean;
	rank: number;
	isVisible: boolean;
};

export type ProblemDetails = {
	type?: string | null;
	title?: string | null;
	status?: number | null;
	detail?: string | null;
	instance?: string | null;
	extensions: unknown;
};

export type PostAuthSignInFetchResponse = 
| FetchResponse<SignInResult, 200> 
| ErrorResponse;

export const postAuthSignInPath = () => `/api/Auth/sign-in`;

export const postAuthSignIn = (requestContract: SignInCommand, headers = new Headers()):
  Promise<PostAuthSignInFetchResponse> => {
    const requestData = getApiRequestData<SignInCommand>(requestContract, false);

    return apiPost(`${getApiUrl()}${postAuthSignInPath()}`, requestData, headers) as Promise<PostAuthSignInFetchResponse>;
}

export type PostAuthSignUpFetchResponse = 
| FetchResponse<SignUpCommandResult, 200> 
| ErrorResponse;

export const postAuthSignUpPath = () => `/api/Auth/sign-up`;

export const postAuthSignUp = (requestContract: SignUpCommand, headers = new Headers()):
  Promise<PostAuthSignUpFetchResponse> => {
    const requestData = getApiRequestData<SignUpCommand>(requestContract, false);

    return apiPost(`${getApiUrl()}${postAuthSignUpPath()}`, requestData, headers) as Promise<PostAuthSignUpFetchResponse>;
}

export type PostAuthSignUpPasswordlessFetchResponse = 
| FetchResponse<PasswordlessSignUpCommandResult, 200> 
| ErrorResponse;

export const postAuthSignUpPasswordlessPath = () => `/api/Auth/sign-up/passwordless`;

export const postAuthSignUpPasswordless = (requestContract: PasswordlessSignUpCommand, headers = new Headers()):
  Promise<PostAuthSignUpPasswordlessFetchResponse> => {
    const requestData = getApiRequestData<PasswordlessSignUpCommand>(requestContract, false);

    return apiPost(`${getApiUrl()}${postAuthSignUpPasswordlessPath()}`, requestData, headers) as Promise<PostAuthSignUpPasswordlessFetchResponse>;
}

export type PostAuthVerifyEmailFetchResponse = 
| FetchResponse<EmailVerificationCommandResult, 200> 
| ErrorResponse;

export const postAuthVerifyEmailPath = () => `/api/Auth/verify-email`;

export const postAuthVerifyEmail = (requestContract: EmailVerificationCommand, headers = new Headers()):
  Promise<PostAuthVerifyEmailFetchResponse> => {
    const requestData = getApiRequestData<EmailVerificationCommand>(requestContract, false);

    return apiPost(`${getApiUrl()}${postAuthVerifyEmailPath()}`, requestData, headers) as Promise<PostAuthVerifyEmailFetchResponse>;
}

export type PostAuthResendVerificationEmailFetchResponse = 
| FetchResponse<ResendVerificationEmailCommandResult, 200> 
| ErrorResponse;

export const postAuthResendVerificationEmailPath = () => `/api/Auth/resend-verification-email`;

export const postAuthResendVerificationEmail = (requestContract: ResendVerificationEmailCommand, headers = new Headers()):
  Promise<PostAuthResendVerificationEmailFetchResponse> => {
    const requestData = getApiRequestData<ResendVerificationEmailCommand>(requestContract, false);

    return apiPost(`${getApiUrl()}${postAuthResendVerificationEmailPath()}`, requestData, headers) as Promise<PostAuthResendVerificationEmailFetchResponse>;
}

export type PostAuthSsoFetchResponse = 
| FetchResponse<SignInResult, 200> 
| ErrorResponse;

export const postAuthSsoPath = () => `/api/Auth/sso`;

export const postAuthSso = (requestContract: SsoSignInRequest, headers = new Headers()):
  Promise<PostAuthSsoFetchResponse> => {
    const requestData = getApiRequestData<SsoSignInRequest>(requestContract, false);

    return apiPost(`${getApiUrl()}${postAuthSsoPath()}`, requestData, headers) as Promise<PostAuthSsoFetchResponse>;
}

export type GetAuthSsoGoogleCallbackFetchResponse = 
| FetchResponse<void, 200> 
| ErrorResponse;

export const getAuthSsoGoogleCallbackPath = () => `/api/Auth/sso/google-callback`;

export const getAuthSsoGoogleCallback = (code?: string, scope?: string | undefined | null, authuser?: string | undefined | null, hd?: string | undefined | null, prompt?: string | undefined | null, headers = new Headers()):
  Promise<GetAuthSsoGoogleCallbackFetchResponse> => {
    const queryParams = {
      "code": code,
      "scope": scope,
      "authuser": authuser,
      "hd": hd,
      "prompt": prompt
    }
    return apiGet(`${getApiUrl()}${getAuthSsoGoogleCallbackPath()}`, headers, queryParams) as Promise<GetAuthSsoGoogleCallbackFetchResponse>;
}

export type GetAuthUserInfoFetchResponse = 
| FetchResponse<UserInfoQueryResult, 200> 
| ErrorResponse;

export const getAuthUserInfoPath = () => `/api/Auth/user-info`;

export const getAuthUserInfo = (headers = new Headers()):
  Promise<GetAuthUserInfoFetchResponse> => {
    return apiGet(`${getApiUrl()}${getAuthUserInfoPath()}`, headers, {}) as Promise<GetAuthUserInfoFetchResponse>;
}

export type PostAuthSendPhoneVerificationTokenFetchResponse = 
| FetchResponse<SendPhoneVerificationTokenCommandResult, 200> 
| ErrorResponse;

export const postAuthSendPhoneVerificationTokenPath = () => `/api/Auth/send-phone-verification-token`;

export const postAuthSendPhoneVerificationToken = (requestContract: SendPhoneVerificationTokenCommand, headers = new Headers()):
  Promise<PostAuthSendPhoneVerificationTokenFetchResponse> => {
    const requestData = getApiRequestData<SendPhoneVerificationTokenCommand>(requestContract, false);

    return apiPost(`${getApiUrl()}${postAuthSendPhoneVerificationTokenPath()}`, requestData, headers) as Promise<PostAuthSendPhoneVerificationTokenFetchResponse>;
}

export type PostAuthVerifyPhoneFetchResponse = 
| FetchResponse<VerifyPhoneCommandResult, 200> 
| ErrorResponse;

export const postAuthVerifyPhonePath = () => `/api/Auth/verify-phone`;

export const postAuthVerifyPhone = (requestContract: VerifyPhoneCommand, headers = new Headers()):
  Promise<PostAuthVerifyPhoneFetchResponse> => {
    const requestData = getApiRequestData<VerifyPhoneCommand>(requestContract, false);

    return apiPost(`${getApiUrl()}${postAuthVerifyPhonePath()}`, requestData, headers) as Promise<PostAuthVerifyPhoneFetchResponse>;
}

export type PostAuthResetPasswordFetchResponse = 
| FetchResponse<ResetPasswordCommandResult, 200> 
| ErrorResponse;

export const postAuthResetPasswordPath = () => `/api/Auth/reset-password`;

export const postAuthResetPassword = (requestContract: ResetPasswordCommand, headers = new Headers()):
  Promise<PostAuthResetPasswordFetchResponse> => {
    const requestData = getApiRequestData<ResetPasswordCommand>(requestContract, false);

    return apiPost(`${getApiUrl()}${postAuthResetPasswordPath()}`, requestData, headers) as Promise<PostAuthResetPasswordFetchResponse>;
}

export type PostAuthSetPasswordFetchResponse = 
| FetchResponse<SetPasswordCommandResult, 200> 
| ErrorResponse;

export const postAuthSetPasswordPath = () => `/api/Auth/set-password`;

export const postAuthSetPassword = (requestContract: SetPasswordCommand, headers = new Headers()):
  Promise<PostAuthSetPasswordFetchResponse> => {
    const requestData = getApiRequestData<SetPasswordCommand>(requestContract, false);

    return apiPost(`${getApiUrl()}${postAuthSetPasswordPath()}`, requestData, headers) as Promise<PostAuthSetPasswordFetchResponse>;
}

export type GetAuthCurrentTimeFetchResponse = 
| FetchResponse<void, 200> 
| ErrorResponse;

export const getAuthCurrentTimePath = () => `/api/Auth/current-time`;

export const getAuthCurrentTime = (headers = new Headers()):
  Promise<GetAuthCurrentTimeFetchResponse> => {
    return apiGet(`${getApiUrl()}${getAuthCurrentTimePath()}`, headers, {}) as Promise<GetAuthCurrentTimeFetchResponse>;
}

export type GetBalanceFetchResponse = 
| FetchResponse<GetBalanceQueryResult, 200> 
| ErrorResponse;

export const getBalancePath = () => `/api/Balance`;

export const getBalance = (headers = new Headers()):
  Promise<GetBalanceFetchResponse> => {
    return apiGet(`${getApiUrl()}${getBalancePath()}`, headers, {}) as Promise<GetBalanceFetchResponse>;
}

export type PostBalanceWithdrawFetchResponse = 
| FetchResponse<AddWithdrawalToQueueCommandResult, 200> 
| ErrorResponse;

export const postBalanceWithdrawPath = () => `/api/Balance/withdraw`;

export const postBalanceWithdraw = (requestContract: WithdrawalRequest, headers = new Headers()):
  Promise<PostBalanceWithdrawFetchResponse> => {
    const requestData = getApiRequestData<WithdrawalRequest>(requestContract, false);

    return apiPost(`${getApiUrl()}${postBalanceWithdrawPath()}`, requestData, headers) as Promise<PostBalanceWithdrawFetchResponse>;
}

export type PostBalanceSellFetchResponse = 
| FetchResponse<SellCommandResult, 200> 
| ErrorResponse;

export const postBalanceSellPath = () => `/api/Balance/sell`;

export const postBalanceSell = (requestContract: SellRequest, headers = new Headers()):
  Promise<PostBalanceSellFetchResponse> => {
    const requestData = getApiRequestData<SellRequest>(requestContract, false);

    return apiPost(`${getApiUrl()}${postBalanceSellPath()}`, requestData, headers) as Promise<PostBalanceSellFetchResponse>;
}

export type GetCacheRefreshFetchResponse = 
| FetchResponse<void, 200> 
| ErrorResponse;

export const getCacheRefreshPath = () => `/api/Cache/refresh`;

export const getCacheRefresh = (headers = new Headers()):
  Promise<GetCacheRefreshFetchResponse> => {
    return apiGet(`${getApiUrl()}${getCacheRefreshPath()}`, headers, {}) as Promise<GetCacheRefreshFetchResponse>;
}

export type GetCodeListFetchResponse = 
| FetchResponse<GetCodeListCollectionQueryResult, 200> 
| ErrorResponse;

export const getCodeListPath = () => `/api/CodeList`;

export const getCodeList = (headers = new Headers()):
  Promise<GetCodeListFetchResponse> => {
    return apiGet(`${getApiUrl()}${getCodeListPath()}`, headers, {}) as Promise<GetCodeListFetchResponse>;
}

export type PostCodeListCodeListsFetchResponse = 
| FetchResponse<GetCodeListCollectionQueryResult, 200> 
| ErrorResponse;

export const postCodeListCodeListsPath = () => `/api/CodeList/code-lists`;

export const postCodeListCodeLists = (headers = new Headers()):
  Promise<PostCodeListCodeListsFetchResponse> => {
    const requestData = getApiRequestData<object>(undefined, false);

    return apiPost(`${getApiUrl()}${postCodeListCodeListsPath()}`, requestData, headers) as Promise<PostCodeListCodeListsFetchResponse>;
}

export type GetCoinbackOffersFetchResponse = 
| FetchResponse<EntityListOfOfferListItemDto, 200> 
| ErrorResponse;

export const getCoinbackOffersPath = () => `/api/Coinback/offers`;

export const getCoinbackOffers = (offset?: number, limit?: number, categoryCode?: string | undefined | null, headers = new Headers()):
  Promise<GetCoinbackOffersFetchResponse> => {
    const queryParams = {
      "offset": offset,
      "limit": limit,
      "categoryCode": categoryCode
    }
    return apiGet(`${getApiUrl()}${getCoinbackOffersPath()}`, headers, queryParams) as Promise<GetCoinbackOffersFetchResponse>;
}

export type GetCoinbackCategoriesFetchResponse = 
| FetchResponse<EntityListOfCategoryDto, 200> 
| ErrorResponse;

export const getCoinbackCategoriesPath = () => `/api/Coinback/categories`;

export const getCoinbackCategories = (headers = new Headers()):
  Promise<GetCoinbackCategoriesFetchResponse> => {
    return apiGet(`${getApiUrl()}${getCoinbackCategoriesPath()}`, headers, {}) as Promise<GetCoinbackCategoriesFetchResponse>;
}

export type GetCoinbackOffersForExtensionFetchResponse = 
| FetchResponse<EntityListOfOfferBrowserExtensionDto, 200> 
| ErrorResponse;

export const getCoinbackOffersForExtensionPath = () => `/api/Coinback/offers-for-extension`;

export const getCoinbackOffersForExtension = (headers = new Headers()):
  Promise<GetCoinbackOffersForExtensionFetchResponse> => {
    return apiGet(`${getApiUrl()}${getCoinbackOffersForExtensionPath()}`, headers, {}) as Promise<GetCoinbackOffersForExtensionFetchResponse>;
}

export type GetCoinbackOfferFetchResponse = 
| FetchResponse<OfferDto, 200> 
| ErrorResponse;

export const getCoinbackOfferPath = () => `/api/Coinback/offer`;

export const getCoinbackOffer = (webUrl?: string | undefined | null, headers = new Headers()):
  Promise<GetCoinbackOfferFetchResponse> => {
    const queryParams = {
      "webUrl": webUrl
    }
    return apiGet(`${getApiUrl()}${getCoinbackOfferPath()}`, headers, queryParams) as Promise<GetCoinbackOfferFetchResponse>;
}

export type GetHashSha256FetchResponse = 
| FetchResponse<string, 200> 
| ErrorResponse;

export const getHashSha256Path = () => `/api/Hash/sha-256`;

export const getHashSha256 = (value?: string | undefined | null, headers = new Headers()):
  Promise<GetHashSha256FetchResponse> => {
    const queryParams = {
      "value": value
    }
    return apiGet(`${getApiUrl()}${getHashSha256Path()}`, headers, queryParams) as Promise<GetHashSha256FetchResponse>;
}

export type PostInvoiceInvoiceListFetchResponse = 
| FetchResponse<EntityListOfInvoiceListItemDto, 200> 
| ErrorResponse;

export const postInvoiceInvoiceListPath = () => `/api/Invoice/invoice-list`;

export const postInvoiceInvoiceList = (headers = new Headers()):
  Promise<PostInvoiceInvoiceListFetchResponse> => {
    const requestData = getApiRequestData<object>(undefined, false);

    return apiPost(`${getApiUrl()}${postInvoiceInvoiceListPath()}`, requestData, headers) as Promise<PostInvoiceInvoiceListFetchResponse>;
}

export type PostInvoiceCreateInvoiceFetchResponse = 
| FetchResponse<CreateInvoiceCommandResult, 200> 
| ErrorResponse;

export const postInvoiceCreateInvoicePath = () => `/api/Invoice/create-invoice`;

export const postInvoiceCreateInvoice = (requestContract: CreateInvoiceRequest, headers = new Headers()):
  Promise<PostInvoiceCreateInvoiceFetchResponse> => {
    const requestData = getApiRequestData<CreateInvoiceRequest>(requestContract, false);

    return apiPost(`${getApiUrl()}${postInvoiceCreateInvoicePath()}`, requestData, headers) as Promise<PostInvoiceCreateInvoiceFetchResponse>;
}

export type PostInvoiceGetInvoiceRateChangeFetchResponse = 
| FetchResponse<GetInvoiceRateChangeDetailQueryResult, 200> 
| ErrorResponse;

export const postInvoiceGetInvoiceRateChangePath = () => `/api/Invoice/get-invoice-rate-change`;

export const postInvoiceGetInvoiceRateChange = (requestContract: GetInvoiceRateChangeDetailQuery, headers = new Headers()):
  Promise<PostInvoiceGetInvoiceRateChangeFetchResponse> => {
    const requestData = getApiRequestData<GetInvoiceRateChangeDetailQuery>(requestContract, false);

    return apiPost(`${getApiUrl()}${postInvoiceGetInvoiceRateChangePath()}`, requestData, headers) as Promise<PostInvoiceGetInvoiceRateChangeFetchResponse>;
}

export type PostInvoiceProcessInvoiceRateChangeFetchResponse = 
| FetchResponse<ProcessInvoiceRateChangeCommandResult, 200> 
| ErrorResponse;

export const postInvoiceProcessInvoiceRateChangePath = () => `/api/Invoice/process-invoice-rate-change`;

export const postInvoiceProcessInvoiceRateChange = (requestContract: ProcessInvoiceRateChangeRequest, headers = new Headers()):
  Promise<PostInvoiceProcessInvoiceRateChangeFetchResponse> => {
    const requestData = getApiRequestData<ProcessInvoiceRateChangeRequest>(requestContract, false);

    return apiPost(`${getApiUrl()}${postInvoiceProcessInvoiceRateChangePath()}`, requestData, headers) as Promise<PostInvoiceProcessInvoiceRateChangeFetchResponse>;
}

export type PostInvoiceDownloadEmployeeGuideFetchResponse = 
| FetchResponse<void, 200> 
| ErrorResponse;

export const postInvoiceDownloadEmployeeGuidePath = () => `/api/Invoice/download-employee-guide`;

export const postInvoiceDownloadEmployeeGuide = (headers = new Headers()):
  Promise<PostInvoiceDownloadEmployeeGuideFetchResponse> => {
    const requestData = getApiRequestData<object>(undefined, false);

    return apiPost(`${getApiUrl()}${postInvoiceDownloadEmployeeGuidePath()}`, requestData, headers) as Promise<PostInvoiceDownloadEmployeeGuideFetchResponse>;
}

export type PostInvoiceGetInvoiceSettingsFetchResponse = 
| FetchResponse<InvoiceSettingsDto, 200> 
| ErrorResponse;

export const postInvoiceGetInvoiceSettingsPath = () => `/api/Invoice/get-invoice-settings`;

export const postInvoiceGetInvoiceSettings = (headers = new Headers()):
  Promise<PostInvoiceGetInvoiceSettingsFetchResponse> => {
    const requestData = getApiRequestData<object>(undefined, false);

    return apiPost(`${getApiUrl()}${postInvoiceGetInvoiceSettingsPath()}`, requestData, headers) as Promise<PostInvoiceGetInvoiceSettingsFetchResponse>;
}

export type PostInvoiceSaveInvoiceSettingsFetchResponse = 
| FetchResponse<SaveInvoiceSettingsCommandResult, 200> 
| ErrorResponse;

export const postInvoiceSaveInvoiceSettingsPath = () => `/api/Invoice/save-invoice-settings`;

export const postInvoiceSaveInvoiceSettings = (requestContract: InvoiceSettingsDto, headers = new Headers()):
  Promise<PostInvoiceSaveInvoiceSettingsFetchResponse> => {
    const requestData = getApiRequestData<InvoiceSettingsDto>(requestContract, false);

    return apiPost(`${getApiUrl()}${postInvoiceSaveInvoiceSettingsPath()}`, requestData, headers) as Promise<PostInvoiceSaveInvoiceSettingsFetchResponse>;
}

export type PostInvoiceGetUserFeeRateFetchResponse = 
| FetchResponse<GetUserFeeRateQueryResult, 200> 
| ErrorResponse;

export const postInvoiceGetUserFeeRatePath = () => `/api/Invoice/get-user-fee-rate`;

export const postInvoiceGetUserFeeRate = (headers = new Headers()):
  Promise<PostInvoiceGetUserFeeRateFetchResponse> => {
    const requestData = getApiRequestData<object>(undefined, false);

    return apiPost(`${getApiUrl()}${postInvoiceGetUserFeeRatePath()}`, requestData, headers) as Promise<PostInvoiceGetUserFeeRateFetchResponse>;
}

export type PostLeadListFetchResponse = 
| FetchResponse<EntityListOfLeadDto, 200> 
| ErrorResponse;

export const postLeadListPath = () => `/api/Lead/list`;

export const postLeadList = (headers = new Headers()):
  Promise<PostLeadListFetchResponse> => {
    const requestData = getApiRequestData<object>(undefined, false);

    return apiPost(`${getApiUrl()}${postLeadListPath()}`, requestData, headers) as Promise<PostLeadListFetchResponse>;
}

export type PostLeadInviteFetchResponse = 
| FetchResponse<LeadInvitationCommandResult, 200> 
| ErrorResponse;

export const postLeadInvitePath = () => `/api/Lead/invite`;

export const postLeadInvite = (requestContract: InviteLeadRequest, headers = new Headers()):
  Promise<PostLeadInviteFetchResponse> => {
    const requestData = getApiRequestData<InviteLeadRequest>(requestContract, false);

    return apiPost(`${getApiUrl()}${postLeadInvitePath()}`, requestData, headers) as Promise<PostLeadInviteFetchResponse>;
}

export type PostLogErrorFetchResponse = 
| FetchResponse<void, 200> 
| ErrorResponse;

export const postLogErrorPath = () => `/api/Log/Error`;

export const postLogError = (requestContract: ErrorWebLogCommand, headers = new Headers()):
  Promise<PostLogErrorFetchResponse> => {
    const requestData = getApiRequestData<ErrorWebLogCommand>(requestContract, false);

    return apiPost(`${getApiUrl()}${postLogErrorPath()}`, requestData, headers) as Promise<PostLogErrorFetchResponse>;
}

export type PostNotificationGetNotificationSettingsFetchResponse = 
| FetchResponse<UserNotificationSettingsDto, 200> 
| ErrorResponse;

export const postNotificationGetNotificationSettingsPath = () => `/api/Notification/get-notification-settings`;

export const postNotificationGetNotificationSettings = (headers = new Headers()):
  Promise<PostNotificationGetNotificationSettingsFetchResponse> => {
    const requestData = getApiRequestData<object>(undefined, false);

    return apiPost(`${getApiUrl()}${postNotificationGetNotificationSettingsPath()}`, requestData, headers) as Promise<PostNotificationGetNotificationSettingsFetchResponse>;
}

export type PostNotificationSaveNotificationSettingsFetchResponse = 
| FetchResponse<SaveUserNotificationSettingsCommandResult, 200> 
| ErrorResponse;

export const postNotificationSaveNotificationSettingsPath = () => `/api/Notification/save-notification-settings`;

export const postNotificationSaveNotificationSettings = (requestContract: UserNotificationSettingsDto, headers = new Headers()):
  Promise<PostNotificationSaveNotificationSettingsFetchResponse> => {
    const requestData = getApiRequestData<UserNotificationSettingsDto>(requestContract, false);

    return apiPost(`${getApiUrl()}${postNotificationSaveNotificationSettingsPath()}`, requestData, headers) as Promise<PostNotificationSaveNotificationSettingsFetchResponse>;
}

export type PostRateGetRateFetchResponse = 
| FetchResponse<GetRateQueryResult, 200> 
| ErrorResponse;

export const postRateGetRatePath = () => `/api/Rate/get-rate`;

export const postRateGetRate = (requestContract: GetRateQuery, headers = new Headers()):
  Promise<PostRateGetRateFetchResponse> => {
    const requestData = getApiRequestData<GetRateQuery>(requestContract, false);

    return apiPost(`${getApiUrl()}${postRateGetRatePath()}`, requestData, headers) as Promise<PostRateGetRateFetchResponse>;
}

export type GetRateHistoryFetchResponse = 
| FetchResponse<GetRateHistoryQueryResult, 200> 
| ErrorResponse;

export const getRateHistoryPath = () => `/api/Rate/history`;

export const getRateHistory = (askCurrencyCode?: string | undefined | null, bidCurrencyCode?: string | undefined | null, startDate?: string, headers = new Headers()):
  Promise<GetRateHistoryFetchResponse> => {
    const queryParams = {
      "askCurrencyCode": askCurrencyCode,
      "bidCurrencyCode": bidCurrencyCode,
      "startDate": startDate
    }
    return apiGet(`${getApiUrl()}${getRateHistoryPath()}`, headers, queryParams) as Promise<GetRateHistoryFetchResponse>;
}

export type PostSystemGetReportFetchResponse = 
| FetchResponse<GetSystemReportQueryResult, 200> 
| ErrorResponse;

export const postSystemGetReportPath = () => `/api/System/get-report`;

export const postSystemGetReport = (headers = new Headers()):
  Promise<PostSystemGetReportFetchResponse> => {
    const requestData = getApiRequestData<object>(undefined, false);

    return apiPost(`${getApiUrl()}${postSystemGetReportPath()}`, requestData, headers) as Promise<PostSystemGetReportFetchResponse>;
}

export type PostSystemWithdrawFetchResponse = 
| FetchResponse<ProcessSystemWithdrawalResult, 200> 
| ErrorResponse;

export const postSystemWithdrawPath = () => `/api/System/withdraw`;

export const postSystemWithdraw = (requestContract: SystemWithdrawalRequest, headers = new Headers()):
  Promise<PostSystemWithdrawFetchResponse> => {
    const requestData = getApiRequestData<SystemWithdrawalRequest>(requestContract, false);

    return apiPost(`${getApiUrl()}${postSystemWithdrawPath()}`, requestData, headers) as Promise<PostSystemWithdrawFetchResponse>;
}

export type PostSystemGenerateAddressFetchResponse = 
| FetchResponse<GenerateSystemAddressCommandResult, 200> 
| ErrorResponse;

export const postSystemGenerateAddressPath = () => `/api/System/generate-address`;

export const postSystemGenerateAddress = (headers = new Headers()):
  Promise<PostSystemGenerateAddressFetchResponse> => {
    const requestData = getApiRequestData<object>(undefined, false);

    return apiPost(`${getApiUrl()}${postSystemGenerateAddressPath()}`, requestData, headers) as Promise<PostSystemGenerateAddressFetchResponse>;
}

export type PostSystemGetSystemSettingFetchResponse = 
| FetchResponse<SystemSettingDto, 200> 
| ErrorResponse;

export const postSystemGetSystemSettingPath = () => `/api/System/get-system-setting`;

export const postSystemGetSystemSetting = (requestContract: GetSystemSettingQuery, headers = new Headers()):
  Promise<PostSystemGetSystemSettingFetchResponse> => {
    const requestData = getApiRequestData<GetSystemSettingQuery>(requestContract, false);

    return apiPost(`${getApiUrl()}${postSystemGetSystemSettingPath()}`, requestData, headers) as Promise<PostSystemGetSystemSettingFetchResponse>;
}

export type PostSystemSaveSystemSettingFetchResponse = 
| FetchResponse<SaveSystemSettingCommandResult, 200> 
| ErrorResponse;

export const postSystemSaveSystemSettingPath = () => `/api/System/save-system-setting`;

export const postSystemSaveSystemSetting = (requestContract: SaveSystemSettingCommand, headers = new Headers()):
  Promise<PostSystemSaveSystemSettingFetchResponse> => {
    const requestData = getApiRequestData<SaveSystemSettingCommand>(requestContract, false);

    return apiPost(`${getApiUrl()}${postSystemSaveSystemSettingPath()}`, requestData, headers) as Promise<PostSystemSaveSystemSettingFetchResponse>;
}

export type PostTransactionTransactionListFetchResponse = 
| FetchResponse<EntityListOfTransactionListItemDto, 200> 
| ErrorResponse;

export const postTransactionTransactionListPath = () => `/api/Transaction/transaction-list`;

export const postTransactionTransactionList = (requestContract: GetTransactionListRequest, headers = new Headers()):
  Promise<PostTransactionTransactionListFetchResponse> => {
    const requestData = getApiRequestData<GetTransactionListRequest>(requestContract, false);

    return apiPost(`${getApiUrl()}${postTransactionTransactionListPath()}`, requestData, headers) as Promise<PostTransactionTransactionListFetchResponse>;
}

export type GetTransactionDetailExternalIDFetchResponse = 
| FetchResponse<GetTransactionDetailQueryResult, 200> 
| ErrorResponse;

export const getTransactionDetailExternalIDPath = (externalID: string) => `/api/Transaction/detail/${externalID}`;

export const getTransactionDetailExternalID = (externalID: string, headers = new Headers()):
  Promise<GetTransactionDetailExternalIDFetchResponse> => {
    return apiGet(`${getApiUrl()}${getTransactionDetailExternalIDPath(externalID)}`, headers, {}) as Promise<GetTransactionDetailExternalIDFetchResponse>;
}

export type GetTransactionCsvExportFetchResponse = 
| FetchResponse<void, 200> 
| ErrorResponse;

export const getTransactionCsvExportPath = () => `/api/Transaction/csv-export`;

export const getTransactionCsvExport = (headers = new Headers()):
  Promise<GetTransactionCsvExportFetchResponse> => {
    return apiGet(`${getApiUrl()}${getTransactionCsvExportPath()}`, headers, {}) as Promise<GetTransactionCsvExportFetchResponse>;
}

export type PostUserBankAccountGetDetailFetchResponse = 
| FetchResponse<UserBankAccountDetailDto, 200> 
| ErrorResponse;

export const postUserBankAccountGetDetailPath = () => `/api/UserBankAccount/get-detail`;

export const postUserBankAccountGetDetail = (requestContract: UserBankAccountDetailRequest, headers = new Headers()):
  Promise<PostUserBankAccountGetDetailFetchResponse> => {
    const requestData = getApiRequestData<UserBankAccountDetailRequest>(requestContract, false);

    return apiPost(`${getApiUrl()}${postUserBankAccountGetDetailPath()}`, requestData, headers) as Promise<PostUserBankAccountGetDetailFetchResponse>;
}

export type PostUserBankAccountBankAddressListFetchResponse = 
| FetchResponse<EntityListOfUserBankAccountListDto, 200> 
| ErrorResponse;

export const postUserBankAccountBankAddressListPath = () => `/api/UserBankAccount/bank-address-list`;

export const postUserBankAccountBankAddressList = (headers = new Headers()):
  Promise<PostUserBankAccountBankAddressListFetchResponse> => {
    const requestData = getApiRequestData<object>(undefined, false);

    return apiPost(`${getApiUrl()}${postUserBankAccountBankAddressListPath()}`, requestData, headers) as Promise<PostUserBankAccountBankAddressListFetchResponse>;
}

export type PostUserBankAccountCreateFetchResponse = 
| FetchResponse<CreateUserBankAccountCommandResult, 200> 
| ErrorResponse;

export const postUserBankAccountCreatePath = () => `/api/UserBankAccount/create`;

export const postUserBankAccountCreate = (headers = new Headers()):
  Promise<PostUserBankAccountCreateFetchResponse> => {
    const requestData = getApiRequestData<object>(undefined, false);

    return apiPost(`${getApiUrl()}${postUserBankAccountCreatePath()}`, requestData, headers) as Promise<PostUserBankAccountCreateFetchResponse>;
}

export type PostUserListFetchResponse = 
| FetchResponse<EntityListOfUserDto, 200> 
| ErrorResponse;

export const postUserListPath = () => `/api/User/list`;

export const postUserList = (requestContract: GetUserListQuery, headers = new Headers()):
  Promise<PostUserListFetchResponse> => {
    const requestData = getApiRequestData<GetUserListQuery>(requestContract, false);

    return apiPost(`${getApiUrl()}${postUserListPath()}`, requestData, headers) as Promise<PostUserListFetchResponse>;
}

export type GetUserAdminDetailUserIDFetchResponse = 
| FetchResponse<UserAdminDetailDto, 200> 
| ErrorResponse;

export const getUserAdminDetailUserIDPath = (userID: number) => `/api/User/admin-detail/${userID}`;

export const getUserAdminDetailUserID = (userID: number, headers = new Headers()):
  Promise<GetUserAdminDetailUserIDFetchResponse> => {
    return apiGet(`${getApiUrl()}${getUserAdminDetailUserIDPath(userID)}`, headers, {}) as Promise<GetUserAdminDetailUserIDFetchResponse>;
}

export type PostUserAdminDetailFetchResponse = 
| FetchResponse<SaveUserAdminDetailCommandResult, 200> 
| ErrorResponse;

export const postUserAdminDetailPath = () => `/api/User/admin-detail`;

export const postUserAdminDetail = (requestContract: UserAdminDetailDto, headers = new Headers()):
  Promise<PostUserAdminDetailFetchResponse> => {
    const requestData = getApiRequestData<UserAdminDetailDto>(requestContract, false);

    return apiPost(`${getApiUrl()}${postUserAdminDetailPath()}`, requestData, headers) as Promise<PostUserAdminDetailFetchResponse>;
}

export type GetUserUserIdentificationsZipUserIDFetchResponse = 
| FetchResponse<void, 200> 
| ErrorResponse;

export const getUserUserIdentificationsZipUserIDPath = (userID: number) => `/api/User/user-identifications-zip/${userID}`;

export const getUserUserIdentificationsZipUserID = (userID: number, headers = new Headers()):
  Promise<GetUserUserIdentificationsZipUserIDFetchResponse> => {
    return apiGet(`${getApiUrl()}${getUserUserIdentificationsZipUserIDPath(userID)}`, headers, {}) as Promise<GetUserUserIdentificationsZipUserIDFetchResponse>;
}

export type PutUserDeactivateFetchResponse = 
| FetchResponse<DeactivateUserCommandResult, 200> 
| ErrorResponse;

export const putUserDeactivatePath = () => `/api/User/deactivate`;

export const putUserDeactivate = (headers = new Headers()):
  Promise<PutUserDeactivateFetchResponse> => {
    const requestData = getApiRequestData<object>(undefined, false);

    return apiPut(`${getApiUrl()}${putUserDeactivatePath()}`, requestData, headers) as Promise<PutUserDeactivateFetchResponse>;
}

export type PostVerificationGetUserVerificationDetailFetchResponse = 
| FetchResponse<UserVerificationDetailDto, 200> 
| ErrorResponse;

export const postVerificationGetUserVerificationDetailPath = () => `/api/Verification/get-user-verification-detail`;

export const postVerificationGetUserVerificationDetail = (headers = new Headers()):
  Promise<PostVerificationGetUserVerificationDetailFetchResponse> => {
    const requestData = getApiRequestData<object>(undefined, false);

    return apiPost(`${getApiUrl()}${postVerificationGetUserVerificationDetailPath()}`, requestData, headers) as Promise<PostVerificationGetUserVerificationDetailFetchResponse>;
}

export type PostVerificationGetUserLimitsFetchResponse = 
| FetchResponse<GetUserLimitsQueryResult, 200> 
| ErrorResponse;

export const postVerificationGetUserLimitsPath = () => `/api/Verification/get-user-limits`;

export const postVerificationGetUserLimits = (headers = new Headers()):
  Promise<PostVerificationGetUserLimitsFetchResponse> => {
    const requestData = getApiRequestData<object>(undefined, false);

    return apiPost(`${getApiUrl()}${postVerificationGetUserLimitsPath()}`, requestData, headers) as Promise<PostVerificationGetUserLimitsFetchResponse>;
}

export type PostVerificationVerifyBankIdFetchResponse = 
| FetchResponse<ProcessBankIDVerificationCommandResult, 200> 
| ErrorResponse;

export const postVerificationVerifyBankIdPath = () => `/api/Verification/verify-bank-id`;

export const postVerificationVerifyBankId = (requestContract: BankIDVerificationRequest, headers = new Headers()):
  Promise<PostVerificationVerifyBankIdFetchResponse> => {
    const requestData = getApiRequestData<BankIDVerificationRequest>(requestContract, false);

    return apiPost(`${getApiUrl()}${postVerificationVerifyBankIdPath()}`, requestData, headers) as Promise<PostVerificationVerifyBankIdFetchResponse>;
}

export type PostVerificationVerifyAdditionalInfoFetchResponse = 
| FetchResponse<SetAdditionalInfoCommandResult, 200> 
| ErrorResponse;

export const postVerificationVerifyAdditionalInfoPath = () => `/api/Verification/verify-additional-info`;

export const postVerificationVerifyAdditionalInfo = (requestContract: SetAdditionalInfoRequest, headers = new Headers()):
  Promise<PostVerificationVerifyAdditionalInfoFetchResponse> => {
    const requestData = getApiRequestData<SetAdditionalInfoRequest>(requestContract, false);

    return apiPost(`${getApiUrl()}${postVerificationVerifyAdditionalInfoPath()}`, requestData, headers) as Promise<PostVerificationVerifyAdditionalInfoFetchResponse>;
}

export type PostVerificationUploadIdentificationFetchResponse = 
| FetchResponse<UploadIdentificationCommandResult, 200> 
| ErrorResponse;

export const postVerificationUploadIdentificationPath = () => `/api/Verification/upload-identification`;

export const postVerificationUploadIdentification = (headers = new Headers()):
  Promise<PostVerificationUploadIdentificationFetchResponse> => {
    const requestData = getApiRequestData<object>(undefined, true);

    return apiPost(`${getApiUrl()}${postVerificationUploadIdentificationPath()}`, requestData, headers) as Promise<PostVerificationUploadIdentificationFetchResponse>;
}

export type PostVerificationSetBankIdentificationInProgressFetchResponse = 
| FetchResponse<SetBankIdentificationInProgressCommandResult, 200> 
| ErrorResponse;

export const postVerificationSetBankIdentificationInProgressPath = () => `/api/Verification/set-bank-identification-in-progress`;

export const postVerificationSetBankIdentificationInProgress = (headers = new Headers()):
  Promise<PostVerificationSetBankIdentificationInProgressFetchResponse> => {
    const requestData = getApiRequestData<object>(undefined, false);

    return apiPost(`${getApiUrl()}${postVerificationSetBankIdentificationInProgressPath()}`, requestData, headers) as Promise<PostVerificationSetBankIdentificationInProgressFetchResponse>;
}

export type PostVoucherValidateFetchResponse = 
| FetchResponse<ValidateVoucherCommandResult, 200> 
| ErrorResponse;

export const postVoucherValidatePath = () => `/api/Voucher/validate`;

export const postVoucherValidate = (requestContract: ValidateVoucherCommand, headers = new Headers()):
  Promise<PostVoucherValidateFetchResponse> => {
    const requestData = getApiRequestData<ValidateVoucherCommand>(requestContract, false);

    return apiPost(`${getApiUrl()}${postVoucherValidatePath()}`, requestData, headers) as Promise<PostVoucherValidateFetchResponse>;
}

export type PostVoucherApplyFetchResponse = 
| FetchResponse<ApplyVoucherCommandResult, 200> 
| ErrorResponse;

export const postVoucherApplyPath = () => `/api/Voucher/apply`;

export const postVoucherApply = (requestContract: ApplyVoucherRequest, headers = new Headers()):
  Promise<PostVoucherApplyFetchResponse> => {
    const requestData = getApiRequestData<ApplyVoucherRequest>(requestContract, false);

    return apiPost(`${getApiUrl()}${postVoucherApplyPath()}`, requestData, headers) as Promise<PostVoucherApplyFetchResponse>;
}

export type GetVerificationsFetchResponse = 
| FetchResponse<UserVerificationDto, 200> 
| ErrorResponse;

export const getVerificationsPath = () => `/api/verifications`;

export const getVerifications = (headers = new Headers()):
  Promise<GetVerificationsFetchResponse> => {
    return apiGet(`${getApiUrl()}${getVerificationsPath()}`, headers, {}) as Promise<GetVerificationsFetchResponse>;
}

export type PostVerificationsFetchResponse = 
| FetchResponse<void, 200> 
| ErrorResponse;

export const postVerificationsPath = () => `/api/verifications`;

export const postVerifications = (headers = new Headers()):
  Promise<PostVerificationsFetchResponse> => {
    const requestData = getApiRequestData<object>(undefined, false);

    return apiPost(`${getApiUrl()}${postVerificationsPath()}`, requestData, headers) as Promise<PostVerificationsFetchResponse>;
}

export type PutVerificationsFetchResponse = 
| FetchResponse<void, 200> 
| ErrorResponse;

export const putVerificationsPath = () => `/api/verifications`;

export const putVerifications = (requestContract: UserVerificationUpdateDto, headers = new Headers()):
  Promise<PutVerificationsFetchResponse> => {
    const requestData = getApiRequestData<UserVerificationUpdateDto>(requestContract, false);

    return apiPut(`${getApiUrl()}${putVerificationsPath()}`, requestData, headers) as Promise<PutVerificationsFetchResponse>;
}

export type PostVerificationsUploadDocumentsFetchResponse = 
| FetchResponse<void, 200> 
| ErrorResponse;

export const postVerificationsUploadDocumentsPath = () => `/api/verifications/upload-documents`;

export const postVerificationsUploadDocuments = (headers = new Headers()):
  Promise<PostVerificationsUploadDocumentsFetchResponse> => {
    const requestData = getApiRequestData<object>(undefined, true);

    return apiPost(`${getApiUrl()}${postVerificationsUploadDocumentsPath()}`, requestData, headers) as Promise<PostVerificationsUploadDocumentsFetchResponse>;
}

export type PostTransactionsInitiateFetchResponse = 
| FetchResponse<InitiateTransactionResponse, 200> 
| ErrorResponse;

export const postTransactionsInitiatePath = () => `/api/transactions/initiate`;

export const postTransactionsInitiate = (requestContract: InitiateTransactionRequest, headers = new Headers()):
  Promise<PostTransactionsInitiateFetchResponse> => {
    const requestData = getApiRequestData<InitiateTransactionRequest>(requestContract, false);

    return apiPost(`${getApiUrl()}${postTransactionsInitiatePath()}`, requestData, headers) as Promise<PostTransactionsInitiateFetchResponse>;
}

export type GetTransactionsStatusFetchResponse = 
| FetchResponse<TransactionStatusResponse, 200> 
| ErrorResponse;

export const getTransactionsStatusPath = () => `/api/transactions/status`;

export const getTransactionsStatus = (publicID?: string | undefined | null, headers = new Headers()):
  Promise<GetTransactionsStatusFetchResponse> => {
    const queryParams = {
      "publicID": publicID
    }
    return apiGet(`${getApiUrl()}${getTransactionsStatusPath()}`, headers, queryParams) as Promise<GetTransactionsStatusFetchResponse>;
}

export type PostTransactionsPayFetchResponse = 
| FetchResponse<PayTransactionResponse, 200> 
| ErrorResponse;

export const postTransactionsPayPath = () => `/api/transactions/pay`;

export const postTransactionsPay = (requestContract: PayTransactionRequest, headers = new Headers()):
  Promise<PostTransactionsPayFetchResponse> => {
    const requestData = getApiRequestData<PayTransactionRequest>(requestContract, false);

    return apiPost(`${getApiUrl()}${postTransactionsPayPath()}`, requestData, headers) as Promise<PostTransactionsPayFetchResponse>;
}

export type GetTransactionsFetchResponse = 
| FetchResponse<EntityListOfTransactionItem, 200> 
| ErrorResponse;

export const getTransactionsPath = () => `/api/transactions`;

export const getTransactions = (offset?: number, limit?: number, headers = new Headers()):
  Promise<GetTransactionsFetchResponse> => {
    const queryParams = {
      "offset": offset,
      "limit": limit
    }
    return apiGet(`${getApiUrl()}${getTransactionsPath()}`, headers, queryParams) as Promise<GetTransactionsFetchResponse>;
}

export type GetTransactionsPublicIDFetchResponse = 
| FetchResponse<TransactionDetailDto2, 200> 
| ErrorResponse;

export const getTransactionsPublicIDPath = (publicID: string) => `/api/transactions/${publicID}`;

export const getTransactionsPublicID = (publicID: string, headers = new Headers()):
  Promise<GetTransactionsPublicIDFetchResponse> => {
    return apiGet(`${getApiUrl()}${getTransactionsPublicIDPath(publicID)}`, headers, {}) as Promise<GetTransactionsPublicIDFetchResponse>;
}

export type GetTransactionsSumFetchResponse = 
| FetchResponse<TransactionSumResponse, 200> 
| ErrorResponse;

export const getTransactionsSumPath = () => `/api/transactions/sum`;

export const getTransactionsSum = (startDate?: string, endDate?: string, allowedStatuses?: TransactionStatus2[] | undefined | null, isCredit?: boolean, headers = new Headers()):
  Promise<GetTransactionsSumFetchResponse> => {
    const queryParams = {
      "startDate": startDate,
      "endDate": endDate,
      "allowedStatuses": allowedStatuses,
      "isCredit": isCredit
    }
    return apiGet(`${getApiUrl()}${getTransactionsSumPath()}`, headers, queryParams) as Promise<GetTransactionsSumFetchResponse>;
}

export type PostTransactionsInvoiceSendFetchResponse = 
| FetchResponse<TransactionSumResponse, 200> 
| ErrorResponse;

export const postTransactionsInvoiceSendPath = () => `/api/transactions/invoice/send`;

export const postTransactionsInvoiceSend = (requestContract: SendInvoiceRequest, headers = new Headers()):
  Promise<PostTransactionsInvoiceSendFetchResponse> => {
    const requestData = getApiRequestData<SendInvoiceRequest>(requestContract, false);

    return apiPost(`${getApiUrl()}${postTransactionsInvoiceSendPath()}`, requestData, headers) as Promise<PostTransactionsInvoiceSendFetchResponse>;
}

export type GetPartyPublicIDFetchResponse = 
| FetchResponse<void, 200> 
| ErrorResponse;

export const getPartyPublicIDPath = (publicID: string) => `/api/Party/${publicID}`;

export const getPartyPublicID = (publicID: string, headers = new Headers()):
  Promise<GetPartyPublicIDFetchResponse> => {
    return apiGet(`${getApiUrl()}${getPartyPublicIDPath(publicID)}`, headers, {}) as Promise<GetPartyPublicIDFetchResponse>;
}

export type PutPartyPublicIDFetchResponse = 
| FetchResponse<void, 200> 
| ErrorResponse;

export const putPartyPublicIDPath = (publicID: string) => `/api/Party/${publicID}`;

export const putPartyPublicID = (requestContract: PartyDto, publicID: string, headers = new Headers()):
  Promise<PutPartyPublicIDFetchResponse> => {
    const requestData = getApiRequestData<PartyDto>(requestContract, false);

    return apiPut(`${getApiUrl()}${putPartyPublicIDPath(publicID)}`, requestData, headers) as Promise<PutPartyPublicIDFetchResponse>;
}

export type GetBankingAccountsFetchResponse = 
| FetchResponse<EntityListOfBankAccountListItem, 200> 
| ErrorResponse;

export const getBankingAccountsPath = () => `/api/banking/accounts`;

export const getBankingAccounts = (headers = new Headers()):
  Promise<GetBankingAccountsFetchResponse> => {
    return apiGet(`${getApiUrl()}${getBankingAccountsPath()}`, headers, {}) as Promise<GetBankingAccountsFetchResponse>;
}

export type PostBankingAccountsSyncFetchResponse = 
| FetchResponse<void, 200> 
| ErrorResponse;

export const postBankingAccountsSyncPath = () => `/api/banking/accounts/sync`;

export const postBankingAccountsSync = (headers = new Headers()):
  Promise<PostBankingAccountsSyncFetchResponse> => {
    const requestData = getApiRequestData<object>(undefined, false);

    return apiPost(`${getApiUrl()}${postBankingAccountsSyncPath()}`, requestData, headers) as Promise<PostBankingAccountsSyncFetchResponse>;
}

export type PutBankingAccountsRankFetchResponse = 
| FetchResponse<void, 200> 
| ErrorResponse;

export const putBankingAccountsRankPath = () => `/api/banking/accounts/rank`;

export const putBankingAccountsRank = (requestContract: RankBankAccountsRequest, headers = new Headers()):
  Promise<PutBankingAccountsRankFetchResponse> => {
    const requestData = getApiRequestData<RankBankAccountsRequest>(requestContract, false);

    return apiPut(`${getApiUrl()}${putBankingAccountsRankPath()}`, requestData, headers) as Promise<PutBankingAccountsRankFetchResponse>;
}

export type DeleteBankingAccountsAccountIDFetchResponse = 
| FetchResponse<void, 200> 
| ErrorResponse;

export const deleteBankingAccountsAccountIDPath = (accountID: number) => `/api/banking/accounts/${accountID}`;

export const deleteBankingAccountsAccountID = (accountID: number, headers = new Headers()):
  Promise<DeleteBankingAccountsAccountIDFetchResponse> => {
    return apiDelete(`${getApiUrl()}${deleteBankingAccountsAccountIDPath(accountID)}`, headers, {}) as Promise<DeleteBankingAccountsAccountIDFetchResponse>;
}

export type GetBankingAuthAuthenticateFetchResponse = 
| FetchResponse<AuthenticationUrl, 200> 
| ErrorResponse;

export const getBankingAuthAuthenticatePath = () => `/api/banking/auth/authenticate`;

export const getBankingAuthAuthenticate = (environment?: BankEnvironment, transactionPublicID?: string | undefined | null, headers = new Headers()):
  Promise<GetBankingAuthAuthenticateFetchResponse> => {
    const queryParams = {
      "environment": environment,
      "transactionPublicID": transactionPublicID
    }
    return apiGet(`${getApiUrl()}${getBankingAuthAuthenticatePath()}`, headers, queryParams) as Promise<GetBankingAuthAuthenticateFetchResponse>;
}

export type GetBankingAuthCallbackFetchResponse = 
| FetchResponse<void, 200> 
| ErrorResponse;

export const getBankingAuthCallbackPath = () => `/api/banking/auth/callback`;

export const getBankingAuthCallback = (code?: string | undefined | null, state?: string | undefined | null, headers = new Headers()):
  Promise<GetBankingAuthCallbackFetchResponse> => {
    const queryParams = {
      "code": code,
      "state": state
    }
    return apiGet(`${getApiUrl()}${getBankingAuthCallbackPath()}`, headers, queryParams) as Promise<GetBankingAuthCallbackFetchResponse>;
}

export type GetBankingBanksEnvironmentsFetchResponse = 
| FetchResponse<EntityListOfBankEnvironmentDto, 200> 
| ErrorResponse;

export const getBankingBanksEnvironmentsPath = () => `/api/banking/banks/environments`;

export const getBankingBanksEnvironments = (headers = new Headers()):
  Promise<GetBankingBanksEnvironmentsFetchResponse> => {
    return apiGet(`${getApiUrl()}${getBankingBanksEnvironmentsPath()}`, headers, {}) as Promise<GetBankingBanksEnvironmentsFetchResponse>;
}

export type GetBankingPaymentsCallbackFetchResponse = 
| FetchResponse<void, 200> 
| FetchResponse<ProblemDetails, 400> 
| FetchResponse<ProblemDetails, 500> 
| ErrorResponse;

export const getBankingPaymentsCallbackPath = () => `/api/banking/payments/callback`;

export const getBankingPaymentsCallback = (instructionIdentification?: string | undefined | null, headers = new Headers()):
  Promise<GetBankingPaymentsCallbackFetchResponse> => {
    const queryParams = {
      "instructionIdentification": instructionIdentification
    }
    return apiGet(`${getApiUrl()}${getBankingPaymentsCallbackPath()}`, headers, queryParams) as Promise<GetBankingPaymentsCallbackFetchResponse>;
}
