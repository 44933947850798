import { Typography } from "@mui/material";
import type { ApplicationLink } from "Components/Layout/Menu/Menu";
import {
  GmailLogoIcon,
  AskQuestionIcon,
  LogoutRoundedIcon,
  MoreIcon,
  MerchantAccountIcon,
  SelectUsersIcon,
  HomeIcon,
  ForwardArrowIcon,
  SortingArrowsIcon,
} from "Components/Shared/Icons";
import { AccessRightCodes } from "Models/AccessRightCodes";
import { useDispatch, useSelector } from "react-redux";
import { resetUser } from "State/Auth/AuthReducer";
import type { RootStateType } from "State/Store";
import { Resources, useResource } from "Translations/Resources";
import { getAppUrl } from "Utils/UrlUtils";

const MenuItemsResources = Resources.Menu.MainMenuItems;

export const useMenuLinks = () => {
  const { t } = useResource();
  const appUrl = getAppUrl();
  const dispatch = useDispatch();

  const accessRightCodes =
    useSelector((e: RootStateType) => e.auth).user?.accessRightCodes || [];

  const topMenu: ApplicationLink[] = [
    {
      text: <Typography>{t(MenuItemsResources.Overview)}</Typography>,
      to: appUrl("dashboard"),
      accessRightCode: AccessRightCodes.Dashboard,
      icon: <HomeIcon />,
    },
    {
      text: <Typography>{t(MenuItemsResources.InitiatePayment)}</Typography>,
      to: appUrl("merchant-payment"),
      accessRightCode: AccessRightCodes.Dashboard,
      icon: <ForwardArrowIcon />,
    },
    {
      text: <Typography>{t(MenuItemsResources.History)}</Typography>,
      to: appUrl("transactions"),
      accessRightCode: AccessRightCodes.Dashboard,
      icon: <SortingArrowsIcon />,
    },
    {
      text: <Typography>{t(MenuItemsResources.Accounts)}</Typography>,
      to: appUrl("bank-address-list"),
      accessRightCode: AccessRightCodes.Dashboard,
      icon: <MerchantAccountIcon />,
    },
    {
      text: <Typography>{t(MenuItemsResources.Settings)}</Typography>,
      to: appUrl("settings"),
      accessRightCode: AccessRightCodes.Dashboard,
      icon: <MoreIcon />,
    },
    {
      text: <Typography>{"Uživatelé"}</Typography>,
      to: appUrl("user-list"),
      accessRightCode: AccessRightCodes.Users,
      icon: <SelectUsersIcon />,
    },
  ].filter(
    ({ accessRightCode }) =>
      !accessRightCode || accessRightCodes.some(e => e === accessRightCode),
  );

  const bottomMenu: ApplicationLink[] = [
    {
      text: <Typography>{"Napište nám"}</Typography>,
      to: appUrl("contact"),
      accessRightCode: AccessRightCodes.Dashboard,
      icon: <GmailLogoIcon />,
    },
    {
      text: <Typography>{"Nápověda"}</Typography>,
      to: "https://www.coinwage.io/casto-kladene-dotazy",
      accessRightCode: AccessRightCodes.Dashboard,
      isExternalLink: true,
      icon: <AskQuestionIcon />,
    },
    {
      text: <Typography>Odhlásit se</Typography>,
      to: appUrl("sign-in"),
      accessRightCode: AccessRightCodes.Dashboard,
      icon: <LogoutRoundedIcon />,
      onClick: () => {
        dispatch(resetUser({}));
      },
    },
  ].filter(
    ({ accessRightCode }) =>
      !accessRightCode || accessRightCodes.some(e => e === accessRightCode),
  );

  return {
    topMenu,
    bottomMenu,
  };
};
