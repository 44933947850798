import * as React from "react";
export const SelectUsersIcon = (props: React.SVGProps<SVGSVGElement>) => {
  props = {
    ...props,
    color: props.color,
  };
  return (
    <svg
      fill="#1A1A1A"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      width="48px"
      height="48px"
      {...props}
    >
      <path
        d="M 12 3 C 8.691 3 6 5.691 6 9 C 6 12.309 8.691 15 12 15 C 15.309 15 18 12.309 18 9 C 18 5.691 15.309 3 12 3 z M 26 3 C 22.691 3 20 5.691 20 9 C 20 12.309 22.691 15 26 15 C 29.309 15 32 12.309 32 9 C 32 5.691 29.309 3 26 3 z M 6.5 17 C 4.57 17 3 18.57 3 20.5 L 3 30 C 3 34.963 7.038 39 12 39 C 14.104 39 16.036313 38.268688 17.570312 37.054688 C 15.968313 35.142688 15 32.684 15 30 L 15 20.5 C 15 19.172 15.474719 17.952 16.261719 17 L 6.5 17 z M 20.5 17 C 18.57 17 17 18.57 17 20.5 L 17 30 C 17 34.963 21.038 39 26 39 C 27.053 39 28.06 38.808609 29 38.474609 L 29 24.292969 C 29 23.138969 29.677609 22.075031 30.724609 21.582031 C 31.799609 21.090031 33.0515 21.260906 33.9375 22.003906 L 35 22.902344 L 35 20.5 C 35 18.57 33.43 17 31.5 17 L 20.5 17 z M 32.134766 23.308594 C 31.947516 23.283469 31.753672 23.311078 31.576172 23.392578 C 31.230172 23.555578 31 23.910922 31 24.294922 L 31 41.296875 C 31 41.719875 31.267109 42.092328 31.662109 42.236328 C 32.055109 42.379328 32.499578 42.266453 32.767578 41.939453 L 35.742188 38.367188 L 38.697266 44.873047 C 39.135266 45.835047 40.265469 46.264031 41.230469 45.832031 L 42.179688 45.40625 C 43.151688 44.97125 43.582578 43.829328 43.142578 42.861328 L 40.158203 36.400391 L 44.998047 36.400391 C 45.421047 36.400391 45.795453 36.034625 45.939453 35.640625 C 46.083453 35.246625 45.967391 34.805109 45.650391 34.537109 L 32.650391 23.535156 C 32.501891 23.410156 32.322016 23.333719 32.134766 23.308594 z"
        fill="currentColor"
      />
    </svg>
  );
};
