import { Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import withStyles from "@mui/styles/withStyles";
import Tooltip, { TooltipProps } from "@mui/material/Tooltip";
import * as React from "react";

type Props = TooltipProps & {
  tooltipColor?: {
    background: string;
    text: string;
  };
};

const StyledTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    padding: theme.spacing(2, 1.5),
    fontSize: 12,
    fontWeight: 400,
  },
}))(Tooltip);

const useStyles = (color?: Props["tooltipColor"]) =>
  makeStyles((theme: Theme) => ({
    tooltip: {
      backgroundColor: color?.background ?? theme.palette.text.primary,
      color: color?.text ?? theme.palette.common.white,
    },
  }));

export const PrimaryTooltip: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = props => {
  const { tooltipColor, ...rest } = props;
  const classes = useStyles(tooltipColor)();
  return <StyledTooltip {...rest} classes={classes}></StyledTooltip>;
};
