import { Divider, Typography } from "@mui/material";
import { FC } from "react";

type Props = {
  text?: string;
  marginY?: number;
};

export const BlDivider: FC<Props> = ({ text, marginY = 4 }) => {
  return (
    <Divider
      sx={{
        ...(marginY !== undefined && {
          marginY: theme => theme.spacing(marginY),
        }),
      }}
      variant={text ? "middle" : "fullWidth"}
    >
      {!!text && <Typography color="gray">{text}</Typography>}
    </Divider>
  );
};
