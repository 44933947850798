import { StyledPageWrapper } from "Components/Shared/StyledComponents";
import { Resources, useResource } from "Translations/Resources";
import { FC, PropsWithChildren, useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { AnonymousLayout } from "Components/Layout/AnonymousLayout";
import { AnonymousCustomerPaymentPayComponent } from "Components/AnonymousCustomer/Payment/AnonymousCustomerPaymentPayComponent";
import { Box, Typography } from "@mui/material";
import { BlTextButton } from "Components/Shared/Buttons/BlTextButton";
import { appUrl } from "Utils/UrlUtils";
import { BankPaymentComponent } from "Components/AnonymousCustomer/Payment/BankPaymentComponent";
import { useSelector } from "react-redux";
import { RootStateType } from "State/Store";

const PageResources = Resources.Customer.Pay;

const BANK_ACCOUNT_SELECT_STEP = "BankAccountSelect";
export const FORCE_BANK_ACCOUNT_SELECT_STEP = "AddBankAccount";

export const AnonymousCustomerPaymentPayPage: FC<
  PropsWithChildren<unknown>
> = _ => {
  const { t } = useResource();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const isPasswordlessSign = useSelector(
    (state: RootStateType) => state.auth.isPasswordlessSign,
  );

  const urlStep = searchParams.get("step");
  const isAccountSelect = urlStep === BANK_ACCOUNT_SELECT_STEP;

  const [isBankSelection, setIsBankSelection] = useState(isAccountSelect);

  useEffect(() => {
    if (!id) {
      navigate(appUrl("sign-in"));
    }
  }, [id, navigate]);

  useEffect(() => {
    if (isPasswordlessSign) {
      setIsBankSelection(true);
    }
  }, [isPasswordlessSign]);

  if (!id) {
    return null;
  }

  const forceBankSelector = urlStep === FORCE_BANK_ACCOUNT_SELECT_STEP;

  const onForceBankAccountSelectCancel = () => {
    navigate({ search: "" });
  };

  const onBankSelector = () => {
    setIsBankSelection(true);
  };

  return (
    <AnonymousLayout
      title={t(PageResources.Title)}
      onChevronClick={
        forceBankSelector ? onForceBankAccountSelectCancel : undefined
      }
    >
      <StyledPageWrapper>
        {isBankSelection ? (
          <BankPaymentComponent
            transactionPublicID={id}
            isAccountSelect={urlStep === BANK_ACCOUNT_SELECT_STEP}
            forceBankSelector={forceBankSelector}
          />
        ) : (
          <>
            <AnonymousCustomerPaymentPayComponent
              transactionPublicID={id}
              onBankSelector={onBankSelector}
            />
            <Box display="flex" marginTop={6} gap={1} justifyContent="center">
              <Typography>Již máte Moone účet?</Typography>
              <BlTextButton onClick={() => navigate(appUrl("sign-in"))}>
                Přihlaste se.
              </BlTextButton>
            </Box>
          </>
        )}
      </StyledPageWrapper>
    </AnonymousLayout>
  );
};

export default AnonymousCustomerPaymentPayPage;
