import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import { authReducer, AuthAction, AuthState } from "State/Auth/AuthReducer";
import { persistStore, persistReducer, PersistConfig } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { nameof } from "Utils/ObjectUtils";
import { logError } from "ErrorService";
import { balanceReducer } from "State/Balance/BalanceReducer";
import {
  getBalanceSaga,
  sellSaga,
  withdrawSaga,
} from "State/Balance/BalanceSaga";
import { transactionReducer } from "State/Transactions/TransactionReducer";
import { getTransactionListSaga } from "State/Transactions/List/GetTransactionListState";
import { downloadTransactionCsvSaga } from "State/Transactions/DownloadCsv/DownloadTransactionCsvState";
import { signInSaga } from "State/Auth/SignIn/SignInState";
import { setPasswordSaga } from "State/Auth/Passwords/SetPasswordsState";
import { signUpSaga } from "State/Auth/SignUp/SignUpState";
import { sendPhoneVerificationCodeSaga } from "State/Auth/Verifications/SendPhoneVerificationTokenState";
import { verifyPhoneSaga } from "State/Auth/Verifications/VerifyPhoneState";
import { signInSsoSaga } from "State/Auth/SignIn/SignInSsoState";
import { verifyEmailSaga } from "State/Auth/Verifications/EmailVerificationState";
import { rateReducer } from "State/Rates/RateReducer";
import { getRateSaga } from "State/Rates/RateSaga";
import { invoiceReducer } from "State/Invoices/InvoiceReducer";
import { createInvoiceSaga } from "State/Invoices/Create/CreateInvoiceState";
import { getInvoiceListSaga } from "State/Invoices/List/GetInvoiceListState";
import { getInvoiceRateChangeSaga } from "State/Invoices/RateChanges/GetInvoiceRateChangeState";
import { downloadEmployeeGuideSaga } from "State/Invoices/DownloadEmployerGuideline/DownloadEmployerGuidelineState";
import { processInvoiceRateChangeSaga } from "State/Invoices/RateChanges/ProcessInvoiceRateChangeState";
import { verificationReducer } from "State/Verification/VerificationReducer";
import { getUserVerificationDetailSaga } from "State/Verification/GetUserVerificationDetail/GetUserVerificationDetailState";
import {
  getInvoiceSettingsSaga,
  saveInvoiceSettingsSaga,
} from "State/Invoices/InvoiceSettings/InvoiceSettingsState";
import { codeListReducer } from "State/CodeLists/CodeListReducer";
import { getCodeListCollectionSaga } from "State/CodeLists/CodeListSaga";
import { getUserLimitsSaga } from "State/Verification/GetUserLimits/GetUserLimitsState";
import { getUserFeeRateSaga } from "State/Invoices/UserFeeRate/GetUserFeeRateState";
import { resetPasswordSaga } from "State/Auth/Passwords/ResetPasswordState";
import { getSystemReportSaga } from "State/System/SystemReport/SystemReportState";
import { systemReducer } from "State/System/SystemReducer";
import { notificationReducer } from "State/Notification/NotificationReducer";
import {
  getUserNotificationSettingsSaga,
  saveUserNotificationSettingsSaga,
} from "State/Notification/Settings/UserNotificationSettingsState";
import { affiliateReducer } from "State/Afl/AffiliateReducer";
import { getLeadListSaga } from "State/Afl/LeadListState/LeadListState";
import { inviteLeadSaga } from "State/Afl/LeadInvitation/LeadInvitation";
import { getTransactionDetailSaga } from "State/Transactions/Detail/TransactionDetailState";
import { processBankIDVerificationSaga } from "State/Verification/BankID/ProcessBankIDVerificationState";
import { setAdditionalInfoSaga } from "State/Verification/AdditionalInfo/SetAdditionalInfoState";
import { uploadUserIdentificationSaga } from "State/Verification/UserIdentifications/UploadUserIdentificationState";
import { uploadReducer } from "State/Upload/UploadReducer";
import { bankAccountReducer } from "State/BankAddresses/BankAddressReducer";
import { getBankAccountListSaga } from "State/BankAddresses/List/BankAddressListState";
import { createBankAddressSaga } from "State/BankAddresses/Create/BankAddressCreateState";
import { getBankAddressDetailSaga } from "State/BankAddresses/Detail/BankAddressDetailState";
import { systemWithdrawSaga } from "State/System/ColdWallet/SystemWithdrawalState";
import { generateSystemAddressSaga } from "State/System/ColdWallet/GenerateSystemAddressState";
import { getSystemSettingSaga } from "State/System/SystemSettings/GetSystemSettingState";
import { setSystemSettingSaga } from "State/System/SystemSettings/SaveSystemSettingState";
import { userReducer } from "State/Users/UserReducer";
import { getUserListSaga } from "State/Users/UserList/UserListState";
import { getUserAdminDetailSaga } from "State/Users/Detail/GetUserAdminDetailState";
import { saveUserAdminDetailSaga } from "State/Users/Detail/SaveUserAdminDetailState";
import { downloadUserIdentificationZipSaga } from "State/Users/Detail/GetUserIdentificationZipState";
import { resetUserSaga } from "State/Auth/ResetUser/ResetUserState";
import { resendVerificationEmailSaga } from "State/Auth/Verifications/ResendVerificationEmailState";
import { setBankAccountVerificationInProgressSaga } from "State/Verification/BankAccount/SetBankAccountVerificationInProgress";
import { voucherReducer } from "State/Voucher/VoucherReducer";
import { applyVoucherSaga } from "State/Voucher/ApplyVoucherState";
import { signUpPasswordlessSaga } from "State/Auth/SignUp/SignUpPasswordlessState";
import { deactivateUserSaga } from "State/Users/Detail/DeactivateUserState";
import { getOfferListSaga } from "State/Coinback/OfferList/OfferListState";
import { coinbackReducer } from "State/Coinback/CoinbackReducer";
import { all } from "redux-saga/effects";
import { getCategoryListSaga } from "State/Coinback/Categories/CategoryListState";
import { getRatesHistorySaga } from "State/Rates/GerRatesHistoryState";
import { errorSaga } from "State/Errors/ErrorsSaga";
import * as Sentry from "@sentry/react";
import { getOfferDetailSaga } from "State/Coinback/OfferDetail/OfferDetailState";
import { getUserInfoSaga } from "State/Auth/UserInfo/GetUserInfoState";

const authPersistConfig: PersistConfig<any> = {
  key: "auth",
  storage: storage,
  whitelist: [
    nameof<AuthState>("user"),
    nameof<AuthState>("unauthenticatedUrl"),
    nameof<AuthState>("verificationEmail"),
  ],
};

const authPersistReducer = persistReducer(
  authPersistConfig,
  authReducer,
) as unknown;

/**
 * Create global state of application
 */
const combinedReducer = combineReducers({
  auth: authPersistReducer as typeof authReducer,
  invoice: invoiceReducer,
  balance: balanceReducer,
  transaction: transactionReducer,
  rate: rateReducer,
  verification: verificationReducer,
  system: systemReducer,
  notification: notificationReducer,
  affiliate: affiliateReducer,
  codeList: persistReducer(
    { key: "codeList", storage: storage },
    codeListReducer,
  ),
  upload: uploadReducer,
  bankAccount: bankAccountReducer,
  user: userReducer,
  voucher: voucherReducer,
  coinback: coinbackReducer,
});

/**
 * Type of global application state
 */
export type RootStateType = ReturnType<typeof combinedReducer>;

/**
 * All changes, that global application state can handle
 */
type RootActionType = AuthAction;

const rootReducer = (state: RootStateType, action: RootActionType) =>
  combinedReducer(state, action);

const persistConfig = {
  key: "root",
  storage,
  whitelist: [],
};

const persistedReducer = persistReducer(persistConfig, rootReducer as any);

const sagaMiddleware = createSagaMiddleware({
  onError: (error, errorInfo) => {
    logError(error, errorInfo);
  },
});

const sentryReduxEnhancer = Sentry.createReduxEnhancer({});

const middlewares = [sagaMiddleware];
const composeEnhancers: any =
  ((window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      trace: true,
      traceLimit: 25,
    })) ||
  compose;

const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(...middlewares), sentryReduxEnhancer),
);

function* rootSaga() {
  yield all([
    signUpSaga(),
    signInSaga(),
    signInSsoSaga(),
    signUpPasswordlessSaga(),
    resetPasswordSaga(),
    setPasswordSaga(),
    sendPhoneVerificationCodeSaga(),
    verifyPhoneSaga(),
    verifyEmailSaga(),
    getBalanceSaga(),
    getTransactionListSaga(),
    downloadTransactionCsvSaga(),
    withdrawSaga(),
    getInvoiceListSaga(),
    getRateSaga(),
    createInvoiceSaga(),
    getInvoiceRateChangeSaga(),
    processInvoiceRateChangeSaga(),
    getUserVerificationDetailSaga(),
    downloadEmployeeGuideSaga(),
    getInvoiceSettingsSaga(),
    saveInvoiceSettingsSaga(),
    getCodeListCollectionSaga(),
    getUserLimitsSaga(),
    getUserFeeRateSaga(),
    getSystemReportSaga(),
    getUserNotificationSettingsSaga(),
    saveUserNotificationSettingsSaga(),
    getLeadListSaga(),
    inviteLeadSaga(),
    getTransactionDetailSaga(),
    processBankIDVerificationSaga(),
    setAdditionalInfoSaga(),
    uploadUserIdentificationSaga(),
    getBankAccountListSaga(),
    createBankAddressSaga(),
    getBankAddressDetailSaga(),
    systemWithdrawSaga(),
    generateSystemAddressSaga(),
    getSystemSettingSaga(),
    setSystemSettingSaga(),
    getUserListSaga(),
    getUserAdminDetailSaga(),
    saveUserAdminDetailSaga(),
    downloadUserIdentificationZipSaga(),
    resetUserSaga(),
    resendVerificationEmailSaga(),
    setBankAccountVerificationInProgressSaga(),
    sellSaga(),
    applyVoucherSaga(),
    deactivateUserSaga(),
    getOfferListSaga(),
    getCategoryListSaga(),
    getRatesHistorySaga(),
    errorSaga(),
    getOfferDetailSaga(),
    getUserInfoSaga(),
  ]);
}

sagaMiddleware.run(rootSaga);

export const buildAppStore = () => {
  (window as any).applicationStore = store;
  let persistor = persistStore(store as any);
  return { store, persistor };
};

export default buildAppStore();
