import { Typography } from "@mui/material";
import {
  PrimaryButton,
  PrimaryButtonProps,
} from "Components/Shared/Buttons/PrimaryButton";
import { ArrowRightIcon } from "Components/Shared/Icons";
import styled from "styled-components";

type Props = PrimaryButtonProps;

const StyledPrimaryButton = styled(PrimaryButton)`
  text-transform: none;
  & > div {
    display: flex;
    align-items: center;

    svg {
      width: 16px;
      height: auto;
      margin-right: ${props => props.theme.spacing(1)};
      color: ${props => props.theme.palette.primary.main};
    }
  }

  ${props => props.theme.breakpoints.up("md")} {
    &:hover {
      & > div {
        svg {
          color: ${props => props.theme.palette.primary.contrastText};
        }
      }
    }
  }
`;

export const ActionButton: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = props => {
  return (
    <StyledPrimaryButton {...props} fullWidth>
      <div>
        <ArrowRightIcon></ArrowRightIcon>
        <Typography variant="body1">{props.children}</Typography>
      </div>
    </StyledPrimaryButton>
  );
};
