import { ActionButton } from "Components/Shared/Buttons/ActionButton";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { downloadEmployeeGuideAsync } from "State/Invoices/DownloadEmployerGuideline/DownloadEmployerGuidelineState";
import { RootStateType } from "State/Store";

export const EmployerGuidelineButton: React.FunctionComponent<
  React.PropsWithChildren<unknown>
> = _ => {
  const dispatch = useDispatch();
  const { isDownloading } = useSelector((e: RootStateType) => e.invoice);
  return (
    <ActionButton
      onClick={() => dispatch(downloadEmployeeGuideAsync.request())}
      isLoading={isDownloading}
      color="info"
    >
      Stáhnout pokyn pro zaměstnavatele
    </ActionButton>
  );
};
