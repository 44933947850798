import {
  Control,
  Controller,
  FieldErrors,
  FieldPath,
  FieldValues,
} from "react-hook-form";
import { CheckboxInput as Checkbox } from "Components/Shared/Inputs/CheckboxInput";
import { CheckboxProps } from "@mui/material";
import { get } from "lodash-es";

type Props<FormType extends FieldValues> = CheckboxProps & {
  name: FieldPath<FormType>;
  label?: string | JSX.Element;
  control: Control<FormType, object>;
  errors: FieldErrors<FormType>;
  formInfo?: { [key: string]: string };
  isLabelFirst?: boolean;
};

export const CheckboxInput = <T extends object>(props: Props<T>) => {
  const { control, errors, formInfo, name, isLabelFirst, ...rest } = props;
  return (
    <>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value } }) => (
          <Checkbox
            onChange={onChange}
            value={value}
            checked={value === true}
            name={name}
            fieldError={get(errors, name as string)}
            isLabelFirst={isLabelFirst}
            {...rest}
          />
        )}
      ></Controller>
    </>
  );
};
