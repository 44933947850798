import {
  AuthenticationUrl,
  BankEnvironment,
  getBankingAuthAuthenticate,
} from "Infrastructure/Api/Api";
import { processResponse } from "Infrastructure/Api/ApiUtils";
import { useMutation } from "@tanstack/react-query";

type Props = {
  onAuthorizeUrl: (props: AuthenticationUrl) => void;
};

type MutationPayloadType = {
  environment: BankEnvironment;
  transactionPublicID?: string;
};

export const useBankingAuthQuery = ({ onAuthorizeUrl }: Props) =>
  useMutation({
    mutationFn: processResponse(
      ({ environment, transactionPublicID }: MutationPayloadType) =>
        getBankingAuthAuthenticate(environment, transactionPublicID),
    ),
    onSuccess: onAuthorizeUrl,
  });
