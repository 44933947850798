import MenuIcon from "@mui/icons-material/Menu";
import { Box, Drawer } from "@mui/material";
import { Avatar } from "Components/Layout/Avatar";
import { MenuItem } from "Components/Layout/Menu/MenuItem";
import { DefaultButton } from "Components/Shared/Buttons/DefaultButton";
import { PageTitleMobile } from "Components/Shared/PageTitles/PageTitleMobile";
import { useMenuLinks } from "Hooks/Layout/useMenuLinks";
import * as React from "react";
import { useLocation } from "react-router";
import styled from "styled-components";

const drawerWidth = 310;

const StyledWrapper = styled.div``;

const StyledMenuIconWrapper = styled(Box)<{ $isVisible: boolean }>`
  position: absolute;
  left: 5px;
  top: 10px;
  z-index: 1;

  ${props => props.theme.breakpoints.down("md")} {
    ${props =>
      !props.$isVisible &&
      `
      display: none;
    `}
  }
`;

const StyledMenu = styled.div`
  position: relative;
  top: 0;
  left: 0;
  width: ${drawerWidth}px;
  height: ${props => props.theme.vh(100)};
  background-color: ${props => props.theme.colors.gray};
  display: grid;
  grid-template-rows: auto 1fr;
  overflow: auto;
  padding-left: ${props => props.theme.spacing(2)};
`;

const StyledList = styled.div`
  margin-top: ${props => props.theme.spacing(1)};
  display: flex;
  flex-direction: column;
`;

const StyledMenuIcon = styled(MenuIcon)`
  color: ${props => props.theme.palette.text.primary};
  width: 24px;
  height: auto;
`;

export type ApplicationLink = {
  text: JSX.Element;
  to: string;
  accessRightCode: string | null;
  isDisabled?: boolean;
  emptySpaceSize?: number;
  isExternalLink?: boolean;
  icon: JSX.Element;
  onClick?: () => void;
};

type Props = {
  isVisible: boolean;
  title: string;
  goBackUrl?: string;
  onChevronClick?: () => void;
};

const LinkItem = (props: ApplicationLink) => {
  const { pathname } = useLocation();

  if (props.emptySpaceSize) {
    return (
      <Box
        key={props.to}
        sx={{ height: `calc(var(--vh) * ${props.emptySpaceSize * 6})` }}
      />
    );
  }
  return <MenuItem key={props.to} item={props} pathname={pathname} />;
};

const Menu: React.FunctionComponent<React.PropsWithChildren<Props>> = props => {
  const { isVisible, title, goBackUrl, onChevronClick } = props;

  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const { topMenu: links, bottomMenu: bottomLinks } = useMenuLinks();

  const menu = (
    <StyledMenu>
      <Avatar />

      <StyledList>
        {links.map((l, i) => (
          <LinkItem {...l} key={i} />
        ))}
      </StyledList>
      <StyledList>
        {bottomLinks.map((l, i) => (
          <LinkItem {...l} key={i} />
        ))}
      </StyledList>
    </StyledMenu>
  );

  const container =
    window !== undefined ? () => window.document.body : undefined;

  return (
    <>
      <StyledWrapper>
        <StyledMenuIconWrapper
          $isVisible={isVisible}
          sx={{
            display: {
              sm: "block",
              md: "none",
            },
          }}
        >
          {!goBackUrl ? (
            <DefaultButton>
              <StyledMenuIcon onClick={handleDrawerToggle} />
              <PageTitleMobile title={title} />
            </DefaultButton>
          ) : (
            <>
              <PageTitleMobile
                title={title}
                goBackUrl={goBackUrl}
                onChevronClick={onChevronClick}
              />
            </>
          )}
        </StyledMenuIconWrapper>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", md: "none" },
            border: "none",
            "& .MuiBackdrop-root": {
              backgroundColor: "transparent",
            },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              border: "none",
              boxShadow: "none",
            },
          }}
        >
          {menu}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", md: "block" },
            height: "100%",
            border: "none",
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              position: "unset",
              border: "none",
              boxShadow: "none",
              backgroundColor: "transparent",
            },
          }}
          open
        >
          {menu}
        </Drawer>
      </StyledWrapper>
    </>
  );
};

export { Menu };
