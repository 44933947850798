import {
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";
import { AuthenticatedLayout } from "Components/Layout/AuthenticatedLayout";
import { Colors } from "Components/Layout/Themes/Colors";
import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";
import { RiskIcon } from "Components/Shared/Icons";
import { PrimaryPaper } from "Components/Shared/PrimaryPaper";
import {
  StyledFlex,
  StyledPageWrapper,
} from "Components/Shared/StyledComponents";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBalanceAsync } from "State/Balance/BalanceReducer";
import { RootStateType } from "State/Store";
import { deactivateUserAsync } from "State/Users/Detail/DeactivateUserState";
import styled from "styled-components";
import { getAppUrl } from "Utils/UrlUtils";

const DeleteButton = styled(PrimaryButton)`
  background-color: ${props => props.theme.colors.deleteRed};
  color: ${props => props.theme.palette.common.white};

  &:hover {
    background-color: ${props => props.theme.colors.deleteRed};
  }
`;

const ModalButton = styled.div`
  cursor: pointer;
`;

const StyledDialog = styled(Dialog)`
  .MuiPaper-root {
    border-radius: 16px;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.25);
    opacity: 1;
    background-color: rgba(255, 255, 255, 1);
    padding: ${props => props.theme.spacing(2)};
  }

  .MuiDialogContent-root {
    padding: 0;
  }
`;

const TextWrapper = styled.div`
  padding: ${props => props.theme.spacing(2)};
`;

export const DeactivateUserPage: React.FunctionComponent<
  React.PropsWithChildren<unknown>
> = _ => {
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(getBalanceAsync.request());
  }, [dispatch]);

  const { isBalanceLoading, balance } = useSelector(
    (e: RootStateType) => e.balance,
  );

  const { isDeactivatingUser } = useSelector((e: RootStateType) => e.user);

  const appUrl = getAppUrl();

  const [isConfirmationModalShown, setIsConfirmationModalShown] =
    React.useState(false);

  const isSubmitDisabled =
    isConfirmationModalShown ||
    isDeactivatingUser ||
    isBalanceLoading ||
    balance === null ||
    balance.availableBalance > 0;

  const isMessageShown = balance !== null && balance.availableBalance > 0;

  const deactivate = () => {
    dispatch(deactivateUserAsync.request());
  };

  return (
    <AuthenticatedLayout title="Smazat účet" goBackUrl={appUrl("settings")}>
      <StyledPageWrapper>
        <div className="left-content">
          <br />
          <PrimaryPaper>
            <Typography fontSize={16}>
              Tímto krokem zrušíte svůj Moone účet.
            </Typography>
            <br />
            <Typography fontSize={16}>
              Vaše osobní údaje budou odstraněny a Váš účet zrušen. Již nebude
              moci dále obchodovat ani se do účtu přihlásit.
            </Typography>

            {isMessageShown && (
              <>
                <br />
                <StyledFlex gap="15px">
                  <RiskIcon color={Colors.DeleteRed} />
                  <Typography color={Colors.DeleteRed}>
                    Účet není možné smazat pokud není prázdný. Před smazáním
                    odešlete své bitcoiny do peněženky nebo je prodejte.
                  </Typography>
                </StyledFlex>
              </>
            )}

            <br />

            <DeleteButton
              disabled={isSubmitDisabled}
              type="submit"
              fullWidth
              variant="contained"
              color="secondary"
              onClick={() => setIsConfirmationModalShown(true)}
            >
              Smazat
            </DeleteButton>

            <StyledDialog
              fullWidth={true}
              open={isConfirmationModalShown}
              onClose={() => setIsConfirmationModalShown(false)}
            >
              <DialogContent>
                <TextWrapper>
                  <Typography sx={{ mt: 2 }}>
                    Opravdu chcete účet smazat? Tento krok je nevratný!
                  </Typography>

                  <br />
                </TextWrapper>
              </DialogContent>
              <DialogActions>
                <StyledFlex
                  alignItems="stretch"
                  justifyContent="flex-end"
                  gap="25px"
                >
                  <ModalButton
                    onClick={() => setIsConfirmationModalShown(false)}
                  >
                    <Typography fontWeight={600}>ZPĚT</Typography>
                  </ModalButton>

                  <ModalButton onClick={() => deactivate()}>
                    <Typography fontWeight={600} color={Colors.DeleteRed}>
                      SMAZAT ÚČET
                    </Typography>
                  </ModalButton>
                </StyledFlex>
              </DialogActions>
            </StyledDialog>
          </PrimaryPaper>
        </div>
      </StyledPageWrapper>
    </AuthenticatedLayout>
  );
};

export default DeactivateUserPage;
