import {
  BottomNavigationAction,
  BottomNavigationActionProps,
  SxProps,
  Theme,
} from "@mui/material";
import { FC } from "react";
import { useTheme } from "styled-components";

export type BottomApplicationLink = {
  isAction?: boolean;
} & Pick<BottomNavigationActionProps, "label" | "action" | "icon" | "disabled">;

type Props = BottomApplicationLink;

const BottomNavigationActionStyles: SxProps<Theme> = {
  fontWeight: 300,
  span: { fontSize: "0.625rem !important" },
  "&.MuiBottomNavigationAction-root": {
    gap: 1,
  },

  svg: { fill: theme => theme.palette.grey[600] },

  "&.Mui-selected": {
    color: theme => theme.palette.primary.main,
    svg: {
      fill: theme => theme.palette.primary.main,
    },
  },
};

export const BottomMenuItem: FC<Props> = ({ isAction, ...props }) => {
  const theme = useTheme();

  return (
    <BottomNavigationAction
      {...props}
      sx={{
        ...BottomNavigationActionStyles,
        ...(isAction && {
          background: theme.palette.gradient.primary,
          borderRadius: "4px",
          color: `${theme.palette.common.white} !important`,
          "&.Mui-disabled": {
            color: `${theme.palette.grey[600]} !important`,
            background: theme.palette.grey[300],
          },
          svg: {
            fill: theme.palette.common.white,
          },
          "&.Mui-selected": {
            svg: {
              fill: theme.palette.common.white,
            },
          },
        }),
      }}
    />
  );
};
