import { createAsyncAction } from "typesafe-actions";
import { put, takeLatest } from "redux-saga/effects";
import { API_ROUTES } from "Api/Api";
import { getType } from "typesafe-actions";
import FileSaver from "file-saver";
import { downloadFile } from "Utils/FileUtils";

export const downloadEmployeeGuideAsync = createAsyncAction(
  "@invoice/DOWNLOAD_EMPLOYEE_GUIDE_REQUEST",
  "@invoice/DOWNLOAD_EMPLOYEE_GUIDE_SUCCESS",
  "@invoice/DOWNLOAD_EMPLOYEE_GUIDE_FAILURE",
)<void, void, Error>();

function* downloadEmployeeGuide(
  action: ReturnType<typeof downloadEmployeeGuideAsync.request>,
): Generator {
  try {
    const route = `${import.meta.env.VITE_API_URL}${
      API_ROUTES.postInvoiceDownloadEmployeeGuide
    }`;
    const response = yield* downloadFile(route, "POST");

    if (response.clone.status === 200) {
      response.clone.blob().then(blob => {
        FileSaver.saveAs(blob, "Pokyn pro zaměstnavatele.pdf");
      });
      yield put(downloadEmployeeGuideAsync.success());
    } else {
      yield put(
        downloadEmployeeGuideAsync.failure(
          new Error(`Error in action ${action.type}`, {
            cause: response.clone.status,
          }),
        ),
      );
    }
  } catch (err) {
    yield put(downloadEmployeeGuideAsync.failure(err as Error));
  }
}

export function* downloadEmployeeGuideSaga() {
  yield takeLatest(
    getType(downloadEmployeeGuideAsync.request),
    downloadEmployeeGuide,
  );
}
