import { ChevronRight } from "@mui/icons-material";
import { ListItem, Typography, Box } from "@mui/material";
import { LeadStatus } from "Api/Api";
import { AuthenticatedLayout } from "Components/Layout/AuthenticatedLayout";
import { Colors } from "Components/Layout/Themes/Colors";
import { UnstyledLink } from "Components/Routes/UnstyledLink";
import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";
import { PrimaryPaper } from "Components/Shared/PrimaryPaper";
import { StyledPageWrapper } from "Components/Shared/StyledComponents";
import { useLeadReward } from "Hooks/SystemSettings/useLeadReward";
import { useMaxInvitationCount } from "Hooks/SystemSettings/useMaxInvitationCount";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLeadListAsync } from "State/Afl/LeadListState/LeadListState";
import { RootStateType } from "State/Store";
import { getUserVerificationDetailAsync } from "State/Verification/GetUserVerificationDetail/GetUserVerificationDetailState";
import styled from "styled-components";
import { canInvite } from "Utils/AffiliateUtils";
import { getAppUrl } from "Utils/UrlUtils";
import { isNotFullyVerified } from "Utils/VerificationUtils";

const Trivia = styled(Typography)`
  margin: ${props => props.theme.spacing(4, 2)};
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const StyledListItem = styled(ListItem)`
  padding-right: 0;
  padding-left: 0;
`;

const translateStatus = (status: LeadStatus) => {
  switch (status) {
    case LeadStatus.Invited:
      return "Pozván";
    case LeadStatus.Registered:
      return "Registrován";
    case LeadStatus.Completed:
    case LeadStatus.CompletedRewardSent:
      return "Zasíláme odměnu";
    case LeadStatus.CompletedRewardApplied:
      return "Odměna vyplacena";
  }
};

export const AffiliatePage: React.FunctionComponent<
  React.PropsWithChildren<unknown>
> = _ => {
  const appUrl = getAppUrl();

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getLeadListAsync.request());

    return () => {
      dispatch(getLeadListAsync.success(null));
    };
  }, [dispatch]);

  React.useEffect(() => {
    dispatch(getUserVerificationDetailAsync.request());
  }, [dispatch]);

  const { leadList } = useSelector((e: RootStateType) => e.affiliate);
  const { detail, isLoading: isVerificationLoading } = useSelector(
    (e: RootStateType) => e.verification,
  );
  const { user } = useSelector((e: RootStateType) => e.auth);

  const isVerificationDisclaimerVisible =
    isNotFullyVerified(detail) && !isVerificationLoading;

  const maxInvitationCount = useMaxInvitationCount();

  const hasFreeInvitations =
    user && canInvite(leadList?.items ?? [], maxInvitationCount);

  const canShowInvite = !isVerificationDisclaimerVisible && hasFreeInvitations;

  const reward = useLeadReward();

  return (
    <AuthenticatedLayout title="Pozvat přítele" goBackUrl={appUrl("settings")}>
      <StyledPageWrapper>
        <div className="left-content">
          <PrimaryPaper>
            <Trivia>
              Pozvěte své kamarády a známé, a{" "}
              <Box component="span" fontWeight="800" display="inline">
                oba
              </Box>{" "}
              dostanete odměnu {reward} Kč, samozřejmě v bitcoinu. Stačí když se
              vámi pozvaný kamarád zaregistruje, plně ověří svůj účet a provede
              nákup Bitcoinu za minimálně {reward} Kč.
            </Trivia>

            {isVerificationDisclaimerVisible && (
              <Trivia>
                Před pozváním přátel je nutné provést ověření vašeho účtu
              </Trivia>
            )}

            {!hasFreeInvitations && !isVerificationDisclaimerVisible && (
              <Trivia fontWeight={600}>
                Využili jste všechny své pozvánky, když Vaši pozvaní přátelé
                plně ověří svůj účet, dostanete odměnu a můžete pozvat další
                přátele.
              </Trivia>
            )}

            {canShowInvite && (
              <UnstyledLink to={appUrl("invite-lead")}>
                <PrimaryButton
                  type="button"
                  variant="contained"
                  color="info"
                  fullWidth
                >
                  Pozvat přítele
                </PrimaryButton>
              </UnstyledLink>
            )}
            {isVerificationDisclaimerVisible && (
              <UnstyledLink to={appUrl("verification")}>
                <PrimaryButton
                  type="button"
                  variant="contained"
                  color="info"
                  fullWidth
                >
                  Ověřit
                </PrimaryButton>
              </UnstyledLink>
            )}
          </PrimaryPaper>

          {!!leadList && !isVerificationDisclaimerVisible && (
            <>
              <br />
              <Typography
                fontWeight={600}
                color={Colors.DarkGrey}
                paddingBottom={"5px"}
              >
                Vaše pozvánky
              </Typography>
              {leadList.items.map(item => (
                <StyledListItem key={item.code} divider>
                  <Row>
                    <Typography>{item.email}</Typography>
                    <Typography fontWeight={600}>
                      {translateStatus(item.status)}
                    </Typography>
                  </Row>
                </StyledListItem>
              ))}

              {isVerificationDisclaimerVisible && (
                <UnstyledLink
                  to={appUrl("verification")}
                  style={{ width: "100%" }}
                >
                  <StyledListItem divider>
                    <Row>
                      <Typography fontWeight={600}>{`Ověřit účet`}</Typography>
                      <ChevronRight />
                    </Row>
                  </StyledListItem>
                </UnstyledLink>
              )}
            </>
          )}
        </div>
      </StyledPageWrapper>
    </AuthenticatedLayout>
  );
};

export default AffiliatePage;
