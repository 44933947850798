import styled from "styled-components";

export const JobTypeHeader = styled.div`
  display: flex;

  svg {
    width: 48px;
    height: auto;
    margin-right: ${props => props.theme.spacing(1)};
    color: ${props => props.theme.palette.primary.main};
  }

  margin-bottom: ${props => props.theme.spacing(2)};
`;
