import {
  EntityListOfCategoryDto,
  EntityListOfOfferListItemDto,
  OfferDto,
} from "Api/Api";
import { getCategoryListAsync } from "State/Coinback/Categories/CategoryListState";
import { getOfferDetailAsync } from "State/Coinback/OfferDetail/OfferDetailState";
import { getOfferListAsync } from "State/Coinback/OfferList/OfferListState";
import { createReducer, ActionType } from "typesafe-actions";

type CoinbackState = {
  isOfferListLoading: boolean;
  offerList: EntityListOfOfferListItemDto | null;
  isCategoryListLoading: boolean;
  categoryList: EntityListOfCategoryDto | null;

  offerDetail: OfferDto | null;
  isOfferDetailLoading: boolean;
};

type CoinbackAction =
  | ActionType<typeof getOfferListAsync>
  | ActionType<typeof getCategoryListAsync>
  | ActionType<typeof getOfferDetailAsync>;

export const coinbackReducer = createReducer<CoinbackState, CoinbackAction>({
  isOfferListLoading: false,
  offerList: null,
  isCategoryListLoading: false,
  categoryList: null,
  isOfferDetailLoading: false,
  offerDetail: null,
})
  .handleAction(getOfferListAsync.request, state => {
    return { ...state, isOfferListLoading: true };
  })
  .handleAction(getOfferListAsync.success, (state, action) => {
    return { ...state, isOfferListLoading: false, offerList: action.payload };
  })
  .handleAction(getOfferListAsync.failure, state => {
    return {
      ...state,
      isOfferListLoading: false,
    };
  })
  .handleAction(getCategoryListAsync.request, state => {
    return { ...state, isCategoryListLoading: true };
  })
  .handleAction(getCategoryListAsync.success, (state, action) => {
    return {
      ...state,
      isCategoryListLoading: false,
      categoryList: action.payload,
    };
  })
  .handleAction(getCategoryListAsync.failure, state => {
    return {
      ...state,
      isCategoryListLoading: false,
    };
  })
  .handleAction(getOfferDetailAsync.request, state => {
    return { ...state, isOfferDetailLoading: true };
  })
  .handleAction(getOfferDetailAsync.success, (state, action) => {
    return {
      ...state,
      isOfferDetailLoading: false,
      offerDetail: action.payload,
    };
  })
  .handleAction(getOfferDetailAsync.failure, state => {
    return {
      ...state,
      isOfferDetailLoading: false,
    };
  });
