import * as React from "react";
export const InfoIcon = (props: React.SVGProps<SVGSVGElement>) => {
  props = {
    ...props,
    color: props.color,
  };
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={46}
      height={46}
      viewBox="0 0 46 46"
      {...props}
    >
      <path
        d="M25,2A23,23,0,1,0,48,25,23,23,0,0,0,25,2Zm0,9a3,3,0,1,1-3,3A3,3,0,0,1,25,11Zm4,27H21V36h2V23H21V21h6V36h2Z"
        transform="translate(-2 -2)"
        fill="currentColor"
      />
    </svg>
  );
};
