import {
  GenerateSystemAddressCommandResult,
  postSystemGenerateAddress,
} from "Api/Api";
import { createAsyncAction } from "typesafe-actions";
import { put, takeLatest } from "redux-saga/effects";
import { getType } from "typesafe-actions";
import { call } from "typed-redux-saga";

export const generateSystemAddressAsync = createAsyncAction(
  "@system/GENERATE_ADDRESS_REQUEST",
  "@system/GENERATE_ADDRESS_SUCCESS",
  "@system/GENERATE_ADDRESS_FAILURE",
)<void, GenerateSystemAddressCommandResult, Error>();

function* generateSystemAddress(
  action: ReturnType<typeof generateSystemAddressAsync.request>,
): Generator {
  try {
    const response = yield* call(postSystemGenerateAddress);
    if (response.status === 200) {
      yield put(generateSystemAddressAsync.success(response.json));
    } else {
      yield put(
        generateSystemAddressAsync.failure(
          new Error(`Error in action ${action.type}`, {
            cause: response.status,
          }),
        ),
      );
    }
  } catch (err) {
    yield put(generateSystemAddressAsync.failure(err as Error));
  }
}
export function* generateSystemAddressSaga() {
  yield takeLatest(
    getType(generateSystemAddressAsync.request),
    generateSystemAddress,
  );
}
