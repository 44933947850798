import { logError } from "ErrorService";
import { AnyAction } from "redux";
import { put, takeEvery } from "typed-redux-saga";

function* handleFailure(action: AnyAction): Generator {
  if (action.type.endsWith("_FAILURE")) {
    if (action.payload instanceof Error) {
      if (
        action.payload.cause !== 201 &&
        action.payload.cause !== 401 &&
        action.payload.cause !== 503
      ) {
        logError(action.payload, null, false);
      }
    }
    yield put({ type: "empty" });
  }
}

export function* errorSaga() {
  yield takeEvery("*", handleFailure);
}
