import { UserStatus } from "Infrastructure/Api/Api";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, RouteProps, useLocation } from "react-router";
import { resetUser } from "State/Auth/AuthReducer";
import { RootStateType } from "State/Store";
import { getAppUrl } from "Utils/UrlUtils";

type AuthorizedRouteProps = RouteProps & {
  isUserAuthenticated: boolean;
  allowAnonymous?: boolean;
};

export const AuthenticatedRoute: React.FunctionComponent<
  React.PropsWithChildren<AuthorizedRouteProps>
> = props => {
  const { children, isUserAuthenticated, allowAnonymous } = props;
  const appUrl = getAppUrl();
  const dispatch = useDispatch();
  const location = useLocation();
  const user = useSelector((state: RootStateType) => state.auth.user);

  if (isUserAuthenticated === false && location.pathname !== "/") {
    dispatch(
      resetUser({ authRedirectUrl: location.pathname + location.search }),
    );
  }

  if (isUserAuthenticated === false) {
    return (
      <Navigate
        to={appUrl("sign-in")}
        state={{ from: location }}
        replace={true}
      />
    ) as any;
  }

  if (!allowAnonymous && user?.userStatus === UserStatus.Anonymous) {
    return (
      <Navigate
        to={appUrl("dashboard")}
        state={{ from: location }}
        replace={true}
      />
    ) as any;
  }

  return children ?? (null as any);
};
