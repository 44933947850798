import { Box } from "@mui/material";
import { BankAccountRowLink } from "Components/BankAccounts/BankAccountRowLink";
import type { Identifier, XYCoord } from "dnd-core";
import { BankAccountListItem } from "Infrastructure/Api/Api";
import type { FC } from "react";
import { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";

export interface CardProps {
  index: number;
  moveCard: (dragIndex: number, hoverIndex: number) => void;
  bankAccount: BankAccountListItem;
  isTouch?: boolean;
}

interface DragItem {
  index: number;
  type: string;
}

export const Card: FC<CardProps> = ({
  bankAccount,
  index,
  moveCard,
  isTouch,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [{ handlerId }, drop] = useDrop<
    DragItem,
    void,
    { handlerId: Identifier | null }
  >({
    accept: "card",
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: "card",
    item: () => {
      return { bankAccount, index };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging && !isTouch ? 0 : 1;
  const backgroundColor = isDragging && isTouch ? "grey.200" : "transparent";

  drag(drop(ref));

  return (
    <Box
      ref={ref}
      data-handler-id={handlerId}
      sx={{
        borderRadius: 2,
        cursor: "move",
        opacity,
        backgroundColor,
      }}
    >
      <BankAccountRowLink item={bankAccount} isEdit />
    </Box>
  );
};
