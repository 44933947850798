import { createReducer, ActionType, createAction } from "typesafe-actions";

export type UploadReducerState = {
  progress: number | null;
};

export const setFetchProcess = createAction("@upload/SET_FETCH_PROCESS")<{
  progress: number | null;
  isBatch: boolean;
}>();

type UploadReducerAction = ActionType<typeof setFetchProcess>;

export const uploadReducer = createReducer<
  UploadReducerState,
  UploadReducerAction
>({
  progress: null,
}).handleAction(setFetchProcess, (state, action) => {
  return {
    ...state,
    progress: action.payload.progress,
  };
});
