import { Box, Button, Typography } from "@mui/material";
import { Colors } from "Components/Layout/Themes/Colors";
import { FC, PropsWithChildren } from "react";

type Props = {
  title?: string;
  onEdit?: () => void;
  hideDivider?: boolean;
};

export const ValuesBox: FC<PropsWithChildren<Props>> = ({
  children,
  onEdit,
  title,
}) => {
  return (
    <>
      <Box display="grid" rowGap={3}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          {!!title && (
            <Typography
              fontSize={16}
              fontWeight={600}
              textTransform="uppercase"
            >
              {title}
            </Typography>
          )}
          {!!onEdit && (
            <Button
              variant="text"
              onClick={onEdit}
              sx={{
                fontSize: "14px",
                fontWeight: 600,
                color: Colors.Link,
                padding: 0,
                textTransform: "none",
              }}
            >
              Upravit
            </Button>
          )}
        </Box>
        {children}
      </Box>
    </>
  );
};
