import { EntityListOfInvoiceListItemDto } from "Api/Api";
import { createAsyncAction } from "typesafe-actions";
import { put, takeLatest } from "redux-saga/effects";
import { postInvoiceInvoiceList } from "Api/Api";
import { getType } from "typesafe-actions";
import { call } from "typed-redux-saga";

export const getInvoiceListAsync = createAsyncAction(
  "@invoice/LIST_REQUEST",
  "@invoice/LIST_SUCCESS",
  "@invoice/LIST_FAILURE",
)<void, EntityListOfInvoiceListItemDto, Error>();

function* getInvoiceList(
  action: ReturnType<typeof getInvoiceListAsync.request>,
): Generator {
  try {
    const response = yield* call(postInvoiceInvoiceList);
    if (response.status === 200) {
      yield put(getInvoiceListAsync.success(response.json));
    } else {
      yield put(
        getInvoiceListAsync.failure(
          new Error(`Error in action ${action.type}`, {
            cause: response.status,
          }),
        ),
      );
    }
  } catch (err) {
    yield put(getInvoiceListAsync.failure(err as Error));
  }
}
export function* getInvoiceListSaga() {
  yield takeLatest(getType(getInvoiceListAsync.request), getInvoiceList);
}
