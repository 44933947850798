import type { FC } from "react";

import airBank from "Assets/Images/Banks/ab.png";
import kb from "Assets/Images/Banks/kb.png";
//import rb from "Assets/Images/Banks/rb.png";
import csas from "Assets/Images/Banks/csas.png";
import csob from "Assets/Images/Banks/csob.png";
//import fio from "Assets/Images/Banks/fio.png";
import unicreditBank from "Assets/Images/Banks/unicredit_bank.jpg";
import type { Bank } from "Infrastructure/Api/Api";
import { Skeleton } from "@mui/material";

const HEIGHT = 32;
const WIDTH = 64;

const iconsSrc: {
  [key in Bank]: { image: string; width?: number; height?: number };
} = {
  CSOB: { image: csob, height: HEIGHT },
  CSAS: { image: csas, height: HEIGHT },
  KB: { image: kb, height: HEIGHT },
  AirBank: { image: airBank, height: HEIGHT },
  UCB: { image: unicreditBank, width: WIDTH },
  //FIO: { image: fio, height: HEIGHT },
};

const getImageUrl = (bank: Bank) =>
  new URL(iconsSrc[bank].image, import.meta.url).href;

type Props = {
  bank?: Bank;
  name?: string;
  isLoading?: boolean;
};

export const BankImage: FC<Props> = ({ bank, name, isLoading }) => {
  if (isLoading) {
    return <Skeleton variant="rectangular" width={120} height={HEIGHT} />;
  }

  if (!bank) {
    return null;
  }

  const logoDefinition = iconsSrc[bank];

  return (
    <img
      src={getImageUrl(bank)}
      height={logoDefinition.height}
      width={logoDefinition.width}
      alt={`${name || "bank"} logo`}
    />
  );
};
