import { type FormData, SignInForm } from "Components/Auth/SignIn/SignInForm";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import type { RootStateType } from "State/Store";
import { Resources, useServerError } from "Translations/Resources";
import { resetError, setSignInStep } from "State/Auth/AuthReducer";
import { SignInStep } from "State/Auth/Models/AuthStateModels";
import { signInAsync } from "State/Auth/SignIn/SignInState";
import { AuthPageLayout } from "Components/Auth/Shared/AuthPageLayout";

export const SignInPage: React.FunctionComponent<
  React.PropsWithChildren<unknown>
> = _ => {
  const dispatch = useDispatch();

  const location = useLocation();
  const navigate = useNavigate();

  const login = new URLSearchParams(location.search).get("login") || "";

  React.useEffect(() => {
    dispatch(resetError());
  }, [dispatch]);

  const { translateError } = useServerError(
    Resources.SignIn.Error,
    Resources.SignIn.Error.General,
  );

  const {
    isLoading,
    errorCode,
    signInStep: step,
  } = useSelector((e: RootStateType) => e.auth);

  const onSubmit = ({ login, password, token }: FormData) => {
    dispatch(
      signInAsync.request({
        model: { login, password, token },
        navigate,
      }),
    );
  };
  const [resetValue, toggleReset] = React.useState(true);
  const onFormReset = () => {
    toggleReset(!resetValue);
    dispatch(setSignInStep(SignInStep.Credentials));
  };

  return (
    <AuthPageLayout
      title={"Přihlášení"}
      isLoading={isLoading}
      pageType={"sign-in"}
      key={`${resetValue}`}
    >
      <SignInForm
        step={step}
        isLoading={isLoading}
        onSubmit={onSubmit}
        serverError={translateError(errorCode)}
        onReset={onFormReset}
        login={login}
      />
    </AuthPageLayout>
  );
};

export default SignInPage;
