import { IdentityProvider } from "Api/Api";
import * as React from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { signInSsoAsync } from "State/Auth/SignIn/SignInSsoState";
import { StyledContainer } from "Components/Auth/Styles";
import { AuthRedirectProgress } from "Components/Auth/Shared/AuthRedirectProgress";
import { AuthPageLayout } from "Components/Auth/Shared/AuthPageLayout";

export const GoogleRedirectPage: React.FunctionComponent<
  React.PropsWithChildren<unknown>
> = _ => {
  const dispatch = useDispatch();

  const location = useLocation();
  const navigate = useNavigate();

  const code = new URLSearchParams(location.search).get("code") || "";

  React.useEffect(() => {
    dispatch(
      signInSsoAsync.request({
        model: {
          token: code,
          identityProvider: IdentityProvider.Google,
          affiliateCode: localStorage.getItem("affiliateCode"),
        },
        navigate,
      }),
    );
  }, [code, dispatch, navigate]);

  return (
    <AuthPageLayout title={"Přesměrování"} isLoading={false} pageType="other">
      <StyledContainer>
        <AuthRedirectProgress />
      </StyledContainer>
    </AuthPageLayout>
  );
};

export default GoogleRedirectPage;
