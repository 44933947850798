import { Typography } from "@mui/material";
import { FC } from "react";

export const SandboxText: FC = () => {
  return (
    <Typography color="textSecondary" marginLeft={1} component="span" noWrap>
      (Sandbox)
    </Typography>
  );
};
