import { Grid, Typography } from "@mui/material";
import { Colors } from "Components/Layout/Themes/Colors";
import { UnstyledLink } from "Components/Routes/UnstyledLink";
import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";
import { ForwardIcon } from "Components/Shared/Icons";
import { PrimaryPaper } from "Components/Shared/PrimaryPaper";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootStateType } from "State/Store";
import { getUserVerificationDetailAsync } from "State/Verification/GetUserVerificationDetail/GetUserVerificationDetailState";
import styled from "styled-components";
import { getAppUrl } from "Utils/UrlUtils";
import { isNotFullyVerified } from "Utils/VerificationUtils";

const StyledButton = styled(PrimaryButton)`
  background: #ffffff;
  border: 1px solid #dd0606;
  box-shadow: 1px 1px 2px rgba(53, 53, 57, 0.15);
  border-radius: 4px;
  text-transform: none;
  width: 150px;

  &:hover {
    background: #ffffff;
    border: 1px solid #dd0606;
  }

  p {
    font-weight: 600;
    font-size: 16px;
    color: #dd0606;
  }
`;

const Wrapper = styled.div``;

export const DashboardPopup: React.FunctionComponent<
  React.PropsWithChildren<unknown>
> = _ => {
  const appUrl = getAppUrl();

  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(getUserVerificationDetailAsync.request());
  }, [dispatch]);

  const { detail } = useSelector((e: RootStateType) => e.verification);
  const { transactionItems } = useSelector((e: RootStateType) => e.transaction);

  const canShowVerificationPopup =
    !!detail && isNotFullyVerified(detail) && transactionItems.length > 0;

  return (
    <>
      {canShowVerificationPopup && (
        <Wrapper>
          <PrimaryPaper>
            <Typography
              color={Colors.Red}
              align="left"
              fontWeight={600}
              marginBottom={3}
            >
              {"Omezený účet"}
            </Typography>
            <Grid container spacing={4}>
              <Grid item xs={6}>
                <Typography align="left">
                  {
                    "Váš účet má nyní omezené funkce a limity. K plnému využití je třeba udělat Vaše ověření."
                  }
                </Typography>
              </Grid>

              <Grid item xs={6} display="flex" justifyContent="center">
                <UnstyledLink to={appUrl("verification")}>
                  <StyledButton
                    fullWidth
                    variant="outlined"
                    startIcon={<ForwardIcon color={Colors.Red} />}
                  >
                    <Typography>{"Ověřit"}</Typography>
                  </StyledButton>
                </UnstyledLink>
              </Grid>
            </Grid>
          </PrimaryPaper>
        </Wrapper>
      )}
    </>
  );
};
