import {
  EntityListOfLeadDto,
  LeadInvitationCommandResultStatus,
} from "Api/Api";
import { inviteLeadAsync } from "State/Afl/LeadInvitation/LeadInvitation";
import { getLeadListAsync } from "State/Afl/LeadListState/LeadListState";
import { createReducer, ActionType, createAction } from "typesafe-actions";

type AffiliateReducerState = {
  leadList: EntityListOfLeadDto | null;
  leadInvitationStatus: LeadInvitationCommandResultStatus | null;
  isLoading: boolean;
};

export const resetLeadInvitationStatus = createAction(
  "@afl/RESET_LEAD_INVITATION_STATUS",
)();

type AffiliateReducerAction =
  | ActionType<typeof resetLeadInvitationStatus>
  | ActionType<typeof getLeadListAsync>
  | ActionType<typeof inviteLeadAsync>;

export const affiliateReducer = createReducer<
  AffiliateReducerState,
  AffiliateReducerAction
>({ leadList: null, leadInvitationStatus: null, isLoading: false })
  .handleAction(resetLeadInvitationStatus, state => {
    return { ...state, leadInvitationStatus: null };
  })
  .handleAction(getLeadListAsync.success, (state, action) => {
    return { ...state, leadList: action.payload };
  })
  .handleAction(inviteLeadAsync.request, (state, action) => {
    return { ...state, leadInvitationStatus: null, isLoading: true };
  })
  .handleAction(inviteLeadAsync.success, (state, action) => {
    return {
      ...state,
      leadInvitationStatus: action.payload.status,
      isLoading: false,
    };
  })
  .handleAction(inviteLeadAsync.failure, (state, action) => {
    return { ...state, leadInvitationStatus: null, isLoading: false };
  });
