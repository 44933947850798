import Typography from "@mui/material/Typography";
import { UnauthenticatedHeader } from "Components/Layout/UnauthenticatedHeader";
import { UnauthenticatedLayout } from "Components/Layout/UnauthenticatedLayout";
import { UnstyledLink } from "Components/Routes/UnstyledLink";
import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";
import React from "react";
import styled from "styled-components";
import { getAppUrl } from "Utils/UrlUtils";
import { useDispatch } from "react-redux";
import { closeWelcomePage } from "State/Auth/AuthReducer";
import { useNavigate } from "react-router-dom";
import { EmployeePanel } from "Components/Welcome/EmployeePanel";
import { IndividualBusinessPersonPanel } from "Components/Welcome/IndividualBusinessPersonPanel";
import { Divider } from "@mui/material";
import { StyledFlex } from "Components/Shared/StyledComponents";

const StyledContainer = styled.div<{ isGray?: boolean }>`
  display: flex;
  justify-content: center;
  ${props => props.isGray && `background-color: ${props.theme.colors.gray};`}

  & > div {
    width: 780px;
  }
`;

const TwoColumnsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  & > div {
    flex-basis: 49%;
  }
`;

const StyledButton = styled(PrimaryButton)`
  margin: ${props => props.theme.spacing(3, 0)};
  width: 30%;
`;

export const StyledWrapper = styled.div`
  display: grid;
  height: 100%;
  grid-template-rows: auto 1fr;
  min-height: ${props => props.theme.vh(100)};
`;

export const WelcomePage: React.FunctionComponent<
  React.PropsWithChildren<unknown>
> = _ => {
  const appUrl = getAppUrl();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const closePage = () => {
    dispatch(closeWelcomePage());
    navigate(appUrl("dashboard"));
  };

  return (
    <UnauthenticatedLayout title="Vítejte">
      <StyledWrapper>
        <StyledContainer>
          <div>
            <UnauthenticatedHeader></UnauthenticatedHeader>
            <Typography>
              {
                "Vytvořili jsme Váš uživatelský účet. Níže si přečtěte, jak začít s příjmem výplaty v bitcoinech."
              }
            </Typography>
            <br />
            <Typography>{"Jaký je Váš typ příjmů?"}</Typography>
            <br />
            <TwoColumnsContainer>
              <EmployeePanel />
              <Divider orientation="vertical" flexItem />
              <IndividualBusinessPersonPanel />
            </TwoColumnsContainer>
            <br />
            <br />
            <Divider />
            <br />
            <div>
              <Typography>
                {
                  "Jelikož je Váš účet zatím neověřený, máte nastavené nízké limity transakcí. Dodatečné ověření můžete následně provést ve svém profilu."
                }
              </Typography>

              <UnstyledLink to={appUrl("verification")} isWithIcon>
                {"Limity transackcií"}
              </UnstyledLink>
              <br />
            </div>
          </div>
        </StyledContainer>
        <StyledContainer isGray={true}>
          <StyledFlex alignItems="flex-start" justifyContent="center">
            <StyledButton variant="contained" onClick={() => closePage()}>
              {"Dokončit"}
            </StyledButton>
          </StyledFlex>
        </StyledContainer>
      </StyledWrapper>
    </UnauthenticatedLayout>
  );
};

export default WelcomePage;
