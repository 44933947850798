import { Box } from "@mui/material";
import * as React from "react";
import styled from "styled-components";
import { Resources, useResource } from "Translations/Resources";
import { useIsMobile } from "Hooks/useIsMobile";
import { BOTTOM_MENU_HEIGHT } from "Components/Layout/Menu/BottomMenu";
import { useHasBackgroundImage } from "Hooks/Layout/useHasBackgroundImage";
import MooneLogo from "Assets/Images/Images/moone.svg";
import { PageTitle } from "Components/Shared/PageTitles/PageTitle";

const StyledWrapper = styled(Box)`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: ${props => props.theme.spacing(6, 0, 0, 12)};
  z-index: 2;

  ${props => props.theme.breakpoints.down("lg")} {
    padding: ${props => props.theme.spacing(6, 3)};
  }
`;

const StyledChildrenWrapper = styled.div`
  margin-top: ${props => props.theme.spacing(6)};
  height: 100%;
`;

type Props = {
  title: string;
  goBackUrl?: string;
  onChevronClick?: () => void;
};

export const AnonymousLayout: React.FC<
  React.PropsWithChildren<Props>
> = props => {
  const { title, onChevronClick } = props;

  const { t } = useResource();
  const { isMobile } = useIsMobile();
  const { hasBackgroundImage } = useHasBackgroundImage();

  React.useEffect(() => {
    document.title = `${title} | ${t(Resources.Common.AppName)}`;
  }, [title, t]);

  return (
    <>
      <Box
        overflow="hidden"
        display="flex"
        paddingBottom={isMobile ? BOTTOM_MENU_HEIGHT : undefined}
      >
        <StyledWrapper marginTop={hasBackgroundImage ? 24 : undefined}>
          <PageTitle onChevronClick={onChevronClick}>
            <img src={MooneLogo} alt="moone logo" width={143} height={45} />
          </PageTitle>
          <StyledChildrenWrapper>{props.children}</StyledChildrenWrapper>
        </StyledWrapper>
      </Box>
    </>
  );
};
