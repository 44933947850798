import { Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { RootStateType } from "State/Store";
import styled from "styled-components";
import { default as MuiAvatar } from "@mui/material/Avatar";

const StyledWrapper = styled.div`
  display: flex;
  grid-template-columns: auto 1fr;
  align-items: center;
  padding: ${props => props.theme.spacing(5, 4)};
  gap: ${props => props.theme.spacing(3)};
`;

const StyledMuiAvatart = styled(MuiAvatar)`
  background: ${props => props.theme.palette.gradient.primary};
`;

const truncate = (input: string) => {
  const threshold = 22;
  if (input.length <= threshold) {
    return input;
  }

  const shortened = input.split("@")[0];

  return shortened.length > threshold
    ? `${shortened.substring(0, threshold)}...`
    : shortened;
};

export const Avatar: React.FunctionComponent<
  React.PropsWithChildren<unknown>
> = _ => {
  const { user } = useSelector((e: RootStateType) => e.auth);
  const name =
    !!user?.firstName && !!user?.lastName
      ? `${user.firstName} ${user.lastName}`
      : `${user?.login}`;

  const acronym =
    !!user?.firstName && !!user?.lastName
      ? `${user.firstName[0]}${user.lastName[0]}`
      : `${user?.login[0]}`;
  return (
    <StyledWrapper>
      {user?.profilePicture && <StyledMuiAvatart src={user.profilePicture} />}
      {!user?.profilePicture && <StyledMuiAvatart>{acronym}</StyledMuiAvatart>}
      <div>
        <Typography variant={"h3"} fontWeight={700} fontSize={14}>
          {truncate(name)}
        </Typography>
        {user && user.login !== name && (
          <Typography fontWeight={400} fontSize={14}>
            {truncate(user.login)}
          </Typography>
        )}
      </div>
    </StyledWrapper>
  );
};
