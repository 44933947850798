import * as React from "react";
import Button, { ButtonProps } from "@mui/material/Button";
import styled from "styled-components";
import { CircularProgress } from "@mui/material";
import { PrimarySkeleton } from "Components/Shared/PrimarySkeleton";
import { isNoU } from "Utils/ObjectUtils";

export type PrimaryButtonProps = ButtonProps & {
  paddingLeftRight?: number;
  isLoading?: boolean;
  fetchProgress?: number | null;
};

const StyledButton = styled(
  ({ paddingLeftRight, fetchProgress, ...rest }: PrimaryButtonProps) => (
    <Button {...rest} />
  ),
)`
  padding: 15px ${props => props.theme.spacing(props.paddingLeftRight ?? 2)};
  min-width: 130px;
  border: ${props =>
    props.variant === "contained" ? "1px solid transparent" : ""};
  box-shadow: ${props =>
    props.variant !== "text" && props.theme.shadow.primary};
  font-weight: ${props => props.variant === "text" && 600};

  ${props =>
    props.color === "primary" &&
    `
      background: ${props.theme.palette.gradient.primary};
      color: ${props.theme.palette.common.white};

      &:hover {
        background: ${props.theme.palette.gradient.primary};
      }

      &.Mui-disabled {
        color: ${props.theme.palette.common.white};
      }
    `}

  ${props =>
    props.color === "secondary" &&
    `
      background-color: ${props.theme.palette.common.white};
      color: ${props.theme.palette.text.primary};
      border: 1px solid ${props.theme.palette.primary.main};
      box-shadow: none;

      &:hover {
        background-color: ${props.theme.palette.primary.main};
        color: ${props.theme.palette.primary.contrastText};
      }

    `}

  .MuiButton-label {
    z-index: 1;
  }

  &.Mui-disabled {
    background: ${props => props.theme.palette.gradient.primaryDisabled};
  }
`;

const StyledLoadingIconWrapper = styled.div<{
  $color?: "primary" | "secondary" | string;
}>`
  min-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;

  ${props =>
    props.$color === "primary"
      ? `
      background: ${props.theme.palette.gradient.primaryDisabled};
    `
      : `
      background-color: ${props.theme.palette.secondary.contrastText};
    `}

  border-radius: ${props => props.theme.borderRadius};
`;

const StyledProgress = styled(PrimarySkeleton)`
  &.MuiSkeleton-root {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    height: 100%;
    background-color: ${props => props.theme.palette.primary.main};
    z-index: -1;
  }
`;

export const PrimaryButton: React.FunctionComponent<
  React.PropsWithChildren<PrimaryButtonProps>
> = props => {
  const { isLoading, fetchProgress, variant, ...rest } = props;

  return (
    <StyledButton
      paddingLeftRight={props.paddingLeftRight}
      fetchProgress={fetchProgress}
      {...rest}
      color={props.color ?? "secondary"}
      variant={variant ?? "contained"}
      disabled={rest.disabled || isLoading}
    >
      {isLoading && (
        <StyledLoadingIconWrapper $color={rest.color}>
          <CircularProgress size={18} />
        </StyledLoadingIconWrapper>
      )}
      {props.children}
      {!isNoU(fetchProgress) && <StyledProgress width={`${fetchProgress}%`} />}
    </StyledButton>
  );
};
