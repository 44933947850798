import { Divider, Typography } from "@mui/material";
import { StyledFlex } from "Components/Shared/StyledComponents";
import styled from "styled-components";

export const AuthPage = styled.div`
  display: grid;
  align-self: center;
  align-items: center;
  justify-items: center;

  ${props => props.theme.breakpoints.down("md")} {
    margin-bottom: 30px;
  }
`;

export const AuthPageContent = styled.div`
  ${props => props.theme.breakpoints.down("md")} {
    width: 98%;
  }

  ${props => props.theme.breakpoints.up("md")} {
    width: 600px;
  }
`;

export const AuthHeader = styled(Typography)`
  padding: 0 2.5%;
  text-transform: uppercase;
`;

export const AuthDivider = styled(Divider)`
  padding: 10px 10%;
`;

export const AuthWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 2.5%;
`;

export const AuthFooterSection = styled(StyledFlex)`
  padding: ${props => props.theme.spacing(2)} 0;
`;
