import { CodeListItem } from "Models/ICodeListDto";

export function enumToCodeList<T extends object>(
  enumToConvert: T,
): CodeListItem[] {
  return Object.keys(enumToConvert).map(key => ({
    code: key,
    name: key,
  }));
}
