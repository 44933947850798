import { Typography } from "@mui/material";
import { AuthenticatedLayout } from "Components/Layout/AuthenticatedLayout";
import { StyledHtmlLink } from "Components/Routes/UnstyledLink";
import { PrimaryPaper } from "Components/Shared/PrimaryPaper";
import {
  StyledFlex,
  StyledPageWrapper,
} from "Components/Shared/StyledComponents";
import styled from "styled-components";

const Trivia = styled(Typography)`
  color: ${props => props.theme.palette.text.secondary};
  margin: ${props => props.theme.spacing(1, 0, 3, 0)};
`;

const StyledText = styled(StyledFlex)`
  flex-wrap: wrap;
`;

export const ContactPage: React.FunctionComponent<
  React.PropsWithChildren<unknown>
> = _ => {
  return (
    <AuthenticatedLayout title="Kontakt">
      <StyledPageWrapper>
        <div className="left-content">
          <PrimaryPaper>
            <StyledText gap="6px">
              <Typography>E-mail:</Typography>
              <StyledHtmlLink href="mailto:info@coinwage.io">
                info@coinwage.io
              </StyledHtmlLink>
            </StyledText>
            <StyledText gap="6px">
              <Typography>Telefon:</Typography>
              <StyledHtmlLink href="tel:+420703120413">
                +420 703 120 413
              </StyledHtmlLink>
            </StyledText>

            <Trivia fontSize={13}>
              Telefonicky nás můžete kontaktovat v pracovní dny od 9:00 do
              16:00.
            </Trivia>

            <Typography fontWeight={600}>Provozovatel</Typography>
            <Typography>Moone s.r.o.</Typography>
            <Typography>IČ: 11833866</Typography>
            <br />

            <Typography fontWeight={600}>Bankovní spojení</Typography>
            <Typography>Fio banka: 2702109034/2010</Typography>
          </PrimaryPaper>
        </div>
      </StyledPageWrapper>
    </AuthenticatedLayout>
  );
};

export default ContactPage;
