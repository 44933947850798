import styled from "styled-components";

const StyledFormControl = styled.div`
  &.long {
    flex: 0 0 360px;
    max-width: 360px;
  }

  &.medium {
    flex: 0 0 170px;
    max-width: 170px;
  }

  &.tiny {
    flex: 0 0 85px;
    max-width: 85px;
  }

  &.no-label {
    padding-top: 26px;
  }
`;

const StyledFormLabelWrapper = styled.div`
  margin-bottom: ${props => props.theme.spacing(2)};
  display: flex;
  align-items: center;
  z-index: 1;

  label {
    display: flex;
    align-items: center;
    font-weight: 300;
    margin: 0;
    color: ${props => props.theme.palette.text.primary};
  }
`;
const StyledFormInput = styled.div`
  & > div {
    display: flex;
  }
`;

const StyledValidationText = styled.div`
  font-size: 12px;
  color: ${props => props.theme.palette.error.main};
  min-height: 20px;
`;

export {
  StyledFormControl,
  StyledFormInput,
  StyledFormLabelWrapper,
  StyledValidationText,
};
