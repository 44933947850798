import { Typography } from "@mui/material";
import { TransactionItem } from "Components/Shared/Transactions/TransactionItem";
import { TransactionItem as TransactionItemType } from "Infrastructure/Api/Api";
import { FC } from "react";

type Props = {
  transactions: TransactionItemType[];
};

export const TransactionItems: FC<Props> = ({ transactions }) => {
  if (!transactions.length) {
    return (
      <Typography marginTop={theme => theme.spacing(2)}>
        {"Zatím nebyla provedena žádná transakce."}
      </Typography>
    );
  }

  return (
    <>
      {transactions.map(e => (
        <TransactionItem key={e.publicID} item={e} direction="incoming" />
      ))}
    </>
  );
};
