export const nameof = <T>(name: keyof T) => name;

/**
 * Is null or undefined
 * @param o
 */
export const isNoU = (o: any) => o === null || o === undefined;

/**
 * Does not mutate value.
 *
 * Only changes type for TypeScript.
 * @param o
 */
export const nullAsUndefined = <T>(o: T | null) => o as T | undefined;

export const enumToArray = <T extends object>(
  enumToConvert: T,
): Array<keyof T> => {
  return Object.keys(enumToConvert) as any;
};

export const isArrayEmpty = (a: Array<any> | null | undefined) =>
  !a || a.length === 0;

export const toCamelCase = <T>(o: T): T => {
  const origO = o as any;

  if (origO instanceof Array) {
    return origO.map(value => {
      if (typeof value === "object") {
        value = toCamelCase(value);
      }
      return value;
    }) as any;
  } else {
    const newO: any = {};

    for (const origKey in origO) {
      if (origO.hasOwnProperty(origKey)) {
        const newKey = (
          origKey.charAt(0).toLowerCase() + origKey.slice(1) || origKey
        ).toString();

        let value = origO[origKey];

        if (
          value instanceof Array ||
          (value !== null && value.constructor === Object)
        ) {
          value = toCamelCase(value);
        }
        newO[newKey] = value;
      }
    }
    return newO;
  }
};
