import { Typography } from "@mui/material";
import {
  IdentityProvider,
  ProcessBankIDVerificationCommandResultStatus,
} from "Api/Api";
import { AuthPageLayout } from "Components/Auth/Shared/AuthPageLayout";
import { AuthRedirectProgress } from "Components/Auth/Shared/AuthRedirectProgress";
import { StyledContainer } from "Components/Auth/Styles";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { signInSsoAsync } from "State/Auth/SignIn/SignInSsoState";
import { RootStateType } from "State/Store";
import { processBankIDVerificationAsync } from "State/Verification/BankID/ProcessBankIDVerificationState";
import { Resources, useServerError } from "Translations/Resources";

type CallbackState = "sign-up" | "verification";

export const BankIDRedirectPage: React.FunctionComponent<
  React.PropsWithChildren<unknown>
> = _ => {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const bankIDError = searchParams.get("error");

  const queryString = location.hash.replace("#", "");
  const params = new URLSearchParams(queryString);
  const accessToken = params.get("access_token");

  const callbackState = params.get("state") as CallbackState;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (!!accessToken) {
      if (callbackState === "sign-up") {
        dispatch(
          signInSsoAsync.request({
            model: {
              token: accessToken,
              identityProvider: IdentityProvider.BankID,
              affiliateCode: localStorage.getItem("affiliateCode"),
            },
            navigate,
          }),
        );
      }
      if (callbackState === "verification") {
        dispatch(
          processBankIDVerificationAsync.request({
            request: { token: accessToken },
            navigate,
          }),
        );
      }
    }
  }, [dispatch, navigate, callbackState, accessToken]);

  const { bankIDVerificationStatus } = useSelector(
    (e: RootStateType) => e.verification,
  );

  const isValidationError =
    bankIDVerificationStatus ===
      ProcessBankIDVerificationCommandResultStatus.BankIDUserInfoError ||
    bankIDVerificationStatus ===
      ProcessBankIDVerificationCommandResultStatus.VerificationAlreadyExists;

  const { translateError } = useServerError(
    Resources.BankIDVerification.Error,
    Resources.BankIDVerification.Error.General,
  );
  const isError = !!bankIDError || isValidationError;

  return (
    <AuthPageLayout title={"Přesměrování"} isLoading={false} pageType="other">
      <StyledContainer>
        {!isError && <AuthRedirectProgress />}

        {isError && (
          <div>
            <Typography variant="h3">Chyba</Typography>
            <Typography>{translateError(bankIDVerificationStatus)}</Typography>
            <br />
          </div>
        )}
      </StyledContainer>
    </AuthPageLayout>
  );
};
export default BankIDRedirectPage;
