import * as React from "react";
export const HomeIcon = (props: React.SVGProps<SVGSVGElement>) => {
  props = {
    ...props,
    color: props.color,
  };
  return (
    <svg
      width={17}
      height={16}
      viewBox="0 0 17 16"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M8.68372 1.33325C8.57711 1.33689 8.47445 1.37453 8.39075 1.44067L3.65312 5.17309C3.05182 5.64697 2.7 6.37109 2.7 7.13664V13.4999C2.7 13.9544 3.0788 14.3332 3.53333 14.3332H6.86666C7.3212 14.3332 7.7 13.9544 7.7 13.4999V10.1666C7.7 10.0682 7.76832 9.99992 7.86666 9.99992H9.53333C9.63168 9.99992 9.7 10.0682 9.7 10.1666V13.4999C9.7 13.9544 10.0788 14.3332 10.5333 14.3332H13.8667C14.3212 14.3332 14.7 13.9544 14.7 13.4999V7.13664C14.7 6.37109 14.3482 5.64697 13.7469 5.17309L9.00924 1.44067C8.91678 1.36763 8.8015 1.32959 8.68372 1.33325ZM8.7 2.46997L13.1284 5.9589C13.4897 6.24369 13.7 6.67685 13.7 7.13664V13.3332H10.7V10.1666C10.7 9.52826 10.1716 8.99992 9.53333 8.99992H7.86666C7.22835 8.99992 6.7 9.52826 6.7 10.1666V13.3332H3.7V7.13664C3.7 6.67685 3.91024 6.24369 4.27161 5.9589L8.7 2.46997Z" />
    </svg>
  );
};
