import { CreateInvoiceCommandResult, CreateInvoiceRequest } from "Api/Api";
import { NavigateFunction } from "react-router-dom";
import { createAsyncAction } from "typesafe-actions";

import { put, takeLatest } from "redux-saga/effects";
import {
  CreateInvoiceCommandResultStatus,
  postInvoiceCreateInvoice,
} from "Api/Api";
import { getType } from "typesafe-actions";
import { call } from "typed-redux-saga";
import { getAppUrl } from "Utils/UrlUtils";
import { toast } from "sonner";

export const createInvoiceAsync = createAsyncAction(
  "@invoice/CREATE_REQUEST",
  "@invoice/CREATE_SUCCESS",
  "@invoice/CREATE_FAILURE",
)<
  { request: CreateInvoiceRequest; navigate: NavigateFunction },
  CreateInvoiceCommandResult,
  Error
>();

function* createInvoice(
  action: ReturnType<typeof createInvoiceAsync.request>,
): Generator {
  try {
    const response = yield* call(
      postInvoiceCreateInvoice,
      action.payload.request,
    );
    if (
      response.status === 200 &&
      response.json.status === CreateInvoiceCommandResultStatus.Success
    ) {
      yield put(createInvoiceAsync.success(response.json));
      toast.success("Invoice created successfully.");
      const url = getAppUrl();
      yield* call(() => action.payload.navigate(url("dashboard")));
    } else {
      yield put(
        createInvoiceAsync.failure(
          new Error(response.json.status, {
            cause: response.status,
          }),
        ),
      );
    }
  } catch (err) {
    yield put(createInvoiceAsync.failure(err as Error));
  }
}
export function* createInvoiceSaga() {
  yield takeLatest(getType(createInvoiceAsync.request), createInvoice);
}
