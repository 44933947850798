import { Box } from "@mui/material";
import { useBankingAccountsSync } from "Api/Mutations/Accounts/useBankingAccountsSync";
import { BalanceComponent } from "Components/Balance/BalanceComponent";
import { DashboardPopup } from "Components/Dashboard/DashboardPopup";
import { TransactionsComponent } from "Components/Dashboard/TransactionsComponent";
import { AuthenticatedLayout } from "Components/Layout/AuthenticatedLayout";
import { StyledPageWrapper } from "Components/Shared/StyledComponents";
import { SignUpSubsidyPopup } from "Components/Voucher/SignUpSubsidyPopup";
import { subDays } from "date-fns";
import { CurrencyCode } from "Models/CurrencyCodes";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getRatesHistoryAsync } from "State/Rates/GerRatesHistoryState";
import { resetTransactionListPagination } from "State/Transactions/TransactionReducer";

export const DASHBOARD_TRANSACTION_LIMIT = 2;

export const DashboardPage: React.FunctionComponent<
  React.PropsWithChildren<unknown>
> = _ => {
  const { mutate: syncAccounts } = useBankingAccountsSync();
  const dispatch = useDispatch();
  React.useEffect(() => {
    return () => {
      dispatch(resetTransactionListPagination());
    };
  }, [dispatch]);

  React.useEffect(() => {
    dispatch(
      getRatesHistoryAsync.request({
        askCurrency: CurrencyCode.CZK,
        bidCurrency: CurrencyCode.BTC,
        startDate: subDays(new Date(), 3),
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    syncAccounts();
  }, [syncAccounts]);

  return (
    <AuthenticatedLayout title="Přehled" hideTitleOnMobile>
      <StyledPageWrapper>
        <Box className="left-content">
          <BalanceComponent />

          {/* <BitcoinTicker
            fiatPrice={btcRate?.askRate}
            fiatCurrency={CurrencyCode.CZK}
            rateHistory={rateHistory}
          /> */}

          <DashboardPopup />

          {/* <FeaturedList /> */}

          <TransactionsComponent />

          <SignUpSubsidyPopup />
        </Box>
      </StyledPageWrapper>
    </AuthenticatedLayout>
  );
};

export default DashboardPage;
