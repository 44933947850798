import {
  postAuthSetPassword,
  SetPasswordCommand,
  SetPasswordCommandResult,
} from "Infrastructure/Api/Api";
import { SetPasswordStep } from "State/Auth/Models/AuthStateModels";
import { signInAsync } from "State/Auth/SignIn/SignInState";
import { call, put, takeLatest } from "typed-redux-saga";
import { createAction, createAsyncAction, getType } from "typesafe-actions";
import { setToken } from "Utils/LocalStorageUtils";
import { trackUserIdentification } from "Utils/TrackingUtils";

export const setPasswordAsync = createAsyncAction(
  "@auth/SET_PASSWORD_REQUEST",
  "@auth/SET_PASSWORD_SUCCESS",
  "@auth/SET_PASSWORD_FAILURE",
)<SetPasswordCommand, SetPasswordCommandResult, Error>();

export const setPasswordSetStep = createAction(
  "@auth/SET_SET_PASSWORD_STEP",
)<SetPasswordStep>();

function* setPassword(
  action: ReturnType<typeof setPasswordAsync.request>,
): Generator {
  try {
    const response = yield* call(postAuthSetPassword, action.payload);
    if (response.status === 200) {
      if (!!response.data.signInResult) {
        yield put(setPasswordAsync.success(response.data));
        setToken(response.data.signInResult);
        trackUserIdentification();

        yield put(signInAsync.success(response.data.signInResult));
      } else {
        yield put(setPasswordAsync.failure(new Error(response.data.status)));
      }
    } else {
      yield put(
        setPasswordAsync.failure(
          new Error(`Error in action ${action.type}`, {
            cause: response.status,
          }),
        ),
      );
    }
  } catch (err) {
    yield put(setPasswordAsync.failure(err as Error));
  }
}
export function* setPasswordSaga() {
  yield takeLatest(getType(setPasswordAsync.request), setPassword);
}
