import {
  GetTransactionDetailQueryResult,
  getTransactionDetailExternalID,
} from "Api/Api";
import { createAction, createAsyncAction } from "typesafe-actions";
import { put, takeLatest } from "redux-saga/effects";
import { getType } from "typesafe-actions";
import { call } from "typed-redux-saga";

export const resetTransactionDetail = createAction(
  "@transaction/RESET_DETAIL",
)();

export const getTransactionDetailAsync = createAsyncAction(
  "@transaction/GET_DETAIL_REQUEST",
  "@transaction/GET_DETAIL_SUCCESS",
  "@transaction/GET_DETAIL_FAILURE",
)<string, GetTransactionDetailQueryResult | null, Error>();

function* getTransactionDetail(
  action: ReturnType<typeof getTransactionDetailAsync.request>,
): Generator {
  try {
    const response = yield* call(
      getTransactionDetailExternalID,
      action.payload,
    );
    if (response.status === 200) {
      yield put(getTransactionDetailAsync.success(response.json));
    } else {
      yield put(
        getTransactionDetailAsync.failure(
          new Error(`Error in action ${action.type}`, {
            cause: response.status,
          }),
        ),
      );
    }
  } catch (err) {
    yield put(getTransactionDetailAsync.failure(err as Error));
  }
}
export function* getTransactionDetailSaga() {
  yield takeLatest(
    getType(getTransactionDetailAsync.request),
    getTransactionDetail,
  );
}
