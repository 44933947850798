import { API_ROUTES } from "Api/Api";
import { createAsyncAction } from "typesafe-actions";
import { put, takeLatest } from "redux-saga/effects";
import { getType } from "typesafe-actions";
import FileSaver from "file-saver";
import { downloadEmployeeGuideAsync } from "State/Invoices/DownloadEmployerGuideline/DownloadEmployerGuidelineState";
import { downloadFile } from "Utils/FileUtils";
import { format } from "date-fns";

export const downloadTransactionCsvAsync = createAsyncAction(
  "@transaction/DOWNLOAD_TRANSACTION_CSV_REQUEST",
  "@transaction/DOWNLOAD_TRANSACTION_CSV_SUCCESS",
  "@transaction/DOWNLOAD_TRANSACTION_CSV_FAILURE",
)<void, void, Error>();

function* downloadTransactionCsv(
  action: ReturnType<typeof downloadTransactionCsvAsync.request>,
): Generator {
  try {
    const route = `${import.meta.env.VITE_API_URL}${
      API_ROUTES.getTransactionCsvExport
    }`;
    const response = yield* downloadFile(route, "GET");

    if (response.clone.status === 200) {
      response.clone.blob().then(blob => {
        FileSaver.saveAs(
          blob,
          `Historie_${format(new Date(), "yyyy-MM-dd_HH:mm")}.csv`,
        );
      });
      yield put(downloadEmployeeGuideAsync.success());
    } else {
      yield put(
        downloadEmployeeGuideAsync.failure(
          new Error(`Error in action ${action.type}`, {
            cause: response.clone.status,
          }),
        ),
      );
    }
  } catch (err) {
    yield put(downloadTransactionCsvAsync.failure(err as Error));
  }
}
export function* downloadTransactionCsvSaga() {
  yield takeLatest(
    getType(downloadTransactionCsvAsync.request),
    downloadTransactionCsv,
  );
}
