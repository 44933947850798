import {
  InfiniteData,
  QueryKey,
  useInfiniteQuery,
} from "@tanstack/react-query";
import {
  EntityListOfTransactionItem,
  getTransactions,
} from "Infrastructure/Api/Api";
import { processResponse } from "Infrastructure/Api/ApiUtils";

type Props = {
  limit?: number;
  nextPageLimit?: number;
};

type PageParams = {
  offset: number;
  limit: number;
};

export const useTransactions = ({ limit, nextPageLimit = 50 }: Props = {}) =>
  useInfiniteQuery<
    EntityListOfTransactionItem,
    unknown,
    InfiniteData<EntityListOfTransactionItem>,
    QueryKey,
    PageParams
  >({
    queryKey: ["TRANSACTIONS", { limit }],
    queryFn: processResponse(({ pageParam: { limit, offset } }) =>
      getTransactions(offset, limit),
    ),
    initialPageParam: { offset: 0, limit: limit ?? 10 },
    getNextPageParam: lastPage => {
      if (lastPage.offset + lastPage.limit >= lastPage.totalCount) {
        return undefined;
      }

      return { offset: lastPage.offset + lastPage.limit, limit: nextPageLimit };
    },
  });
