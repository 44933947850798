import { useQuery } from "@tanstack/react-query";
import { getBankingAccounts } from "Infrastructure/Api/Api";
import { processResponse } from "Infrastructure/Api/ApiUtils";

export const BANKING_ACCOUNTS_QUERY_KEY = ["BANKING_ACCOUNTS"];

export const useBankingAccounts = () =>
  useQuery({
    queryKey: BANKING_ACCOUNTS_QUERY_KEY,
    queryFn: processResponse(() => getBankingAccounts()),
  });
