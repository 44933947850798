import { SignInResult } from "Api/Api";
import { isAfter, parseJSON } from "date-fns";
import { setUnauthenticatedUrl } from "State/Auth/AuthReducer";
import { AppUser } from "State/Auth/Models/AuthStateModels";
import { getCodeListCollectionAsync } from "State/CodeLists/CodeListReducer";
import { RootStateType } from "State/Store";
import { setToken } from "Utils/LocalStorageUtils";
import { getAppUrl, resolveStartPage } from "Utils/UrlUtils";
import { put, select } from "typed-redux-saga";
import { NavigateFunction } from "react-router";
import { trackUserIdentification } from "Utils/TrackingUtils";
import { captureException } from "@sentry/react";

export const JWT_KEY = "jwtToken";

export function isAuthenticated(user: AppUser | null) {
  if (user === null) {
    return false;
  }

  return isAfter(parseJSON(user.tokenExpiration), new Date());
}

export function* afterSignInSuccess(
  response: SignInResult,
  navigate?: NavigateFunction,
) {
  const appUrl = getAppUrl();
  setToken(response);
  yield put(getCodeListCollectionAsync.request());
  trackUserIdentification();

  const { user, unauthenticatedUrl } = yield* select(
    (state: RootStateType) => state.auth,
  );
  yield* put(setUnauthenticatedUrl(null));

  try {
    const startUrl = resolveStartPage(appUrl, user, unauthenticatedUrl);

    if (navigate) {
      const url = new URL(
        `${window.location.protocol}//${window.location.host}${startUrl}`,
      );

      navigate(
        {
          pathname: url.pathname,
          search: url.search,
        },
        {
          replace: true,
          state: null,
          relative: "path",
          preventScrollReset: true,
        },
      );
    }
  } catch (e) {
    captureException(e);
    
    if (navigate) {
      var url = appUrl("sign-in");

      navigate(
        {
          pathname: url,
        },
        {
          replace: true,
          state: null,
          relative: "path",
          preventScrollReset: true,
        },
      );
    }
  }
}
