import { setTag, setUser, withScope } from "@sentry/react";
import { JWT_KEY } from "Auth/Models/AuthConstants";
import { logError } from "./LoggingUtils";

const isSentryEnabled = import.meta.env.VITE_SENTRY_IS_ENABLED === "true";

export function trackUserIdentification() {
  setSentryIdentification();
}

type JwtToken = {
  nameid: string;
  unique_name: string;
  email: string;
  nbf: number;
  exp: number;
  iat: number;
  aud: string;
};

function decodeJWT(token: string): JwtToken {
  return JSON.parse(window.atob(token.split(".")[1]));
}

function setSentryIdentification() {
  if (!isSentryEnabled) {
    return;
  }

  try {
    const user = parseSentryUserFromJWT();

    if (!!user) {
      setUser(user);
      setTag("user.login", user.username);

      withScope(scope => {
        scope.setUser(user);
        scope.setTag("user.login", user.username);
      });
    }
  } catch (error) {
    logError(error as Error);
  }
}

export function parseSentryUserFromJWT() {
  const token = localStorage.getItem(JWT_KEY);

  if (!token) {
    return;
  }

  const login = decodeJWT(token).unique_name;

  const user = {
    id: login,
    email: login,
    username: login,
  };

  return user;
}
