import { Typography } from "@mui/material";

export const NeedsInvestigationStep: React.FunctionComponent<
  React.PropsWithChildren<unknown>
> = _ => {
  return (
    <>
      <br />
      <Typography>
        Děkujeme za vyplnení dotazníku, budeme vás kontaktovat.
      </Typography>
    </>
  );
};
