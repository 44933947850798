import { BottomNavigation } from "@mui/material";
import { BottomMenuItem } from "Components/Layout/Menu/BottomMenuItem";
import { useBottomMenuLinks } from "Hooks/Layout/useBottomMenuLinks";
import { CSSProperties, FC } from "react";
import { useLocation, useNavigate } from "react-router";

export const BOTTOM_MENU_HEIGHT = "61px";

const BottomNavigationStyles: CSSProperties = {
  paddingTop: 1,
  paddingBottom: 4,
  boxShadow: "0px -1px 4px rgba(0, 0, 0, 0.05)",
  zIndex: 10_000,
  height: BOTTOM_MENU_HEIGHT,
  position: "fixed",
  width: "100%",
  bottom: 0,
};

export const BottomMenu: FC = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { menuItems } = useBottomMenuLinks();

  return (
    <BottomNavigation
      value={pathname}
      onChange={(_, value) => navigate(value)}
      sx={BottomNavigationStyles}
      showLabels
    >
      {menuItems.map((item, index) => (
        <BottomMenuItem key={index} {...item} />
      ))}
    </BottomNavigation>
  );
};
