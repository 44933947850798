import { yupResolver } from "@hookform/resolvers/yup";
import { Typography } from "@mui/material";
import { SourceOfIncome } from "Api/Api";
import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";
import { CheckboxInput } from "Components/Shared/Inputs/Form/CheckboxInput";
import { Dropdown } from "Components/Shared/Inputs/Form/Dropdown";
import { CodeListItem } from "Models/ICodeListDto";
import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { RootStateType } from "State/Store";
import { setAdditionalInfoAsync } from "State/Verification/AdditionalInfo/SetAdditionalInfoState";
import styled from "styled-components";
import { object, ObjectSchema, boolean, mixed } from "yup";

type FormModel = {
  hasNotMoneyFromCriminalActivityOrIsFinancingTerrorism: boolean;
  hasNotConnectionToSanctionedCountry: boolean;
  isNotPoliticallyExposedPerson: boolean;
  sourceOfIncome: SourceOfIncome;
};

const Trivia = styled(Typography)`
  color: ${props => props.theme.palette.text.secondary};
`;

const StyledSubmitButton = styled(PrimaryButton)`
  margin: ${props => props.theme.spacing(1, 0)};
`;

const sourceOfIncome: CodeListItem[] = [
  { code: SourceOfIncome.WageOrSalary, name: "Mzda, plat" },
  {
    code: SourceOfIncome.BusinessIncome,
    name: "Příjmy z podnikání právnické osoby / OSVČ",
  },
  {
    code: SourceOfIncome.AlimonyOrWelfareOrPension,
    name: "Výživné, dávky, důchod",
  },
  {
    code: SourceOfIncome.CapitalGains,
    name: "Příjmy z kapitálu (dividendy, pronájmy a jiné)",
  },
];

export const AdditionalVerificationStep: React.FunctionComponent<
  React.PropsWithChildren<unknown>
> = _ => {
  const validationSchema: ObjectSchema<FormModel> = object({
    hasNotMoneyFromCriminalActivityOrIsFinancingTerrorism: boolean().required(),
    hasNotConnectionToSanctionedCountry: boolean().required(),
    isNotPoliticallyExposedPerson: boolean().required(),
    sourceOfIncome: mixed<SourceOfIncome>()
      .oneOf(Object.values(SourceOfIncome))
      .defined("Vyplňte původ financí"),
  }).defined();

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<FormModel>({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
    defaultValues: {
      hasNotMoneyFromCriminalActivityOrIsFinancingTerrorism: false,
      hasNotConnectionToSanctionedCountry: false,
      isNotPoliticallyExposedPerson: false,
    },
  });

  const dispatch = useDispatch();

  const { isLoading } = useSelector((e: RootStateType) => e.verification);

  const onSubmit = ({
    hasNotMoneyFromCriminalActivityOrIsFinancingTerrorism,
    hasNotConnectionToSanctionedCountry,
    isNotPoliticallyExposedPerson,
    sourceOfIncome,
  }: FormModel) => {
    dispatch(
      setAdditionalInfoAsync.request({
        hasMoneyFromCriminalActivityOrIsFinancingTerrorism:
          !hasNotMoneyFromCriminalActivityOrIsFinancingTerrorism,
        hasConnectionToSanctionedCountry: !hasNotConnectionToSanctionedCountry,
        isPoliticallyExposedPerson: !isNotPoliticallyExposedPerson,
        sourceOfIncome,
      }),
    );
  };

  return (
    <>
      <br />
      <Typography>
        Abychom naplnili zákonné povinnosti a umožnili Vám obchodovat ve větším
        objemu, musíme provést ověření Vaší totožnosti.
      </Typography>
      <br />
      <Typography>Nejdříve o Vás potřebujeme vědět pár informací:</Typography>
      <br />
      <form onSubmit={handleSubmit(data => onSubmit(data))}>
        <Dropdown
          control={control}
          name="sourceOfIncome"
          errors={errors}
          label="Původ financí"
          codeList={sourceOfIncome}
          placeholder="Vyberte z možností"
        />
        <br />

        <CheckboxInput
          control={control}
          name="hasNotMoneyFromCriminalActivityOrIsFinancingTerrorism"
          errors={errors}
          label={
            <Trivia>
              Potvrzuji, že mé finanční prostředky nepocházejí z trestné
              činnosti a neslouží k financování terorismu a byly nabyty v
              souladu s právními předpisy
            </Trivia>
          }
        />
        <br />
        <CheckboxInput
          control={control}
          name="hasNotConnectionToSanctionedCountry"
          errors={errors}
          label={
            <Trivia>
              Potvrzuji, že nemám obchodní či jiné aktivity v zemích či se
              subjekty ze zemí, vůči nimž Česká republika uplatňuje mezinárodní
              sankce podle zákona o provádění mezinárodních sankcích
            </Trivia>
          }
        />
        <br />
        <CheckboxInput
          control={control}
          name="isNotPoliticallyExposedPerson"
          errors={errors}
          label={
            <Trivia>Potvrzuji, že nejsem politicky exponovanou osobou</Trivia>
          }
        />
        <br />
        <br />
        <br />
        <StyledSubmitButton
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          isLoading={isLoading}
        >
          {"Další krok"}
        </StyledSubmitButton>
      </form>
    </>
  );
};
