import { Box, Skeleton } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useTransactionsDetail } from "Api/Queries/Transactions/useTransactionsDetail";
import { useTransactionsStatus } from "Api/Queries/Transactions/useTransactionsStatus";
import { PaymentStatus } from "Components/Merchant/InitiatePaymentDetail/PaymentStatus";
import { PrimaryPaper } from "Components/Shared/PrimaryPaper";
import { CurrencyCode } from "Models/CurrencyCodes";
import { FC, PropsWithChildren } from "react";
import styled from "styled-components";
import { Resources, useResource } from "Translations/Resources";
import { formatCurrency } from "Utils/CurrencyUtils";

type Props = {
  publicID: string;
};

const RowFactory = (isCZK: boolean) => styled.div`
  display: grid;
  grid-template-columns: ${isCZK ? "0.8fr 1fr" : "2fr 4fr"};
  margin-top: 10px;
  margin-bottom: 10px;
`;

const Left = styled(Typography)`
  font-size: 16px;
`;

const Right = styled(Typography)`
  font-size: 18px;
  font-weight: 500;
  justify-self: flex-end;
  word-break: break-word;
  text-align: end;
`;

const ComponentResources = Resources.Merchant.InitiateDetail;

const CzRow = RowFactory(/*currencyCode === CurrencyCode.CZK*/ true);

const LoadingRowWrapper: FC<PropsWithChildren<{ isLoading?: boolean }>> = ({
  children,
  isLoading,
}) => {
  if (isLoading) {
    return (
      <CzRow>
        <Left>
          <Skeleton width="125px" />
        </Left>
        <Right>
          <Skeleton width="60px" />
        </Right>
      </CzRow>
    );
  }

  return <>{children}</>;
};

// TODO: We can use TransactionForm component - if we display the data from this component
export const TransactionDetail: FC<Props> = ({ publicID }) => {
  const { t } = useResource();
  const { data: transactionsDetail, isLoading: isLoadingTransactionsDetail } =
    useTransactionsDetail(publicID);
  const { data: status, isLoading: isLoadingStatus } =
    useTransactionsStatus(publicID);

  const Row = RowFactory(/*currencyCode === CurrencyCode.CZK*/ true);

  const transactionStatus =
    status?.transactionStatus ?? transactionsDetail?.transactionStatus;
  const paymentInstructionStatus =
    status?.paymentInstructionStatus ??
    transactionsDetail?.paymentInstructionStatus;

  return (
    <PrimaryPaper>
      <LoadingRowWrapper isLoading={isLoadingTransactionsDetail}>
        {!!transactionsDetail?.creditorIBAN && (
          <Row>
            <Left>{"IBAN"}</Left>
            <Right>{transactionsDetail?.creditorIBAN}</Right>
          </Row>
        )}
      </LoadingRowWrapper>

      <LoadingRowWrapper isLoading={isLoadingTransactionsDetail}>
        {!!transactionsDetail?.paymentReference && (
          <Row>
            <Left>{t(Resources.Common.VariableSymbol)}</Left>
            <Right>{transactionsDetail.paymentReference}</Right>
          </Row>
        )}
      </LoadingRowWrapper>

      <LoadingRowWrapper isLoading={isLoadingTransactionsDetail}>
        {!!transactionsDetail?.amount && (
          <Row>
            <Left>{t(Resources.Common.Amount)}</Left>
            <Right>
              {formatCurrency(transactionsDetail?.amount, CurrencyCode.CZK)}
            </Right>
          </Row>
        )}
      </LoadingRowWrapper>

      {!!transactionsDetail?.noteForDebtor && (
        <Row>
          <Left>{t(Resources.Common.Note)}</Left>
          <Right>{transactionsDetail?.noteForDebtor}</Right>
        </Row>
      )}

      <LoadingRowWrapper isLoading={isLoadingTransactionsDetail}>
        <Row>
          <Left>{t(ComponentResources.TransactionPaymentCode)}</Left>
          <Right>{transactionsDetail?.publicID}</Right>
        </Row>
      </LoadingRowWrapper>

      <Box marginTop={10} textAlign="center">
        <PaymentStatus
          transactionStatus={transactionStatus}
          paymentInstructionStatus={paymentInstructionStatus ?? undefined}
          isLoading={
            isLoadingStatus && !transactionStatus && !paymentInstructionStatus
          }
        />
      </Box>
    </PrimaryPaper>
  );
};
