import {
  InvoiceSettingsDto,
  postInvoiceGetInvoiceSettings,
  postInvoiceSaveInvoiceSettings,
} from "Api/Api";
import { toast } from "sonner";
import { call, put, takeLatest } from "typed-redux-saga";
import { createAsyncAction, getType } from "typesafe-actions";

export const getInvoiceSettingsAsync = createAsyncAction(
  "@invoice/GET_SETTINGS_REQUEST",
  "@invoice/GET_SETTINGS_SUCCESS",
  "@invoice/GET_SETTINGS_FAILURE",
)<void, InvoiceSettingsDto | null, Error>();

export const saveInvoiceSettingsAsync = createAsyncAction(
  "@invoice/SAVE_SETTINGS_REQUEST",
  "@invoice/SAVE_SETTINGS_SUCCESS",
  "@invoice/SAVE_SETTINGS_FAILURE",
)<InvoiceSettingsDto, void, Error>();

function* getInvoiceSettings(
  action: ReturnType<typeof getInvoiceSettingsAsync.request>,
): Generator {
  try {
    const response = yield* call(postInvoiceGetInvoiceSettings);
    if (response.status === 200) {
      yield put(getInvoiceSettingsAsync.success(response.json));
    } else {
      yield put(
        getInvoiceSettingsAsync.failure(
          new Error(`Error in action ${action.type}`, {
            cause: response.status,
          }),
        ),
      );
    }
  } catch (err) {
    yield put(getInvoiceSettingsAsync.failure(err as Error));
  }
}
export function* getInvoiceSettingsSaga() {
  yield takeLatest(
    getType(getInvoiceSettingsAsync.request),
    getInvoiceSettings,
  );
}

function* saveInvoiceSettings(
  action: ReturnType<typeof saveInvoiceSettingsAsync.request>,
): Generator {
  try {
    const response = yield* call(
      postInvoiceSaveInvoiceSettings,
      action.payload,
    );
    if (response.status === 200) {
      yield put(saveInvoiceSettingsAsync.success());
      toast.success("Nastavení bylo uloženo.");
    } else {
      yield put(
        saveInvoiceSettingsAsync.failure(
          new Error(`Error in action ${action.type}`, {
            cause: response.status,
          }),
        ),
      );
    }
  } catch (err) {
    yield put(saveInvoiceSettingsAsync.failure(err as Error));
  }
}
export function* saveInvoiceSettingsSaga() {
  yield takeLatest(
    getType(saveInvoiceSettingsAsync.request),
    saveInvoiceSettings,
  );
}
