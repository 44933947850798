import { FC } from "react";
import { CurrencyCode } from "Models/CurrencyCodes";
import { useCurrencySymbol } from "Hooks/useCurrencySymbol";
import { Box, Skeleton, styled, Typography } from "@mui/material";

type Props = {
  balance: number;
  currency?: CurrencyCode;
  prefix?: string;
  isLoading?: boolean;
  isBigCurrency?: boolean;
};

const Currency = styled(Typography)`
  display: flex;
  flex-direction: column;
  justify-content: end;
  margin-bottom: 5px;
`;

export const Balance: FC<Props> = ({
  balance,
  currency,
  prefix,
  isLoading,
  isBigCurrency,
}) => {
  const currencySymbol = useCurrencySymbol(currency || CurrencyCode.CZK);

  const formattedValue =
    balance === 0
      ? "0"
      : `${prefix || ""} ${new Intl.NumberFormat("cs-CZ", {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        }).format(balance ?? 0)}`;

  return (
    <Box display="flex" gap={2}>
      <Typography variant="h2">
        {isLoading ? <Skeleton width={140} /> : formattedValue}
      </Typography>
      {!isLoading && (
        <Currency variant={isBigCurrency ? "h2" : "body2"}>
          {currencySymbol}
        </Currency>
      )}
    </Box>
  );
};
