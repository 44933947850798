import { GetUserListQuery } from "Api/Api";
import { AuthenticatedLayout } from "Components/Layout/AuthenticatedLayout";
import { StyledPageWrapper } from "Components/Shared/StyledComponents";
import { UserListTable } from "Components/Users/UserListTable";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootStateType } from "State/Store";
import { getUserListAsync } from "State/Users/UserList/UserListState";

const ITEMS_PER_PAGE = 25;

export const UserListPage: React.FunctionComponent<
  React.PropsWithChildren<unknown>
> = _ => {
  const dispatch = useDispatch();

  const [filter, setFilter] = React.useState<GetUserListQuery>({
    limit: ITEMS_PER_PAGE,
    offset: 0,
  });

  const handlePageChange = React.useCallback(
    (newPage: number, newRows: number) => {
      setFilter({
        ...filter,
        ...{
          limit: newRows,
          offset: newPage * newRows,
        },
      });
    },
    [filter],
  );

  React.useEffect(() => {
    dispatch(getUserListAsync.request(filter));
  }, [dispatch, filter]);

  const { userList, isUserListLoading: isUserListLeading } = useSelector(
    (e: RootStateType) => e.user,
  );

  return (
    <AuthenticatedLayout title="Uživatelé">
      <StyledPageWrapper style={{ maxWidth: "750px" }}>
        <div className="left-content" style={{ overflowX: "scroll" }}>
          <UserListTable
            isLoading={isUserListLeading}
            list={userList}
            rowsPerPage={ITEMS_PER_PAGE}
            onPageChange={handlePageChange}
          ></UserListTable>
        </div>
      </StyledPageWrapper>
    </AuthenticatedLayout>
  );
};

export default UserListPage;
