import { Grid, Typography } from "@mui/material";
import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";
import { PlusMathIcon } from "Components/Shared/Icons";
import { useNavigate } from "react-router";
import { Resources, useResource } from "Translations/Resources";
import { appUrl } from "Utils/UrlUtils";

const ComponentResources = Resources.Merchant.Payment;

type Props = {
  publicID?: string;
};

export const PaymentActions: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = ({ publicID }) => {
  const { t } = useResource();
  const navigate = useNavigate();

  return (
    <Grid container spacing={4} marginTop={2}>
      <Grid item xs={12}>
        <PrimaryButton
          type="button"
          color="primary"
          fullWidth
          startIcon={<PlusMathIcon width={20} height={20} />}
          onClick={() =>
            navigate(`${appUrl("initiate-payment")}?publicID=${publicID}`)
          }
        >
          <Typography fontWeight={600} fontSize={14}>
            {t(ComponentResources.InitiatePaymentButton)}
          </Typography>
        </PrimaryButton>
      </Grid>
    </Grid>
  );
};
