import { useQuery } from "@tanstack/react-query";
import {
  getTransactionsStatus,
  PaymentInstructionStatus,
  TransactionStatusResponse,
} from "Infrastructure/Api/Api";
import { processResponse } from "Infrastructure/Api/ApiUtils";

export const useTransactionsStatus = (publicID: string) =>
  useQuery<TransactionStatusResponse>({
    queryKey: ["TRANSACTIONS_STATUS", publicID],
    queryFn: processResponse(() => getTransactionsStatus(publicID)),
    refetchInterval: data => {
      if (
        data?.state?.data?.paymentInstructionStatus !==
        PaymentInstructionStatus.AcceptedSettlementCompleted
      ) {
        return 5_000;
      }

      return false;
    },
  });
