import { UnstyledLink } from "Components/Routes/UnstyledLink";
import styled from "styled-components";
import { getAppUrl } from "Utils/UrlUtils";

import moone from "Assets/Images/Images/moone.png";

const StyledWrapper = styled.div`
  display: flex;
  padding-bottom: ${props => props.theme.spacing(2)};
  padding-top: ${props => props.theme.spacing(3)};
`;

export const UnauthenticatedHeader: React.FunctionComponent<
  React.PropsWithChildren<unknown>
> = _ => {
  const appUrl = getAppUrl();

  return (
    <UnstyledLink to={appUrl("sign-in")}>
      <StyledWrapper>
        <img src={moone} alt="Moone" width="143" />
      </StyledWrapper>
    </UnstyledLink>
  );
};
