import {
  AdditionalInfoStatus,
  BankVerificationStatus,
  IdentityVerificationStatus,
  RiskProfileStatus,
  UserVerificationDetailDto,
} from "Api/Api";

export function isNotFullyVerified(detail: UserVerificationDetailDto | null) {
  return (
    !!detail &&
    (detail.additionalInfoStatus === AdditionalInfoStatus.NotVerified ||
      detail.riskProfile === RiskProfileStatus.NotVerified ||
      detail.identityVerificationStatus ===
        IdentityVerificationStatus.NotVerified ||
      detail.bankVerificationStatus === BankVerificationStatus.NotVerified)
  );
}
