import { call } from "typed-redux-saga";
import { JWT_KEY } from "Utils/AuthUtils";

export function* downloadFile(route: string, method: "POST" | "GET") {
  return yield* call(() =>
    fetch(route, {
      method: method,
      mode: "cors",
      headers: {
        Authorization: localStorage.getItem(JWT_KEY) as string,
      },
    }).then(e => {
      const fileName = getFileName(e);
      const fileLength = Number(e.headers.get("Content-Length") ?? 0);

      return { fileName, fileLength, stream: e, clone: e.clone() };
    }),
  );
}

function getFileName(response: Response) {
  const header = response.headers.get("Content-Disposition") || "";

  const utfHeader = "filename*=UTF-8''";
  const utfName = header
    .split("; ")
    .filter(e => e.startsWith("filename"))
    .find(e => e.startsWith(utfHeader));

  if (!!utfName) {
    return decodeURIComponent(utfName.replace(utfHeader, ""));
  }

  const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
  const matches = filenameRegex.exec(header || "");
  let fileName = "";
  if (matches != null && matches[1]) {
    fileName = matches[1].replace(/['"]/g, "");
  }

  return fileName;
}
