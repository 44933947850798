import { InfoIcon } from "Components/Shared/Icons";
import { PrimaryTooltip } from "Components/Shared/PrimaryTooltip";
import { StyledFlex } from "Components/Shared/StyledComponents";
import * as React from "react";
import styled from "styled-components";

type Props = { formInfo?: { [key: string]: string }; name: string };

const StyledIcon = styled(StyledFlex)`
  margin-left: ${props => props.theme.spacing(2)};
  svg {
    width: 16px;
    height: auto;
  }
`;

const FormInfoTooltip: React.FunctionComponent<
  React.PropsWithChildren<{ html: string }>
> = props => (
  <>
    <PrimaryTooltip
      placement={"right-end"}
      title={
        <div
          dangerouslySetInnerHTML={{
            __html: `${props.html}`,
          }}
        />
      }
    >
      <StyledIcon alignItems={"center"} justifyContent={"center"}>
        <InfoIcon></InfoIcon>
      </StyledIcon>
    </PrimaryTooltip>
  </>
);

const MemoizedTooltip = React.memo(FormInfoTooltip);

export const FormInfo: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = props => {
  const { formInfo, name } = props;
  const n = name.split("].").reverse()[0];
  return (
    <>{formInfo && formInfo[n] && <MemoizedTooltip html={formInfo[n]} />}</>
  );
};
