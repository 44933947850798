import * as React from "react";
export const MultiplyIcon = (props: React.SVGProps<SVGSVGElement>) => {
  props = {
    ...props,
    color: props.color,
  };
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24.094}
      height={24}
      viewBox="0 0 24.094 24"
      {...props}
    >
      <path
        d="M14.406,13,13,14.406,23.625,25,13,35.594,14.406,37,25.063,26.406,35.688,37l1.406-1.406L26.469,25,37.094,14.406,35.688,13,25.063,23.594Z"
        transform="translate(-13 -13)"
        fill="currentColor"
      />
    </svg>
  );
};
