import * as React from "react";
export const AskQuestionIcon = (props: React.SVGProps<SVGSVGElement>) => {
  props = {
    ...props,
    color: props.color,
  };
  return (
    <svg
      width={50}
      height={50}
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M25 4C12.3281 4 2 12.8789 2 23.9922C2 30.4453 5.53125 36.0625 10.9609 39.7305C10.9531 39.9453 10.9687 40.2969 10.6836 41.3438C10.3359 42.6445 9.625 44.4727 8.1875 46.5352L7.16406 48H8.94922C15.1406 48 18.7227 43.9648 19.2773 43.3164C21.1211 43.75 23.0195 43.9883 25 43.9883C37.6719 43.9883 48 35.1094 48 23.9922C48 12.8789 37.6719 4 25 4ZM26 34.1992H23.3008V31.3008H26V34.1992ZM30.5 21.6016C30.1016 22.1992 29.3008 23.1016 28 24.3008C27.1016 25.1992 26.5 25.8984 26.1992 26.5C25.8984 27 25.8008 27.8008 25.8008 29H23.3008C23.3008 27.6992 23.5 26.6016 23.8008 25.8008C24.1016 25 24.8008 24.1016 25.8984 23L27 21.8984C27.3008 21.6016 27.6016 21.3008 27.8008 20.8984C28.1992 20.3008 28.3984 19.6992 28.3984 19C28.3984 18.1016 28.1016 17.3008 27.6016 16.6992C27.1016 16 26.1016 15.6992 24.8984 15.6992C23.3008 15.6992 22.1992 16.3008 21.6016 17.3984C21.3008 18 21.1016 19 21 20.1016H18.5C18.6016 18.1992 19.1016 16.6016 20.3008 15.3984C21.3984 14.1992 23 13.6016 25 13.6016C26.8008 13.6016 28.3008 14.1016 29.3984 15.1992C30.5 16.1992 31.1016 17.6016 31.1016 19.1992C31.1016 20.1992 30.8984 21 30.5 21.6016Z"
        fill="currentColor"
      />
    </svg>
  );
};
