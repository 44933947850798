import { Typography } from "@mui/material";
import { AuthenticatedLayout } from "Components/Layout/AuthenticatedLayout";
import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";
import { StyledPageWrapper } from "Components/Shared/StyledComponents";
import { UserDetailForm } from "Components/Users/UserDetailForm";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { RootStateType } from "State/Store";
import { getUserAdminDetailAsync } from "State/Users/Detail/GetUserAdminDetailState";
import { downloadUserIdentificationZipAsync } from "State/Users/Detail/GetUserIdentificationZipState";
import { saveUserAdminDetailAsync } from "State/Users/Detail/SaveUserAdminDetailState";
import { getAppUrl } from "Utils/UrlUtils";

export const UserDetailPage: React.FunctionComponent<
  React.PropsWithChildren<unknown>
> = _ => {
  const [title, setTitle] = React.useState("Uživatel");

  const { id } = useParams();

  const dispatch = useDispatch();
  React.useEffect(() => {
    if (!!id) {
      dispatch(getUserAdminDetailAsync.request(Number(id)));
    }

    return () => {
      dispatch(getUserAdminDetailAsync.success(null));
    };
  }, [dispatch, id]);

  const { isUserDetailLoading, userDetail, isZipDownloading } = useSelector(
    (e: RootStateType) => e.user,
  );

  React.useEffect(() => {
    if (!!userDetail) {
      setTitle(userDetail.login);
    }
  }, [userDetail]);

  const appUrl = getAppUrl();

  return (
    <AuthenticatedLayout
      title={title}
      key={isUserDetailLoading.toString()}
      goBackUrl={appUrl("user-list")}
    >
      <StyledPageWrapper>
        <div className="left-content">
          <>
            {!!userDetail && (
              <>
                <UserDetailForm
                  user={userDetail}
                  onSubmit={model => {
                    dispatch(saveUserAdminDetailAsync.request(model));
                  }}
                />

                {userDetail.hasIdentificationsUploaded && (
                  <>
                    <br />
                    <Typography variant="h3">Akce:</Typography>
                    <br />
                    <PrimaryButton
                      isLoading={isZipDownloading}
                      fullWidth
                      onClick={() =>
                        dispatch(
                          downloadUserIdentificationZipAsync.request({
                            login: userDetail.login,
                            userID: userDetail.userID,
                          }),
                        )
                      }
                    >
                      Stáhnout doklady
                    </PrimaryButton>
                    <br />
                    <br />
                  </>
                )}
              </>
            )}
          </>
        </div>
      </StyledPageWrapper>
    </AuthenticatedLayout>
  );
};

export default UserDetailPage;
