import { Typography } from "@mui/material";
import { useBankingAuthQuery } from "Api/Mutations/useBankingAuth";
import { BankSelectorComponent } from "Components/Banking/Auth/BankSelectorComponent";
import { BankEnvironment } from "Infrastructure/Api/Api";
import { FC } from "react";
import { Resources, useResource } from "Translations/Resources";

type Props = {
  transactionPublicID: string;
};

const ComponentResources = Resources.Customer.Pay;

export const BankSelector: FC<Props> = ({ transactionPublicID }) => {
  const { t } = useResource();
  const {
    isPending,
    mutate: bankingAuth,
    variables: selectedEnvironment,
  } = useBankingAuthQuery({
    onAuthorizeUrl: ({ url }) => {
      window.location.href = url;
    },
  });

  const onBankSelected = (environment: BankEnvironment) => {
    if (!isPending) {
      bankingAuth({ environment, transactionPublicID });
    }
  };

  return (
    <div>
      <Typography marginBottom={4}>
        {t(ComponentResources.ChooseBank)}
      </Typography>
      <BankSelectorComponent
        isActionPending={false}
        onBankSelected={onBankSelected}
        selectedEnvironment={selectedEnvironment?.environment}
      />
    </div>
  );
};
