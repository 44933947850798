import { useMediaQuery, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { appUrl } from "Utils/UrlUtils";

export const useHasBackgroundImage = () => {
  const location = useLocation();
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));
  const [hasBackgroundImage, setHasBackgroundImage] = useState(false);

  useEffect(() => {
    if (isSm && [appUrl("dashboard")].includes(location.pathname)) {
      setHasBackgroundImage(true);
    } else {
      setHasBackgroundImage(false);
    }
  }, [location, isSm]);

  return { hasBackgroundImage };
};
