import { yupResolver } from "@hookform/resolvers/yup";
import { Typography } from "@mui/material";
import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";
import { FormInput } from "Components/Shared/Inputs/Form/FormInput";
import { getAppUrl } from "Utils/UrlUtils";
import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { resolveStartPage } from "Utils/UrlUtils";
import { RootStateType } from "State/Store";
import styled from "styled-components";
import { Resources, useResource, useServerError } from "Translations/Resources";
import { object, ObjectSchema, string } from "yup";
import {
  setPasswordAsync,
  setPasswordSetStep,
} from "State/Auth/Passwords/SetPasswordsState";
import { resetError, setUnauthenticatedUrl } from "State/Auth/AuthReducer";
import { StyledContainer } from "Components/Auth/Styles";
import { SetPasswordStep } from "State/Auth/Models/AuthStateModels";
import { AuthPageLayout } from "Components/Auth/Shared/AuthPageLayout";

type FormModel = {
  password: string;
  passwordRepeated: string;
};

const StyledForm = styled.form`
  margin-top: ${props => props.theme.spacing(4)};
`;

const StyledSubmitButton = styled(PrimaryButton)`
  margin-top: ${props => props.theme.spacing(3)};
`;

const StyledServerError = styled(Typography)`
  color: ${props => props.theme.colors.red};
`;

export const SetPasswordPage: React.FunctionComponent<
  React.PropsWithChildren<unknown>
> = _ => {
  const { token } = useParams();
  const { t } = useResource();
  const dispatch = useDispatch();

  const location = useLocation();

  const isCreate =
    new URLSearchParams(location.search).get("isCreate") === "true" || false;

  React.useEffect(() => {
    dispatch(resetError());
  }, [dispatch]);

  const validationSchema: ObjectSchema<FormModel> = object({
    password: string().required(t(Resources.Validation.Required)),
    passwordRepeated: string()
      .required(t(Resources.Validation.Required))
      .test("match", "Hesla se musí shodovat", function (value) {
        return this.parent.password === value;
      }),
  }).defined();

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<FormModel>({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
    defaultValues: { password: "", passwordRepeated: "" },
  });

  const submit = (data: FormModel) => {
    if (!!token) {
      dispatch(
        setPasswordAsync.request({ token: token, password: data.password }),
      );
    }
  };

  const { translateError } = useServerError(
    Resources.SignUp.Error,
    Resources.SignUp.Error.General,
  );

  const { isLoading, user, unauthenticatedUrl, errorCode, setPasswordStep } =
    useSelector((e: RootStateType) => e.auth);

  const navigate = useNavigate();
  const appUrl = getAppUrl();

  React.useEffect(() => {
    return () => {
      dispatch(setPasswordSetStep(SetPasswordStep.Password));
    };
  }, [dispatch]);

  React.useEffect(() => {
    if (!isLoading && !!user && setPasswordStep === SetPasswordStep.Success) {
      const { from } = {
        from: {
          pathname: resolveStartPage(appUrl, user, unauthenticatedUrl),
        },
      };

      dispatch(setUnauthenticatedUrl(null));
      navigate(from);
    }
  }, [
    isLoading,
    user,
    navigate,
    appUrl,
    unauthenticatedUrl,
    dispatch,
    setPasswordStep,
  ]);

  const title = isCreate ? "Vytvoření hesla" : "Reset hesla";

  return (
    <>
      <AuthPageLayout title={title} isLoading={isLoading} pageType={"other"}>
        <StyledForm onSubmit={handleSubmit(submit)}>
          <StyledContainer>
            <div>
              <FormInput
                control={control}
                name="password"
                errors={errors}
                label="Heslo"
                type="password"
              ></FormInput>

              <FormInput
                control={control}
                name="passwordRepeated"
                errors={errors}
                label="Heslo znovu"
                type="password"
              ></FormInput>

              <StyledServerError variant="subtitle1">
                {translateError(errorCode)}
              </StyledServerError>

              <StyledSubmitButton
                type="submit"
                variant="contained"
                color="info"
                isLoading={isLoading}
                fullWidth
              >
                {"Nastavit heslo"}
              </StyledSubmitButton>
            </div>
          </StyledContainer>
        </StyledForm>
      </AuthPageLayout>
    </>
  );
};

export default SetPasswordPage;
