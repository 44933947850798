import { AuthenticatedLayout } from "Components/Layout/AuthenticatedLayout";
import { StyledPageWrapper } from "Components/Shared/StyledComponents";
import { Resources, useResource } from "Translations/Resources";
import { InitiatePaymentDetailComponent } from "Components/Merchant/InitiatePaymentDetail/InitiatePaymentDetailComponent";
import { useNavigate, useParams } from "react-router";
import { appUrl } from "Utils/UrlUtils";
import { FC, PropsWithChildren } from "react";

const MenuResources = Resources.Menu.MainMenuItems;

export const MerchantInitiatePaymentDetailPage: FC<
  PropsWithChildren<unknown>
> = _ => {
  const { t } = useResource();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();

  // This should not happen, but just in case
  if (!id) {
    navigate(appUrl("initiate-payment"));

    return null;
  }

  return (
    <AuthenticatedLayout title={t(MenuResources.InitiatePayment)}>
      <StyledPageWrapper>
        <div className="left-content">
          <InitiatePaymentDetailComponent publicID={id} />
        </div>
      </StyledPageWrapper>
    </AuthenticatedLayout>
  );
};

export default MerchantInitiatePaymentDetailPage;
