import { Typography } from "@mui/material";
import { AnonymousLayout } from "Components/Layout/AnonymousLayout";
import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";
import { useNavigate } from "react-router";
import { appUrl } from "Utils/UrlUtils";

export const AnonymousDashboardPage: React.FunctionComponent<
  React.PropsWithChildren<unknown>
> = _ => {
  const navigate = useNavigate();

  return (
    <AnonymousLayout title="">
      <Typography marginBottom={8} variant="h6">
        Pro přístup k plné verzi aplikace se prosím registrujte
      </Typography>
      <PrimaryButton
        fullWidth
        color="primary"
        onClick={() => navigate(appUrl("sign-up"))}
      >
        Registrovat se
      </PrimaryButton>
    </AnonymousLayout>
  );
};
