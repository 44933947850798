import { Skeleton, Typography } from "@mui/material";
import { TransactionItem } from "Components/Shared/Transactions/TransactionItem";
import type { TransactionItem as TransactionItemType } from "Infrastructure/Api/Api";
import type { FC } from "react";

type Props = {
  transactions?: TransactionItemType[];
  isLoading: boolean;
};

export const RecentTransactions: FC<Props> = ({ isLoading, transactions }) => {
  if (isLoading && !transactions) {
    return (
      <>
        <Skeleton height={62} />
        <Skeleton height={62} />
        <Skeleton height={62} />
      </>
    );
  }

  if (transactions?.length === 0) {
    return <Typography>Nejsou k dispozici žádné transakce</Typography>;
  }

  return (
    <div>
      {transactions?.map(transaction => (
        <TransactionItem
          key={transaction.publicID}
          item={transaction}
          direction="outgoing"
        />
      ))}
    </div>
  );
};
