import { ChevronRight } from "@mui/icons-material";
import { Box, ListItem, Typography } from "@mui/material";
import { UserBankAccountStatus } from "Api/Api";
import { useBankingAccountDelete } from "Api/Mutations/Accounts/useBankingAccountDelete";
import { Colors } from "Components/Layout/Themes/Colors";
import { UnstyledLink } from "Components/Routes/UnstyledLink";
import { SandboxText } from "Components/Shared/Bank/SandboxText";
import { BankImage } from "Components/Shared/BankImage";
import { BlConfirmDialog } from "Components/Shared/Dialogs/BlConfirmDialog";
import { CloseIcon } from "Components/Shared/Icons";
import { StyledFlex } from "Components/Shared/StyledComponents";
import { useConfirmDialog } from "Hooks/useConfirmDialog";
import type { BankAccountListItem } from "Infrastructure/Api/Api";
import { type FC, type PropsWithChildren } from "react";
import styled from "styled-components";
import { isSandbox } from "Utils/BankUtils";
import { getAppUrl } from "Utils/UrlUtils";
import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const VerifiedRow = styled(Row)<{ $isEdit?: boolean }>`
  border: 1px ${({ $isEdit }) => ($isEdit ? "dashed" : "solid")};
  border-color: ${props => props.theme.palette.grey[300]};
  border-radius: ${props => props.theme.spacing(2)};
  position: relative;
  overflow: hidden;
  // padding: ${props => props.theme.spacing(1, 2)};
  > p {
    color: ${props => props.theme.palette.primary.dark};
  }
`;

const StyledListItem = styled(ListItem)<{
  $isVerified: boolean;
  $isEdit?: boolean;
}>`
  padding: 0;
  cursor: ${props =>
    props.$isVerified ? (props.$isEdit ? "mode" : "default") : "pointer"};
  div {
    color: ${props => props.theme.palette.primary.dark};

    ${props => props.theme.breakpoints.up("md")} {
      &:hover {
        color: ${props => props.theme.palette.primary.main};
      }
    }
  }
`;

const StyledButton = styled(PrimaryButton)`
  padding: 0;
  min-width: 60px;
  width: 60px;
  border-radius: 0;
  box-shadow: none;

  &.Mui-disabled {
    color: ${({ theme }) => theme.palette.primary.contrastText};
    background: none;
    background-color: ${({ theme }) => theme.palette.grey[500]};
  }
`;

const RightSide = styled(StyledFlex)`
  align-items: center;
  color: ${props => props.theme.palette.primary.main}!important;
`;

export const BankAccountRowLink: FC<
  PropsWithChildren<{
    item: BankAccountListItem;
    isEdit?: boolean;
  }>
> = ({ item, isEdit }) => {
  const { environment, iban, bankAccountID } = item;
  const { mutate: deleteAccount, isPending: isDeletingAccount } =
    useBankingAccountDelete();
  const { isOpen: isOpenConfirm, handleResult, open } = useConfirmDialog();

  const appUrl = getAppUrl();

  // TODO: Will be verified status used?
  const isVerified = [
    UserBankAccountStatus.Verified,
    UserBankAccountStatus.VerifiedIBANParsingError,
  ].includes(UserBankAccountStatus.Verified);

  // TODO: Will be used?
  const statusTest = environment; //getStatusText(item.status);

  return (
    <StyledListItem $isVerified={isVerified} $isEdit={isEdit}>
      {isVerified ? (
        <>
          <VerifiedRow $isEdit={isEdit}>
            <Box
              display="flex"
              alignItems="center"
              overflow="hidden"
              gap={2}
              paddingX={2}
              paddingY={1}
            >
              <BankImage bank={item.bank} />
              <Box overflow="hidden">
                <Typography fontWeight={600} lineHeight={1} noWrap>
                  <span>{item.productName}</span>
                  {isSandbox(item.environment) && <SandboxText />}
                </Typography>
                {!!iban && <Typography noWrap>{iban}</Typography>}
              </Box>
            </Box>
            {!isEdit && (
              <StyledButton
                onClick={open}
                isLoading={isDeletingAccount}
                color="error"
              >
                <CloseIcon fill={Colors.PrimaryText} height={18} />
              </StyledButton>
            )}
          </VerifiedRow>
          <BlConfirmDialog
            isOpen={isOpenConfirm}
            handleResult={handleResult(() => deleteAccount(bankAccountID))}
            modalTitle={"Smazat účet"}
            modalContent={
              "Opravdu chcete smazat účet " + item.productName + "?"
            }
          />
        </>
      ) : (
        <UnstyledLink
          to={`${appUrl("bank-address-detail")}/${bankAccountID}`}
          style={{ width: "100%" }}
        >
          <Row>
            <Typography>
              {/*item.formattedAccountNumber ?? "Neověřený účet"*/}
            </Typography>
            <RightSide>
              <Typography fontWeight={600}>{statusTest}</Typography>
              <ChevronRight />
            </RightSide>
          </Row>
        </UnstyledLink>
      )}
    </StyledListItem>
  );
};

// function getStatusText(status: UserBankAccountStatus) {
//   switch (status) {
//     case UserBankAccountStatus.Verified:
//     case UserBankAccountStatus.VerifiedIBANParsingError:
//       return "Ověřený";
//     case UserBankAccountStatus.NewToVerify:
//     case UserBankAccountStatus.New:
//       return "Platební údaje";
//     case UserBankAccountStatus.Rejected:
//       return "Zamítnutý";
//     default:
//       return "";
//   }
// }

const CreateBankAccountTypography = styled(Typography)`
  color: ${props => props.theme.palette.primary.main};
  font-weight: 600;
`;

export const CreateBankAccountRowLink: React.FunctionComponent<
  React.PropsWithChildren<{
    onClick?: () => void;
  }>
> = ({ onClick }) => {
  return (
    <StyledListItem divider $isVerified={false} onClick={onClick}>
      <Row>
        <CreateBankAccountTypography>
          Přidat nový účet
        </CreateBankAccountTypography>
        <RightSide>
          <ChevronRight />
        </RightSide>
      </Row>
    </StyledListItem>
  );
};
