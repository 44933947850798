import { Typography } from "@mui/material";
import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";
import { DragNDrop } from "Components/Shared/DragNDrop";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootStateType } from "State/Store";
import { uploadUserIdentificationAsync } from "State/Verification/UserIdentifications/UploadUserIdentificationState";
import styled from "styled-components";

const SmallHeader = styled(Typography)`
  color: ${props => props.theme.palette.text.secondary};
  margin: ${props => props.theme.spacing(1, 0)};
`;

const StyledSubmitButton = styled(PrimaryButton)`
  margin: ${props => props.theme.spacing(1, 0)};
`;

export const IdentityCardsStep: React.FunctionComponent<
  React.PropsWithChildren<unknown>
> = _ => {
  const [firstIDCardFrontSide, setFirstIDCardFrontFile] =
    React.useState<File | null>(null);
  const [firstIDCardBackSide, setFirstIDCardBackFile] =
    React.useState<File | null>(null);
  const [secondIDCardFrontSide, setSecondIDCardFrontFile] =
    React.useState<File | null>(null);

  const isDisabled =
    !firstIDCardFrontSide || !firstIDCardBackSide || !secondIDCardFrontSide;

  const dispatch = useDispatch();
  const submit = () => {
    if (isDisabled) {
      return;
    }
    dispatch(
      uploadUserIdentificationAsync.request({
        firstIDCardBackSide,
        firstIDCardFrontSide,
        secondIDCardFrontSide,
      }),
    );
  };

  const { isLoading } = useSelector((e: RootStateType) => e.verification);

  return (
    <>
      <br />
      <Typography>Nahrejte prosím čitelné skeny dvou dokladů.</Typography>
      <br />

      <Typography variant="h4">Občanský průkaz</Typography>
      <SmallHeader>přední strana</SmallHeader>

      <DragNDrop
        file={firstIDCardFrontSide}
        title={"Nahrát sken dokladu"}
        description={"Klikněte zde pro nahrání nebo vyfocení"}
        onChange={val => {
          setFirstIDCardFrontFile(val);
        }}
      ></DragNDrop>

      <SmallHeader>zadní strana</SmallHeader>

      <DragNDrop
        file={firstIDCardBackSide}
        title={"Nahrát sken dokladu"}
        description={"Klikněte zde pro nahrání nebo vyfocení"}
        onChange={val => {
          setFirstIDCardBackFile(val);
        }}
      ></DragNDrop>

      <Typography variant="h4">Druhý doklad</Typography>
      <SmallHeader>řidičský průkaz nebo pas </SmallHeader>

      <DragNDrop
        file={secondIDCardFrontSide}
        title={"Nahrát sken dokladu"}
        description={"Klikněte zde pro nahrání nebo vyfocení"}
        onChange={val => {
          setSecondIDCardFrontFile(val);
        }}
      ></DragNDrop>

      <br />
      <br />
      <br />
      <br />
      <StyledSubmitButton
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        disabled={isDisabled}
        onClick={submit}
        isLoading={isLoading}
      >
        {"Odeslat"}
      </StyledSubmitButton>
    </>
  );
};
