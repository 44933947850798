import { Typography } from "@mui/material";
import { ManagerIcon } from "Components/Shared/Icons";
import { Invoice } from "Components/Invoices/Invoice";
import { JobTypeHeader } from "Components/Welcome/Styles";
import styled from "styled-components";
import { CurrencyCode } from "Models/CurrencyCodes";

const JobTypeWrapper = styled.div`
  margin-top: ${props => props.theme.spacing(2)};
  margin-left: ${props => props.theme.spacing(4)};
`;

export const IndividualBusinessPersonPanel: React.FunctionComponent<
  React.PropsWithChildren<unknown>
> = _ => {
  return (
    <div>
      <JobTypeWrapper>
        <JobTypeHeader>
          <ManagerIcon />
          <Typography>
            {"Jsem podnikatel, chci proplácet faktury v bitcoinech"}
          </Typography>
        </JobTypeHeader>
        <Typography>
          {
            "Umístěte výše následující platební údajena faktury, které chcete proplatit v bitcoinech."
          }
        </Typography>

        <br />
        <br />
        <Invoice currencyCode={CurrencyCode.CZK} />
      </JobTypeWrapper>
    </div>
  );
};
