import { Box } from "@mui/material";
import { AuthenticatedLayout } from "Components/Layout/AuthenticatedLayout";
import { Colors } from "Components/Layout/Themes/Colors";
import { SettingsRowLink } from "Components/Settings/SettingsRowLink";
import {
  FriendsIcon,
  InstagramVerificationBadgeIcon,
  MultiplyIcon,
  NotificationIcon,
  TrashBinIcon,
  UserIcon,
} from "Components/Shared/Icons";
import { StyledPageWrapper } from "Components/Shared/StyledComponents";
import React from "react";
import { useDispatch } from "react-redux";
import { getLeadListAsync } from "State/Afl/LeadListState/LeadListState";
import { getAppUrl } from "Utils/UrlUtils";

export const SettingsPage: React.FunctionComponent<
  React.PropsWithChildren<unknown>
> = _ => {
  const appUrl = getAppUrl();

  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(getLeadListAsync.request());

    return () => {
      dispatch(getLeadListAsync.success(null));
    };
  }, [dispatch]);

  return (
    <AuthenticatedLayout title="Více">
      <StyledPageWrapper>
        <Box
          className="left-content"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          gap={4}
          height="100%"
        >
          {/* <UserLimits /> */}

          <div>
            <SettingsRowLink
              name="Profil"
              url={appUrl("account")}
              icon={<UserIcon />}
            />
            {/*<SettingsRowLink
                name="Ověření účtu"
                url={appUrl("verification")}
                icon={<VerificationBadgeIcon />}
              />*/}
            {/* <SettingsRowLink
                name="Uchování bitcoinů"
                url={appUrl("account-settings")}
                icon={<MoneyBoxIcon />}
              /> */}
            <SettingsRowLink
              name="Připojené účty"
              url={appUrl("bank-address-list")}
              icon={<InstagramVerificationBadgeIcon />}
            />
            <SettingsRowLink
              name="Notifikace a upozornění"
              url={appUrl("notification-settings")}
              icon={<NotificationIcon />}
            />
            <SettingsRowLink
              name="Pozvat přítele"
              url={appUrl("affiliate")}
              icon={<FriendsIcon />}
            />
          </div>

          <div>
            <SettingsRowLink
              name="Smazat účet"
              url={appUrl("deactivate-user")}
              icon={<TrashBinIcon />}
              color={Colors.Red}
              rightIcon={
                <MultiplyIcon
                  style={{ marginLeft: "5px" }}
                  width={12}
                  height={12}
                />
              }
            />
          </div>
        </Box>
      </StyledPageWrapper>
    </AuthenticatedLayout>
  );
};

export default SettingsPage;
