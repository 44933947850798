import type { FormModel } from "Components/Customer/Payment/CustomerPaymentPayComponent";
import { BankingAccountSelector } from "Components/Shared/Inputs/Form/BankingAccountSelector";
import { FormInput } from "Components/Shared/Inputs/Form/FormInput";
import type { BankAccountListItem } from "Infrastructure/Api/Api";
import type { FC } from "react";
import { useFormContext } from "react-hook-form";
import { Resources, useResource } from "Translations/Resources";

const FormResources = Resources.Merchant.Initiate.Form;

type Props = {
  bankAccounts?: BankAccountListItem[];
  isLoading?: boolean;
};

export const FormFields: FC<Props> = ({ bankAccounts, isLoading }) => {
  const { t } = useResource();
  const { control } = useFormContext<FormModel>();

  return (
    <div>
      <BankingAccountSelector
        name="bankAccountID"
        control={control}
        label={t(FormResources.AccountSelectTitle)}
        bankAccounts={bankAccounts ?? []}
      />

      <FormInput
        control={control}
        name="publicID"
        label="Identifikátor transakce"
        type="text"
      />
    </div>
  );
};
