import * as React from "react";
export const NotificationIcon = (props: React.SVGProps<SVGSVGElement>) => {
  props = {
    ...props,
    color: props.color,
  };
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.0001 0C9.11728 0 8.4001 0.717188 8.4001 1.6C8.4001 2.48281 9.11728 3.2 10.0001 3.2C10.8829 3.2 11.6001 2.48281 11.6001 1.6C11.6001 0.717188 10.8829 0 10.0001 0ZM7.7501 2.4375C5.92197 3.22031 4.8001 4.98281 4.8001 7.2C4.8001 11.6 3.27979 12.7047 2.3751 13.3625C1.97354 13.6531 1.6001 13.9234 1.6001 14.4C1.6001 16.0828 4.1126 16.8 10.0001 16.8C15.8876 16.8 18.4001 16.0828 18.4001 14.4C18.4001 13.9234 18.0267 13.6531 17.6251 13.3625C16.7204 12.7047 15.2001 11.6 15.2001 7.2C15.2001 4.97656 14.0798 3.21875 12.2501 2.4375C11.9079 3.34688 11.0267 4 10.0001 4C8.97353 4 8.09228 3.34531 7.7501 2.4375ZM7.6001 17.55C7.6001 17.5656 7.6001 17.5844 7.6001 17.6C7.6001 18.9234 8.67666 20 10.0001 20C11.3235 20 12.4001 18.9234 12.4001 17.6C12.4001 17.5844 12.4001 17.5656 12.4001 17.55C11.647 17.5813 10.847 17.6 10.0001 17.6C9.15322 17.6 8.35322 17.5813 7.6001 17.55Z"
        fill="#555555"
      />
    </svg>
  );
};
