import { LeadDto, LeadStatus } from "Api/Api";

export const canInvite = (items: LeadDto[], maxInvitationCount: number) => {
  const invited = items.filter(
    e =>
      ![
        LeadStatus.Completed,
        LeadStatus.CompletedRewardSent,
        LeadStatus.CompletedRewardApplied,
      ].includes(e.status),
  );

  return invited.length < maxInvitationCount;
};
