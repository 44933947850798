import {
  ApplyVoucherCommandResult,
  ValidateVoucherCommandResult,
} from "Api/Api";
import { applyVoucherAsync } from "State/Voucher/ApplyVoucherState";
import { createReducer, ActionType, createAction } from "typesafe-actions";

export enum RecaptchaStatus {
  Valid,
  Invalid,
}

type SignUpSubsidy = {
  fiatAmount: number;
  currencyCode: string;
};

type VoucherState = {
  validateResult: ValidateVoucherCommandResult | null;
  isValidating: boolean;
  applyResult: ApplyVoucherCommandResult | null;
  isApplying: boolean;
  recaptchaStatus: RecaptchaStatus;
  signUpSubsidy: SignUpSubsidy | null;
};

export const resetVoucherApplyResult = createAction(
  "@voucher/RESET_VOUCHER_APPLY_RESULT",
)<void>();

export const setRecaptchaStatus = createAction(
  "@voucher/SET_RECAPTCHA_STATUS",
)<RecaptchaStatus>();

export const setSignUpSubsidy = createAction(
  "@voucher/SET_SIGN_UP_SUBSIDY",
)<SignUpSubsidy | null>();

type VoucherAction =
  | ActionType<typeof applyVoucherAsync>
  | ActionType<typeof setRecaptchaStatus>
  | ActionType<typeof setSignUpSubsidy>
  | ActionType<typeof resetVoucherApplyResult>;

export const voucherReducer = createReducer<VoucherState, VoucherAction>({
  validateResult: null,
  isValidating: false,
  applyResult: null,
  isApplying: false,
  recaptchaStatus: RecaptchaStatus.Valid,
  signUpSubsidy: null,
})
  .handleAction(applyVoucherAsync.request, state => {
    return { ...state, isApplying: true };
  })
  .handleAction(applyVoucherAsync.success, (state, action) => {
    return { ...state, isApplying: false, applyResult: action.payload };
  })
  .handleAction(applyVoucherAsync.failure, (state, action) => {
    return {
      ...state,
      isApplying: false,
    };
  })
  .handleAction(resetVoucherApplyResult, state => {
    return {
      ...state,
      applyResult: null,
      validateResult: null,
      recaptchaStatus: RecaptchaStatus.Valid,
    };
  })
  .handleAction(setRecaptchaStatus, (state, action) => {
    return { ...state, recaptchaStatus: action.payload };
  })
  .handleAction(setSignUpSubsidy, (state, action) => {
    return { ...state, signUpSubsidy: action.payload };
  });
