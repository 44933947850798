import { Typography } from "@mui/material";
import { PaymentDetail } from "Components/Merchant/InitiatePaymentDetail/PaymentDetail";
import { PrimaryPaper } from "Components/Shared/PrimaryPaper";
import { FC } from "react";
import { Resources, useResource } from "Translations/Resources";

const ComponentResources = Resources.Merchant.InitiateDetail;

type Props = {
  publicID: string;
};

export const InitiatePaymentDetailComponent: FC<Props> = ({ publicID }) => {
  const { t } = useResource();

  return (
    <PrimaryPaper>
      <Typography marginBottom={10}>{t(ComponentResources.Title)}</Typography>
      <PaymentDetail publicID={publicID} />
    </PrimaryPaper>
  );
};
