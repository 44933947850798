import { AuthenticatedLayout } from "Components/Layout/AuthenticatedLayout";
import { StyledPageWrapper } from "Components/Shared/StyledComponents";
import React from "react";
import { useSelector } from "react-redux";
import { RootStateType } from "State/Store";
import { AppUser } from "State/Auth/Models/AuthStateModels";
import { getAppUrl } from "Utils/UrlUtils";
import { PaperRow, PrimaryPaper } from "Components/Shared/PrimaryPaper";
import { Grid } from "@mui/material";

export const AccountPage: React.FunctionComponent<
  React.PropsWithChildren<unknown>
> = _ => {
  const user = useSelector((e: RootStateType) => e.auth.user) as AppUser;

  const appUrl = getAppUrl();

  return (
    <AuthenticatedLayout title="Profil" goBackUrl={appUrl("settings")}>
      <StyledPageWrapper>
        <div className="left-content">
          {!!user && (
            <PrimaryPaper>
              <Grid container direction="column">
                <Grid container item direction={"row"} spacing={3}>
                  {!!user.firstName && (
                    <PaperRow left={"Jméno"} right={user.firstName} />
                  )}
                  {!!user.lastName && (
                    <PaperRow left={"Příjmení"} right={user.lastName} />
                  )}
                  <PaperRow left={"E-mail"} right={user.login} />
                </Grid>
              </Grid>
            </PrimaryPaper>
          )}
        </div>
      </StyledPageWrapper>
    </AuthenticatedLayout>
  );
};

export default AccountPage;
