import { Button, ButtonProps, SxProps, Theme, Typography } from "@mui/material";
import { FC } from "react";

type Props = Omit<ButtonProps, "variant">;

const buttonStyles: SxProps<Theme> = {
  padding: "0 !important",
  backgroundColor: "inherit",
  textTransform: "none",
  "&:hover": {
    backgroundColor: "inherit",
  },
};

const typographyStyles: SxProps<Theme> = {
  background: props => props.palette.gradient.primary,
  backgroundClip: "text",
  WebkitTextFillColor: "transparent",
};

export const BlTextButton: FC<Props> = props => {
  return (
    <Button color="primary" variant="text" {...props} sx={buttonStyles}>
      <Typography color="primary" fontWeight={600} sx={typographyStyles}>
        {props.children}
      </Typography>
    </Button>
  );
};
