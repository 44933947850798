import { Grid, Typography } from "@mui/material";
import { UnstyledLink } from "Components/Routes/UnstyledLink";
import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";
import { PlusMathIcon, ForwardArrowIcon } from "Components/Shared/Icons";
import { AppUser } from "State/Auth/Models/AuthStateModels";
import { getAppUrl } from "Utils/UrlUtils";

type Props = {
  user: AppUser;
  publicID: string;
};

export const BalanceActions: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = props => {
  const { publicID } = props;

  const appUrl = getAppUrl();
  return (
    <Grid container spacing={4} marginTop={2}>
      <Grid item xs={12} sm={6}>
        <UnstyledLink to={`${appUrl("initiate-payment")}?publicID=${publicID}`}>
          <PrimaryButton
            type="button"
            color="primary"
            fullWidth
            startIcon={<PlusMathIcon width={20} height={20} />}
          >
            <Typography fontWeight={600} fontSize={14}>
              Vyvolat platbu
            </Typography>
          </PrimaryButton>
        </UnstyledLink>
      </Grid>

      <Grid item xs={12} sm={6}>
        <UnstyledLink
          to={`${appUrl("customer-payment-pay")}?publicID=${publicID}`}
        >
          <PrimaryButton
            variant="outlined"
            type="button"
            color="info"
            fullWidth
            startIcon={<ForwardArrowIcon width={20} height={20} />}
          >
            <Typography fontWeight={600} fontSize={14}>
              Poslat platbu
            </Typography>
          </PrimaryButton>
        </UnstyledLink>
      </Grid>
    </Grid>
  );
};
