import { put, takeLatest } from "redux-saga/effects";
import {
  AddWithdrawalToQueueCommandResultStatus,
  getBalance as getBalanceRest,
  postBalanceSell,
  postBalanceWithdraw,
  SellCommandResultStatus,
} from "Api/Api";
import { getType } from "typesafe-actions";
import { call } from "typed-redux-saga";
import {
  getBalanceAsync,
  sellAsync,
  setWithdrawalStep,
  withdrawAsync,
} from "State/Balance/BalanceReducer";
import { toast } from "sonner";
import { getTransactionListAsync } from "State/Transactions/List/GetTransactionListState";
import { DASHBOARD_TRANSACTION_LIMIT } from "Pages/DashboardPage";
import { getAppUrl } from "Utils/UrlUtils";
import { logError } from "ErrorService";

function* getBalance(
  action: ReturnType<typeof getBalanceAsync.request>,
): Generator {
  try {
    const response = yield* call(getBalanceRest);
    if (response.status === 200) {
      yield put(getBalanceAsync.success(response.json));
    } else {
      yield put(
        getBalanceAsync.failure(
          new Error(`Error in action ${action.type}`, {
            cause: response.status,
          }),
        ),
      );
    }
  } catch (err) {
    yield put(getBalanceAsync.failure(err as Error));
  }
}
export function* getBalanceSaga() {
  yield takeLatest(getType(getBalanceAsync.request), getBalance);
}

function* withdraw(
  action: ReturnType<typeof withdrawAsync.request>,
): Generator {
  try {
    const response = yield* call(postBalanceWithdraw, action.payload);
    if (
      response.status === 200 &&
      response.json.status === AddWithdrawalToQueueCommandResultStatus.Success
    ) {
      toast.success("Výběr proběhl úspěšně");
      yield put(setWithdrawalStep("success"));
      yield put(withdrawAsync.success(response.json));
      yield put(getBalanceAsync.request());
      yield put(
        getTransactionListAsync.request({
          offset: 0,
          limit: DASHBOARD_TRANSACTION_LIMIT,
          isShowMoreShown: false,
        }),
      );
    } else {
      yield put(
        withdrawAsync.failure(
          new Error(`Error in action ${action.type}`, {
            cause: response.status,
          }),
        ),
      );

      if (!!response.json) {
        if (
          response.json.status ===
          AddWithdrawalToQueueCommandResultStatus.InsufficientFunds
        ) {
          toast.error("Výběr se nezdařil. Na Vašem účtu není dostatek peněz.");
        } else {
          toast.error("Výběr se nezdařil");
        }
      } else {
        yield put(
          withdrawAsync.failure(
            new Error(`Error in action ${action.type}`, {
              cause: response.status,
            }),
          ),
        );
      }
    }
  } catch (err) {
    toast.error("Výběr se nezdařil");
    yield put(withdrawAsync.failure(err as Error));
  }
}
export function* withdrawSaga() {
  yield takeLatest(getType(withdrawAsync.request), withdraw);
}

function* sell(action: ReturnType<typeof sellAsync.request>): Generator {
  try {
    const response = yield* call(postBalanceSell, action.payload.request);
    if (response.status === 200) {
      yield put(sellAsync.success(response.json));

      const status = response.json.status;
      if (status === SellCommandResultStatus.Success) {
        toast.success("Prodej proběhl úspěšně.");
        action.payload.navigate(getAppUrl()("dashboard"));
      } else if (status === SellCommandResultStatus.RateExpired) {
        toast.error("Prodej se nezdařil. Platnost ponuky vypršela.");
      } else {
        toast.error("Prodej se nezdařil. Zkuste to znovu.");
        logError(
          new Error(`Error in action ${action.type}`, {
            cause: response.status,
          }),
          {
            response: response.json.status,
            request: action.payload.request,
          },
          false,
        );
      }
    } else {
      yield put(
        sellAsync.failure(
          new Error(`Error in action ${action.type}`, {
            cause: response.status,
          }),
        ),
      );
    }
  } catch (err) {
    yield put(sellAsync.failure(err as Error));
  }
}
export function* sellSaga() {
  yield takeLatest(getType(sellAsync.request), sell);
}
